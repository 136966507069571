import React, { useState, useRef, useEffect, useMemo } from "react";

import {
  Paper,
  Autocomplete,

} from "@mui/material";

import { useApplication } from "../../redux/application/hook";

import GlobalSearchTextField from "../../component/gloabalTextfields/searchTextField";
import GlobalTextField from "../../component/gloabalTextfields/globalTextfield";
import ParamSettingListing from "./paramSettingListing";

import PrimaryButton from "../../component/buttons/primaryButton";
import ResetButton from "../../component/buttons/tableButtons/resetButton";

import BreadCrumbs from "../../component/breadCrumbs/breadCrumbs";

import ParamSettingDialogbox from "./paramSettingDialogbox";

export default function ParamSetting(props) {
  const { applicationParamList } = useApplication();
  const [openParamDialog, setOpenParamDialog] = useState(false);

  const [searchVal, setSearchVal] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("no")
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedParamType, setSelectedParamType] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [selectedParamSetting, setSelectedParamSetting] = useState(null)
  const [action, setAction] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  //==========================Dialog Function================================
  const handleOpenParamDialogBox = (action) => {
    setOpenParamDialog(true);
    setAction(action)
  }

  const handleCloseParamDialogBox = () => {
    setOpenParamDialog(false);
    setSelectedParamSetting(null);
  }
  //==========================================================================

  //==========================Search Function================================
  const handleFilterAppParamBySearchVal = (appParam) => {
    var lowerCaseSearchVal = searchVal.toLowerCase() ?? "";
    return (
      appParam.paramValue?.toLowerCase().includes(lowerCaseSearchVal) ||
      appParam.paramType?.toLowerCase().includes(lowerCaseSearchVal) ||
      appParam.moduleName?.toLowerCase().includes(lowerCaseSearchVal) ||
      appParam.paramStatus?.toLowerCase().includes(lowerCaseSearchVal) 
    );
  };
  //==========================================================================

  //==========================Table Sort Function=============================
  const getComparator = (order, orderBy) => {
    if (orderBy === "no") {
      return order === "desc"
        ? (a, b) => b[orderBy] - a[orderBy]
        : (a, b) => a[orderBy] - b[orderBy];
    } else {
      return order === "desc"
        ? (a, b) =>
            b[orderBy]?.localeCompare(a[orderBy], undefined, {
              numeric: true,
              sensitivity: "base",
            })
        : (a, b) =>
            a[orderBy]?.localeCompare(b[orderBy], undefined, {
              numeric: true,
              sensitivity: "base",
            });
    }
  };

  const handleRequestRoleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //==========================================================================

  const handleResetFilterValue = () =>{
    setSelectedModule("");
    setSelectedParamType("");
    setSelectedStatus("");
    setSearchVal("");
    setPage(0);
  }

  const MappedApplicationParam = useMemo(() => {
    var mappedAppParam = applicationParamList && applicationParamList?.map((x, index) => {
      return {
        no: index + 1,
        id: x.id,
        moduleId: x.moduleId,
        isSpecialAccess: x?.module?.isSpecialAccess ?? null,
        moduleName: x.module?.name ?? "General",
        moduleStatus: x.module?.status,
        paramType: x.paramType,
        paramValue: x.paramValue,
        paramDesc: x.paramDesc,
        paramRemark: x.paramRemark,
        paramSeq: x.paramSeq,
        paramStatus: x.paramStatus === 1 ? "Active" : "Inactive",
      };
    });

    return mappedAppParam;
  }, [applicationParamList]);

  const FilterApplicationParam = useMemo(() => {
    setPage(0)
    return MappedApplicationParam
      ?.filter((a) => (selectedParamType ? a.paramType === selectedParamType : true))
      ?.filter((a) => (selectedModule ? a.moduleName === selectedModule : true))
      ?.filter((a) => (selectedStatus ? a.paramStatus === selectedStatus : true))
      .filter((a) => handleFilterAppParamBySearchVal(a))
      .sort(getComparator(order, orderBy));
  }, [
    MappedApplicationParam,
    selectedParamType,
    selectedStatus,
    selectedModule,
    searchVal,
    order,
    orderBy,
  ]);

  const paramTypeFilter = useMemo(() => {
    return MappedApplicationParam?.filter((a) =>
      selectedModule ? a.moduleName === selectedModule : true
    )
      ?.filter((a) => (selectedStatus ? a.paramStatus === selectedStatus : true))
      ?.sort((a, b) => a.paramType.localeCompare(b.paramType))
      ?.map((z) => z.paramType)
      ?.filter((v, i, a) => a.indexOf(v) === i);
  }, [MappedApplicationParam, selectedModule, selectedStatus]);

  const moduleFilter = useMemo(() => {
    return MappedApplicationParam?.filter((a) =>
      selectedParamType ? a.paramType === selectedParamType : true
    )
      ?.filter((a) => (selectedStatus ? a.paramStatus === selectedStatus : true))
      ?.sort((a, b) => a.moduleName.localeCompare(b.moduleName))
      ?.map((z) => z.moduleName)
      ?.filter((v, i, a) => a.indexOf(v) === i);
  }, [MappedApplicationParam, selectedParamType, selectedStatus]);

  const statusFilter = useMemo(() => {
    return MappedApplicationParam?.filter((a) =>
      selectedParamType ? a.paramType === selectedParamType : true
    )
      ?.filter((a) => (selectedModule ? a.moduleName === selectedModule : true))
      ?.sort((a, b) => a.paramStatus.localeCompare(b.paramStatus))
      ?.map((z) => z.paramStatus)
      ?.filter((v, i, a) => a.indexOf(v) === i);
  }, [MappedApplicationParam, selectedParamType, selectedModule]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BreadCrumbs />
        <PrimaryButton size={"small"} onClick={() => handleOpenParamDialogBox("Create")}>
          Add Parameter
        </PrimaryButton>
      </div>

      <Paper sx={{ mt: 5 }}>
        <div style={{ display: "flex", padding: "16px" }}>
          <GlobalSearchTextField
            sx={{ width: "30%" }}
            onChange={(e) => setSearchVal(e.target.value)}
            searchValue={searchVal}
            handleClearSearch={() => setSearchVal("")}
          />
          <Autocomplete
            size={"small"}
            sx={{ width: 200, ml: 1 }}
            options={paramTypeFilter ?? []}
            value={selectedParamType}
            onChange={(e, v) => setSelectedParamType(v)}
            renderInput={(params) => <GlobalTextField label={"Param Type"} {...params} />}
          />
          <Autocomplete
            size={"small"}
            label={"Module"}
            sx={{ width: 300, ml: 1 }}
            options={moduleFilter ?? []}
            value={selectedModule}
            onChange={(e, v) => setSelectedModule(v)}
            renderInput={(params) => <GlobalTextField label={"Module"} {...params} />}
          />
          <Autocomplete
            size={"small"}
            label={"Status"}
            sx={{ width: 200, ml: 1 }}
            options={statusFilter ?? []}
            value={selectedStatus}
            onChange={(e, v) => setSelectedStatus(v)}
            renderInput={(params) => <GlobalTextField label={"Status"} {...params} />}
          />
          <div
            style={{
              marginLeft: "auto",
              alignItems: "center",
              display: "flex",
            }}
          >
            <ResetButton
              onClick={() => {
                handleResetFilterValue();
              }}
              size={"small"}
            />
          </div>
        </div>
        <ParamSettingListing
          FilterApplicationParam={FilterApplicationParam}
          order={order}
          orderBy={orderBy}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          isLoading={isLoading}
          setOpenParamDialog={setOpenParamDialog}
          selectedParamSetting={selectedParamSetting}
          setSelectedParamSetting={setSelectedParamSetting}
          action={action}
          setAction={setAction}
          handleRequestRoleSort={handleRequestRoleSort}
        />
      </Paper>
      <ParamSettingDialogbox
        open={openParamDialog}
        onClose={handleCloseParamDialogBox}
        MappedApplicationParam={MappedApplicationParam}
        selectedParamSetting={selectedParamSetting}
        setSelectedModule={setSelectedModule}
        setSelectedParamType={setSelectedParamType}
        setSelectedStatus={setSelectedStatus}
        setSearchVal={setSearchVal}
        setPage={setPage}
        action={action}
      />
    </>
  );
}
