import React, { useEffect, useMemo, useState } from "react";

import BreadCrumbs from "../../../component/breadCrumbs/breadCrumbs";
import PrimaryButton from "../../../component/buttons/primaryButton";

import { LoadingButton } from "@mui/lab";
import AssignedSalesRepCard from "./assignedSalesRepCard";
import "./salesInfo.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";
import { useSnackbar } from "../../../contexts/snackbarContext";
import { useApplication } from "../../../redux/application/hook";
import SalesDetails from "./salesDetails";
import SalesInteractions from "./salesInteractions";
import { useAuthentication } from "../../../redux/authentication/hook";

export default function SalesInfo({ stage }) {
  const { user } = useAuthentication();
  const { employeeList, applicationParamList } = useApplication();
  const navigate = useNavigate();
  const openSnackbar = useSnackbar();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [loading, setLoading] = useState({});
  const [salesStage, setSalesStage] = useState({});

  const fallBackUrl =
    stage === "lead" ? "/leadManagement" : stage === "deal" ? "/dealManagement" : "/";

  const getLeadById = () => {
    setLoading({ salesStage: true });
    axios
      .get(`${ApiEndpoints.getLeadById}/${id}`)
      .then((res) => {
        setSalesStage(res.data);
      })
      .catch((error) => {
        openSnackbar("Failed to get lead info", "error");
        navigate(fallBackUrl);
      })
      .finally(() => {
        setLoading({ salesStage: false });
      });
  };

  const getDealById = async () => {
    setLoading({ salesStage: true });
    await axios
      .get(`${ApiEndpoints.getDealById}/${id}`)
      .then((res) => {
        setSalesStage(res.data);
      })
      .catch((error) => {
        openSnackbar("Failed to get deal info", "error");
        navigate(fallBackUrl);
      })
      .finally(() => {
        setLoading({ salesStage: false });
      });
  };

  const getSalesStage = async () => {
    if (stage === "lead") {
      if (id) {
        await getLeadById(id);
      } else {
        navigate(fallBackUrl);
      }
    } else if (stage === "deal") {
      if (id) {
        await getDealById(id);
      } else {
        navigate(fallBackUrl);
      }
    } else {
      navigate(fallBackUrl);
    }
  };

  const handleSuccess = async () => {
    if (stage === "lead") {
      await handleLeadSuccess();
    } else {
      await handleDealSuccess();
    }
  };

  const handleFailure = async () => {
    if (stage === "lead") {
      await handleLeadFailure();
    } else {
      await handleDealFailure();
    }
  };

  const handleLeadSuccess = async () => {
    setLoading({ success: true });
    const data = {
      id: id,
      interactedById: user?.emp?.id,
      isSuccessful: true,
      by: user?.username,
    };
    await axios
      .post(`${ApiEndpoints.closeLead}/${id}`, data)
      .then((res) => {
        openSnackbar("Successfully qualified lead", "success");
        getSalesStage();
      })
      .catch((error) => {
        openSnackbar("Failed to qualify lead", "error");
      })
      .finally(() => {
        setLoading({ success: false });
      });
  };

  const handleLeadFailure = async () => {
    setLoading({ fail: true });
    const data = {
      id: id,
      interactedById: user?.emp?.id,
      isSuccessful: false,
      by: user?.username,
    };
    await axios
      .post(`${ApiEndpoints.closeLead}/${id}`, data)
      .then((res) => {
        openSnackbar("Successfully unqualified lead", "success");
        getSalesStage();
      })
      .catch((error) => {
        openSnackbar("Failed to unqualify lead", "error");
      })
      .finally(() => {
        setLoading({ fail: false });
      });
  };

  const handleDealSuccess = async () => {
    setLoading({ success: true });
    const data = {
      id: id,
      interactedById: user?.emp?.id,
      isSuccessful: true,
      by: user?.username,
    };
    await axios
      .post(`${ApiEndpoints.closeDeal}/${id}`, data)
      .then((res) => {
        openSnackbar("Successfully closed deal", "success");
        getSalesStage();
      })
      .catch((error) => {
        openSnackbar("Failed to close deal", "error");
      })
      .finally(() => {
        setLoading({ success: false });
      });
  };

  const handleDealFailure = async () => {
    setLoading({ fail: true });
    const data = {
      id: id,
      interactedById: user?.emp?.id,
      isSuccessful: false,
      by: user?.username,
    };
    await axios
      .post(`${ApiEndpoints.closeDeal}/${id}`, data)
      .then((res) => {
        openSnackbar("Successfully closed deal", "success");
        getSalesStage();
      })
      .catch((error) => {
        openSnackbar("Failed to close deal", "error");
      })
      .finally(() => {
        setLoading({ fail: false });
      });
  };

  const handleReopen = () => {
    if (stage === "lead") {
      handleReopenLead();
    } else {
      handleReopenDeal();
    }
  };

  const handleReopenLead = async () => {
    setLoading({ reopen: true });
    const data = {
      id: id,
      interactedById: user?.emp?.id,
      by: user?.username,
    };
    await axios
      .post(`${ApiEndpoints.reopenLead}/${id}`, data)
      .then((res) => {
        openSnackbar("Successfully reopened lead", "success");
        getSalesStage();
      })
      .catch((error) => {
        openSnackbar("Failed to reopen lead", "error");
      })
      .finally(() => {
        setLoading({ reopen: false });
      });
  };
  const handleReopenDeal = async () => {
    setLoading({ reopen: true });
    const data = {
      id: id,
      interactedById: user?.emp?.id,
      by: user?.username,
    };
    await axios
      .post(`${ApiEndpoints.reopenDeal}/${id}`, data)
      .then((res) => {
        openSnackbar("Successfully reopened deal", "success");
        getSalesStage();
      })
      .catch((error) => {
        openSnackbar("Failed to reopen deal", "error");
      })
      .finally(() => {
        setLoading({ reopen: false });
      });
  };

  useEffect(() => {
    getSalesStage();
  }, [stage, id]);

  const salesRep = useMemo(() => {
    return employeeList?.find((employee) => employee?.id === salesStage?.sales?.salesRepId);
  }, [salesStage, employeeList]);

  const isClosedSuccess = useMemo(() => {
    return ["LeadStatus-Success", "DealStatus-Success"].includes(
      applicationParamList?.find((ap) => ap?.id === salesStage?.statusId)?.paramType
    );
  }, [salesStage, applicationParamList]);

  const isClosedFail = useMemo(() => {
    return ["LeadStatus-Fail", "DealStatus-Fail"].includes(
      applicationParamList?.find((ap) => ap?.id === salesStage?.statusId)?.paramType
    );
  }, [salesStage, applicationParamList]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <BreadCrumbs />
        <span style={{ display: "flex", gap: "0.5rem" }}>
          {stage === "lead" && isClosedSuccess ? null : salesStage?.id &&
            !isClosedSuccess &&
            !isClosedFail ? (
            <React.Fragment>
              <PrimaryButton
                size="small"
                onClick={handleSuccess}
                loading={loading?.success}
                disabled={loading?.fail || loading?.salesStage}
              >
                {stage === "lead" ? "Qualify" : "Close-Won"}
              </PrimaryButton>
              <LoadingButton
                sx={{
                  boxShadow: "none",
                  minWidth: "6rem",
                }}
                size="small"
                color="error"
                variant="contained"
                onClick={handleFailure}
                loading={loading?.fail}
                disabled={loading?.success || loading?.salesStage}
              >
                {stage === "lead" ? "Unqualify" : "Close-Lost"}
              </LoadingButton>
            </React.Fragment>
          ) : isClosedSuccess || isClosedFail ? (
            <LoadingButton
              sx={{
                boxShadow: "none",
                minWidth: "6rem",
              }}
              size="small"
              variant="outlined"
              onClick={handleReopen}
              loading={loading?.reopen || loading?.salesStage}
            >
              Reopen
            </LoadingButton>
          ) : null}
        </span>
      </div>

      <div className="sales-info-container">
        <AssignedSalesRepCard
          loading={loading?.salesStage}
          salesRep={salesRep}
          salesStage={salesStage}
          actionable={!isClosedFail && !isClosedSuccess}
          getSalesStage={getSalesStage}
        />
        <SalesDetails sales={salesStage?.sales} actionable={!isClosedFail && !isClosedSuccess} />
        <SalesInteractions
          getSalesStage={getSalesStage}
          stage={stage}
          salesStage={salesStage}
          loadingSalesStage={loading?.salesStage}
          actionable={!isClosedFail && !isClosedSuccess}
        />
      </div>
    </div>
  );
}
