import React from "react";
import { Typography } from "@mui/material";

const Header1 = ({ children, ...props }) => {
  return (
    <Typography
      color={"#202020"}
      sx={{
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

const Header2 = ({ children, ...props }) => {
  return (
    <Typography
      color={"#5D5D5D"}
      sx={{
        fontSize: "0.8rem",
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
const Header3 = ({ children, ...props }) => {
  return (
    <Typography
      color={"#999999"}
      sx={{
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

const Header4 = ({ children, ...props }) => {
  return (
    <Typography
      color={"#D9D9D9"}
      sx={{
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

const Header5 = ({ children, ...props }) => {
  return (
    <Typography
      color={"#E5E5E5"}
      sx={{
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export { Header1, Header2, Header3, Header4, Header5 };
