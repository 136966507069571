import React, { useEffect, useState } from "react";

import axios from "axios";
import { ApiEndpoints } from "../../configuration/apiEndpoints";

import BreadCrumbs from "../../component/breadCrumbs/breadCrumbs";
import GlobalTabs from "../../component/golablTab/globalTab";

import RoleManagement from "./roleManagement/roleManagement";
import UserListing from "./userManagement/userListing";

export default function AccessManagement() {
  const [rawRole, setRawRole] = useState();
  const [isLoading, setIsLoading] = useState();

  //==========================API Function=====================================
  const getAllRole = async () => {
    setIsLoading(true);
    await axios
      .get(ApiEndpoints.getRole)
      .then((resp) => {
        setRawRole(resp.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };
  //============================================================================

  useEffect(() => {
    getAllRole();
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "start",
        }}
      >
        <BreadCrumbs />
      </div>

      <div style={{ marginTop: "25px" }}>
        <GlobalTabs
          tabs={[
            {
              label: "Roles",
              component: (
                <RoleManagement rawRole={rawRole} isLoading={isLoading} getAllRole={getAllRole} />
              ),
            },
            {
              label: "Users",
              component: <UserListing roleList={rawRole} getAllRole={getAllRole} />,
            },
          ]}
        />
      </div>
    </div>
  );
}
