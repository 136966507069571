import {
  SET_IS_AUTHENTICATED,
  SET_USER,
  SET_MODULE_ACCESS,
  SET_IS_CURRENT_MODULE_ADMIN,
} from "./types";

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const setIsAuthenticated = (payload) => ({
  type: SET_IS_AUTHENTICATED,
  payload,
});

export const setModuleAccess = (payload) => ({
  type: SET_MODULE_ACCESS,
  payload,
});

export const setIsCurrentModuleAdmin = (payload) => ({
  type: SET_IS_CURRENT_MODULE_ADMIN,
  payload,
});
