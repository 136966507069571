import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Typography } from "@mui/material";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const GlobalTabs = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& .MuiTab-root": {
              color: "#999999", // Default color of tabs
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#34C6F5", // Color of selected tab
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#34C6F5", // Color of the tab indicator (underline)
            },
            minHeight: "2.5rem",
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              sx={{
                minHeight: "2.5rem",
                fontSize: "0.8rem",
                padding: "0.5rem 0.75rem",
                borderRadius: "4px 4px 0 0",
              }}
              label={tab.label}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

GlobalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default GlobalTabs;
