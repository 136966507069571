import React, { createContext, useContext, useCallback } from "react";
import { SnackbarProvider as NotistackProvider, useSnackbar as useNotiSnackbar } from "notistack";

const SnackbarContext = createContext();

function InnerSnackbarProvider({ children }) {
  const { enqueueSnackbar } = useNotiSnackbar();

  const openSnackbar = useCallback(
    (message, type) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    },
    [enqueueSnackbar]
  );

  return <SnackbarContext.Provider value={openSnackbar}>{children}</SnackbarContext.Provider>;
}

export function SnackbarProvider({ children }) {
  return (
    <NotistackProvider maxSnack={3}>
      <InnerSnackbarProvider>{children}</InnerSnackbarProvider>
    </NotistackProvider>
  );
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}
