import React, { useEffect, useMemo, useRef, useState } from "react";
import { Paper, Grid, IconButton, Button, Menu, MenuItem } from "@mui/material";
import { IconPencil, IconUpload, IconX } from "@tabler/icons-react";
import { Header1, Header2 } from "../../../../component/globalTypography/headers";
import FieldRender from "../fieldRender";
import defaultUser from "../../../../assets/images/defaultUser.jpg";
import { Controller } from "react-hook-form";
import { ProfilePictureDialog } from "./profilePictureDialog";
import axios from "axios";
import { ApiEndpoints } from "../../../../configuration/apiEndpoints";
import { useAuthentication } from "../../../../redux/authentication/hook";
import { useSnackbar } from "../../../../contexts/snackbarContext";
import ConfirmDialog from "../../../../component/confirmDialog/confirmDialog";

const PersonalDetails = (props) => {
  const { fields, fieldOptions, errors, control, employee, isEdit, getProfilePictureById } = props;
  const { user } = useAuthentication();
  const openSnackbar = useSnackbar();

  const ProfilePicture = ({ field, error, helperText }) => {
    const [image, setImage] = useState(null);
    const [newProfilePic, setNewProfilePic] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(null);
    const fileInputRef = useRef(null);
    const canvasRef = useRef(null);

    const openButtonList = Boolean(anchorEl);

    useEffect(() => {
      if (field.value) {
        convertFileToImage(field.value);
      } else setImage(null);
    }, [field.value]);

    const convertFileToImage = (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setImage(img.src);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    };

    const cropImage = (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = 300;
          canvas.height = 300;

          const size = Math.min(img.width, img.height);
          const startX = (img.width - size) / 2;
          const startY = (img.height - size) / 2;

          ctx.drawImage(img, startX, startY, size, size, 0, 0, 300, 300);
          canvas.toBlob((blob) => {
            const croppedFile = new File([blob], file.name, { type: file.type });
            if (isEdit) setNewProfilePic(croppedFile);
            else field.onChange(croppedFile);
          }, file.type);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    };

    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const validTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (!validTypes.includes(file.type)) {
          openSnackbar("Must be an image (JPG, JPEG, PNG)", "info");
        } else {
          cropImage(file);
          if (isEdit) {
            handleOpenDialog();
          }
        }
      }
    };

    const handleUpload = () => {
      fileInputRef.current.click();
    };

    const handleEdit = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleCloseEditMenu = () => {
      setAnchorEl(null);
    };

    const handleOpenDialog = () => {
      setOpenDialog(true);
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
      setNewProfilePic(null);
      fileInputRef.current.value = "";
    };

    const handleDialogConfirm = async () => {
      setLoading("upload");
      var data = {
        id: employee?.id,
        profilePicture: newProfilePic,
        by: user?.username,
      };
      var formData = new FormData();
      Object.keys(data)?.forEach((key) => {
        formData.append(key, data[key]);
      });
      await axios
        .post(`${ApiEndpoints.updateProfilePicture}/${employee?.id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          getProfilePictureById();
          openSnackbar("Successfully updated profile picture", "success");
        })
        .catch((error) => {
          openSnackbar("Failed to update profile picture", "error");
        })
        .finally(() => {
          setLoading(null);
          handleCloseDialog();
        });
    };

    const handleRemovePhoto = async () => {
      setLoading("remove");
      var data = {
        id: employee?.id,
        by: user?.username,
      };
      await axios
        .post(`${ApiEndpoints.deleteProfilePicture}/${employee?.id}`, data)
        .then((res) => {
          getProfilePictureById();
          openSnackbar("Successfully removed profile picture", "success");
        })
        .catch((error) => {
          openSnackbar("Failed to remove profile picture", "error");
        })
        .finally(() => {
          setLoading(null);
          setOpenConfirmDialog(false);
        });
    };

    const styles = {
      container: {
        position: "relative",
      },
      profilePic: {
        borderRadius: "50%",
        objectFit: "cover",
        aspectRatio: "auto 180 / 180",
        border: "1px solid grey",
      },
      errorMessage: {
        color: "red",
        marginTop: "10px",
        fontSize: "0.75rem",
      },
    };

    return (
      <div style={{ alignSelf: "center" }}>
        <div style={styles.container}>
          <img
            src={image || defaultUser}
            alt="Profile"
            style={styles.profilePic}
            width={180}
            height={180}
          />
          {field.value && !isEdit && (
            <IconButton
              size="small"
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={() => field.onChange(undefined)}
            >
              <IconX size="0.875rem" color="red" />
            </IconButton>
          )}
          {isEdit ? (
            <React.Fragment>
              <Button
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  marginBottom: "1rem",
                  textTransform: "none",
                  borderRadius: "10px",
                  padding: "5px 15px",
                }}
                size="small"
                color="info"
                variant="contained"
                startIcon={<IconPencil size={"0.9rem"} />}
                onClick={handleEdit}
              >
                Edit
              </Button>
              <Menu
                elevation={0}
                anchorEl={anchorEl}
                open={openButtonList}
                onClose={handleCloseEditMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseEditMenu();
                    handleUpload();
                  }}
                >
                  Upload photo
                </MenuItem>
                {field.value && (
                  <MenuItem
                    onClick={() => {
                      handleCloseEditMenu();
                      setOpenConfirmDialog(true);
                    }}
                  >
                    Remove photo
                  </MenuItem>
                )}
              </Menu>
            </React.Fragment>
          ) : (
            <Button
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                marginBottom: "1rem",
                textTransform: "none",
                borderRadius: "10px",
                padding: "5px 15px",
              }}
              size="small"
              variant="contained"
              startIcon={<IconUpload size={"0.9rem"} />}
              onClick={handleUpload}
            >
              Upload
            </Button>
          )}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageChange}
            accept="image/jpeg, image/png, image/gif"
            style={{ display: "none" }}
          />
          <canvas ref={canvasRef} style={{ display: "none" }} />
        </div>

        {error && <p style={styles.errorMessage}>{helperText}</p>}
        {openDialog && (
          <ProfilePictureDialog
            open={openDialog}
            close={handleCloseDialog}
            newProfilePic={newProfilePic}
            handleConfirm={handleDialogConfirm}
            loading={loading === "upload"}
          />
        )}
        {openConfirmDialog && (
          <ConfirmDialog
            open={openConfirmDialog}
            type={"error"}
            onSubmit={handleRemovePhoto}
            onClose={() => setOpenConfirmDialog(false)}
            dialogTitle={"Remove Profile Picture"}
            dialogContent={() => "Are you sure to remove profile picture?"}
            loading={loading === "remove"}
          />
        )}
      </div>
    );
  };

  const renderProfilePicture = useMemo(() => {
    return (
      <Controller
        name="profilePicture"
        control={control}
        render={({ field: { onChange, value, ...restField } }) => (
          <ProfilePicture
            field={{
              ...restField,
              onChange,
              value,
            }}
            error={!!errors?.profilePicture}
            helperText={errors?.profilePicture?.message}
          />
        )}
      />
    );
  }, [control, errors?.profilePicture]);

  return (
    <Paper
      sx={{ p: "1.5rem", border: "1px solid", borderColor: "#eeeeee", borderRadius: "10px" }}
      elevation={0}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header1 fontSize={"0.9375rem"}>Personal Details</Header1>
        </Grid>

        <Grid item xs={12} md={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Header2 fontSize={"0.9375rem"}>Profile Picture</Header2>
            {renderProfilePicture}
          </div>
        </Grid>

        <Grid item xs={12} md={9} container spacing={1}>
          {fields
            .filter((field) => field.id !== "profilePicture")
            .map((fieldItem) => {
              return (
                <Grid item xs={fieldItem.gridSize} key={fieldItem.id}>
                  <FieldRender
                    fieldItem={fieldItem}
                    fieldOptions={fieldOptions}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PersonalDetails;
