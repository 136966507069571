import {
  SET_APPLICATION_PARAM_LIST,
  SET_COMPANY_LIST,
  SET_DEPARTMENT_LIST,
  SET_EMPLOYEE_LIST,
  SET_LOADING,
  SET_MODULE_LIST,
  SET_ROLE_LIST,
} from "./types";
import locationData from "../../assets/data/countries+states+cities.json";
import nationalities from "../../assets/data/nationalities.json";

const initialState = {
  employeeList: null,
  loading: null,
  locationData: locationData,
  nationalities: nationalities,
  applicationParamList: null,
  companyList: null,
  departmentList: null,
  moduleList: null,
  roleList: null,
};

export default function ApplicationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEE_LIST:
      return {
        ...state,
        employeeList: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_APPLICATION_PARAM_LIST:
      return {
        ...state,
        applicationParamList: action.payload,
      };
    case SET_COMPANY_LIST:
      return {
        ...state,
        companyList: action.payload,
      };
    case SET_DEPARTMENT_LIST:
      return {
        ...state,
        departmentList: action.payload,
      };
    case SET_MODULE_LIST:
      return {
        ...state,
        moduleList: action.payload,
      };
    case SET_ROLE_LIST:
      return {
        ...state,
        roleList: action.payload,
      };
    default:
      return state;
  }
}
