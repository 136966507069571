import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { Header2 } from "../component/globalTypography/headers";
import GlobalTextField from "../component/gloabalTextfields/globalTextfield";
import { MuiTelInput } from "mui-tel-input";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete } from "@mui/material";

const useZodForm = () => {
  // const { schema } = props;
  const [schema, setSchema] = useState(z.object());
  const customZodResolver = async (values) => {
    try {
      const result = schema.parse(values);
      return { errors: {}, values: result };
    } catch (error) {
      if (error instanceof z.ZodError) {
        const formattedErrors = error.flatten().fieldErrors;
        const errors = Object.keys(formattedErrors).reduce((prev, curr) => {
          prev[curr] = { type: "manual", message: formattedErrors[curr][0] };
          return prev;
        }, {});

        return {
          errors: errors,
          values: {},
        };
      }
      return { errors: {}, values: {} };
    }
  };

  const { formState, control, ...formProps } = useForm({
    resolver: async (values) => customZodResolver(values),
  });

  const [errors, setErrors] = useState({});

  const allFieldValue = useWatch({ control });

  const validateForm = async (values) => {
    return customZodResolver(values, schema).then(({ values, errors }) => {
      if (errors) {
        setErrors(errors);
      } else {
        setErrors({});
      }
    });
  };
  const buildSchemaByFieldType = (field) => {
    let schema;
    switch (field.type) {
      case "string" || "phone":
        schema = z.string().min(1, "Required");
        break;
      case "email":
        schema = z.string().email();
        break;
      case "number":
        schema = z.number().refine((num) => {
          const rounded = Math.round(num * 100) / 100;
          return num === rounded;
        }, "Please enter a number with up to two decimal places.");
        break;
      case "date":
        schema = z.custom((value) => value instanceof dayjs, "Invalid Date");
        break;
      case "selection":
        schema = z.string();
        break;
      default:
        schema = z.string();
    }

    if (field.optional) {
      schema = schema.optional().or(z.literal("")).or(z.null());
    }

    return schema;
  };

  const convertFieldsToSchema = (fields) => {
    const schemaObject = fields.reduce((acc, field) => {
      acc[field.id] = buildSchemaByFieldType(field);
      return acc;
    }, {});
    return z.object(schemaObject).required();
  };

  const renderField = (fieldItem, fieldOptions, control, errors) => {
    const fieldOption = fieldItem.type === "selection" ? fieldOptions?.[fieldItem.id] : [];
    return (
      <div>
        <Header2 fontSize={"0.8rem"}>{`${fieldItem.label} ${
          !fieldItem.optional ? "*" : ""
        }`}</Header2>
        {["string", "email"].includes(fieldItem.type) && (
          <Controller
            name={fieldItem.id}
            control={control}
            render={({ field }) => {
              return (
                <GlobalTextField
                  {...field}
                  value={field?.value || ""}
                  size={"small"}
                  error={!!errors[fieldItem.id]}
                  helperText={errors[fieldItem.id]?.message}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    field.onChange(e.target.value || undefined);
                  }}
                />
              );
            }}
          />
        )}
        {fieldItem.type === "phone" && (
          <Controller
            name={fieldItem.id}
            control={control}
            render={({ field }) => {
              return (
                <MuiTelInput
                  {...field}
                  defaultCountry="MY"
                  value={field?.value || ""}
                  size={"small"}
                  error={!!errors[fieldItem.id]}
                  helperText={errors[fieldItem.id]?.message}
                  sx={{ width: "100%" }}
                  onChange={(value) => {
                    field.onChange(value ? value.replace(/\s/g, "") : undefined);
                  }}
                />
              );
            }}
          />
        )}
        {fieldItem.type === "number" && (
          <Controller
            name={fieldItem.id}
            control={control}
            render={({ field }) => {
              return (
                <GlobalTextField
                  {...field}
                  value={field?.value || ""}
                  type={"number"}
                  size={"small"}
                  error={!!errors[fieldItem.id]}
                  helperText={errors[fieldItem.id]?.message}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    field.onChange(parseFloat(e.target.value) || undefined);
                  }}
                />
              );
            }}
          />
        )}
        {fieldItem.type === "date" && (
          <Controller
            name={fieldItem.id}
            control={control}
            render={({ field, fieldState }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                      error: !!errors[fieldItem.id],
                      helperText: errors[fieldItem.id]?.message,
                    },
                  }}
                  TextFieldComponent={(props) => <GlobalTextField {...props} />}
                  onBlur={field.onBlur}
                  value={field?.value || null}
                  onChange={(event) => {
                    field.onChange(event ? dayjs(event) : undefined);
                  }}
                />
              </LocalizationProvider>
            )}
          />
        )}
        {fieldItem.type === "selection" && (
          <Controller
            name={fieldItem.id}
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={fieldOption || []}
                fullWidth
                onChange={(e, v) => {
                  field.onChange(v?.value || undefined);
                }}
                getOptionLabel={(o) => o?.label}
                onBlur={field.onBlur}
                value={fieldOption?.find((option) => option?.value === field?.value) || null}
                renderInput={(params) => (
                  <GlobalTextField
                    {...params}
                    size={"small"}
                    error={!!errors[fieldItem.id]}
                    helperText={errors[fieldItem.id]?.message}
                  />
                )}
              />
            )}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (formState.isSubmitted) {
      validateForm(allFieldValue);
    }
  }, [formState.isSubmitted, allFieldValue, schema]);

  return {
    control,
    formState,
    errors,
    allFieldValue,
    validateForm,
    setSchema,
    convertFieldsToSchema,
    renderField,
    ...formProps,
  };
};

export default useZodForm;
