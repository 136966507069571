import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const GlobalSearchTextField = ({ searchValue, handleClearSearch, ...props }) => {
  return (
    <TextField
      {...props}
      placeholder={"Search"}
      size={"small"}
      value={searchValue}
      sx={{
        ...props.sx,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#E5E5E5", // Default border color
          },
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="#D9D9D9" />
          </InputAdornment>
        ),
        endAdornment: searchValue && (
          <InputAdornment position="start">
            <IconButton sx={{ padding: "4px" }} onClick={() => handleClearSearch()}>
              <ClearIcon color="#D9D9D9" fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default GlobalSearchTextField;
