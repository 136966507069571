import React from "react";

import { Box, Grid, Paper, styled, Typography } from "@mui/material";

import BreadCrumbs from "../../component/breadCrumbs/breadCrumbs";
import PrimaryButton from "../../component/buttons/primaryButton";

import GlobalTabs from "../../component/golablTab/globalTab";

const Row = styled(Box)(({ theme, header }) => ({
  display: "flex",
  backgroundColor: "#CFEAFF",
  padding: theme.spacing(1, 2),
  marginBottom: header ? theme.spacing(1) : theme.spacing(1),
  boxShadow: header ? "0px 0px 4px rgba(0, 0, 0, 0.10)" : "none",
  borderRadius: 5,
  gap: "8px",
  "& > *": {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  "& > *:first-of-type": {
    flex: 1,
  },
  "& > *:nth-of-type(2)": {
    flex: 2,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: header ? "white" : "#E5F4FF",
  },
  color: header ? "#202020" : "#5D5D5D",
  fontWeight: header ? "500" : "400",
}));

const Cell = ({ children }) => <span style={{ fontSize: "0.8125rem" }}>{children}</span>;

const StatusCard = styled(Paper)(({ theme, color }) => ({
  padding: theme.spacing(2),
  backgroundColor: color,
  color: "white",
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const leaveBalances = [
  {
    leaveType: "AL",
    leaveDescription: "Annual Leave",
    bringForward: 7,
    entitlement: 14,
    leaveBurn: 0,
    taken: 5,
    balance: 16,
  },
  {
    leaveType: "CL",
    leaveDescription: "Compassionate Leave",
    bringForward: 0,
    entitlement: 3,
    leaveBurn: 0,
    taken: 0,
    balance: 3,
  },
  {
    leaveType: "HL",
    leaveDescription: "Hospitalization Leave",
    bringForward: 0,
    entitlement: 60,
    leaveBurn: 0,
    taken: 0,
    balance: 60,
  },
  {
    leaveType: "ML",
    leaveDescription: "Marriage Leave",
    bringForward: 0,
    entitlement: 5,
    leaveBurn: 0,
    taken: 0,
    balance: 5,
  },
  {
    leaveType: "UL",
    leaveDescription: "Unpaid Leave",
    bringForward: 0,
    entitlement: 99,
    leaveBurn: 0,
    taken: 0,
    balance: 99,
  },
];

export default function LeaveDashboard() {
  return (
    <div style={{ display: "grid", gap: "1.8rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <BreadCrumbs />
        <span style={{ display: "flex", gap: "0.5rem" }}>
          <PrimaryButton size={"small"}>Requests</PrimaryButton>
          <PrimaryButton size={"small"}>Apply Leave</PrimaryButton>
        </span>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8} style={{ overflowX: "auto" }}>
            <Row header>
              <Cell>Leave Type</Cell>
              <Cell>Leave Description</Cell>
              <Cell>Bring Forward</Cell>
              <Cell>Entitlement</Cell>
              <Cell>Leave Burn</Cell>
              <Cell>Taken</Cell>
              <Cell>Balance</Cell>
            </Row>
            {leaveBalances?.map((leaveBalance) => (
              <Row key={leaveBalance?.leaveType}>
                <Cell>{leaveBalance?.leaveType}</Cell>
                <Cell>{leaveBalance?.leaveDescription}</Cell>
                <Cell>{leaveBalance?.bringForward?.toFixed(1)}</Cell>
                <Cell>{leaveBalance?.entitlement?.toFixed(1)}</Cell>
                <Cell>{leaveBalance?.leaveBurn?.toFixed(1)}</Cell>
                <Cell>{leaveBalance?.taken?.toFixed(1)}</Cell>
                <Cell>{leaveBalance?.balance?.toFixed(1)}</Cell>
              </Row>
            ))}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={6}>
                <StatusCard color="#4CAF50">
                  <Typography variant="subtitle1">Pending Approval</Typography>
                  <Typography variant="h4">6</Typography>
                </StatusCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={6}>
                <StatusCard color="#F44336">
                  <Typography variant="subtitle1">Rejected Request</Typography>
                  <Typography variant="h4">3</Typography>
                </StatusCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={6}>
                <StatusCard color="#9C27B0">
                  <Typography variant="subtitle1">Withdrawal Request</Typography>
                  <Typography variant="h4">0</Typography>
                </StatusCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={6}>
                <StatusCard color="#009688">
                  <Typography variant="subtitle1">Approved Request</Typography>
                  <Typography variant="h4">10</Typography>
                </StatusCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div>
        <GlobalTabs
          tabs={[
            {
              label: "Your Request",
              component: <>Request</>,
            },
            {
              label: "Holiday List",
              component: <>Holiday List</>,
            },
          ]}
        />
      </div>
    </div>
  );
}
