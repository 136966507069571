import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./configuration/routes";
import { ThemeProvider, alpha, createTheme } from "@mui/material";
import { SnackbarProvider } from "./contexts/snackbarContext";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const theme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#E5E5E5",
          "&:hover": {
            borderColor: "#E5E5E5",
          },
        },
        root: {
          fontSize: "0.875rem",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#202020",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          "&.Mui-focused": {
            color: "#202020",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          "::after": {
            borderBottom: "1px solid #202020",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: "0.875rem",
        },
        noOptions: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "4px 1.2rem",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 6,
          marginTop: 8,
          minWidth: "8rem",
          color: "rgb(55, 65, 81)",
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        },
        list: {
          padding: "4px 0",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          "& .MuiSvgIcon-root": {
            fontSize: "0.875rem",
            marginRight: "0.875rem",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0.5rem",
          fontSize: "0.8125rem",
        },
        head: {
          color: "#202020",
        },
        body: {
          color: "#5D5D5D",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fontSize: "1rem",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: "500px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <Router>
            <Routes />
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
