import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

const GlobalTextField = React.forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      ref={ref}
      sx={{
        ...props.sx,
      }}
    />
  );
});

export default GlobalTextField;
