import React from "react";
import { useRoutes } from "react-router-dom";
import Layout from "../component/layout/layout";
import EmployeeListing from "../page/employeeManagement/employeeListing";
import Home from "../page/home/home";
import EmployeeForm from "../page/employeeManagement/employeeForm/employeeForm";
import EmployeeManagement from "../page/employeeManagement/employeeManagement";
import ParamSetting from "../page/applicationParamSetting/paramSetting";
import AccessManagement from "../page/accessManagement/accessManagement";
import EditRole from "../page/accessManagement/roleManagement/editRole";
import Login from "../page/login/login";
import ProtectedModuleRoute from "../component/protectedModuleRoute";
import {
  IconSettings,
  IconUserCog,
  IconUsersGroup,
  IconCurrentLocation,
  IconPremiumRights,
  IconDoorExit,
} from "@tabler/icons-react";
import NotFoundPage from "../component/errorPage/notFoundPage";

//CRM
import LeadManagement from "../page/leadManagement/leadManagement";
import CreateLead from "../page/leadManagement/createLead/createLead";
import SalesInfo from "../component/crm/salesInfo/salesInfo";

import DealManagement from "../page/dealManagement/dealManagement";
import CreateNewDeal from "../page/dealManagement/createNewDeal/createNewDeal";

import LeaveDashboard from "../page/leaveManagement/leaveDashboard";

export const routes = [
  {
    path: "login",
    element: <Login />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      // Employee Management
      {
        path: "employeeManagement",
        element: <ProtectedModuleRoute module="Employee Management" />,
        children: [
          {
            title: "Employee Management",
            path: "",
            element: <EmployeeManagement />,
            sideNav: { icon: <IconUsersGroup size={"1.5rem"} />, module: "Employee Management" },
          },
          {
            title: "Create Employee",
            path: "create",
            element: <EmployeeForm />,
          },
          {
            title: "Edit Employee",
            path: "edit",
            element: <EmployeeForm />,
          },
        ],
      },
      //Lead Management
      {
        path: "leadManagement",
        element: <ProtectedModuleRoute module="CRM" />,
        children: [
          {
            title: "Lead Management",
            path: "",
            element: <LeadManagement />,
            sideNav: { icon: <IconCurrentLocation size={"1.5rem"} />, module: "CRM" },
          },
          {
            title: "Lead Info",
            path: "leadInfo",
            element: <SalesInfo stage={"lead"} />,
          },
          {
            element: <ProtectedModuleRoute module="CRM" isAdminOnly />,
            children: [
              {
                title: "Create New Lead",
                path: "create",
                element: <CreateLead />,
              },
            ],
          },
        ],
      },
      //Deal Management
      {
        path: "dealManagement",
        element: <ProtectedModuleRoute module="CRM" />,
        children: [
          {
            title: "Deal Management",
            path: "",
            element: <DealManagement />,
            sideNav: { icon: <IconPremiumRights size={"1.5rem"} />, module: "CRM" },
          },
          {
            title: "Deal Info",
            path: "dealInfo",
            element: <SalesInfo stage={"deal"} />,
          },
          {
            element: <ProtectedModuleRoute module="CRM" isAdminOnly />,
            children: [
              {
                title: "Create New Deal",
                path: "create",
                element: <CreateNewDeal />,
              },
            ],
          },
        ],
      },
      // Param Setting
      {
        path: "parameterSetting",
        element: <ProtectedModuleRoute module="Parameter Setting" />,
        children: [
          {
            title: "Parameter Setting",
            path: "",
            element: <ParamSetting />,
            sideNav: { icon: <IconSettings size={"1.5rem"} />, module: "Parameter Setting" },
          },
        ],
      },
      // Access Management
      {
        path: "accessMangement",
        element: <ProtectedModuleRoute module="Access Management" />,
        children: [
          {
            title: "Access Management",
            path: "",
            element: <AccessManagement />,
            sideNav: { icon: <IconUserCog size={"1.5rem"} />, module: "Access Management" },
          },
          {
            title: "Edit Role",
            path: "edit",
            element: <EditRole />,
          },
        ],
      },
      // Leave Management
      // {
      //   path: "leaveDashboard",
      //   element: <ProtectedModuleRoute module="Leave Management" />,
      //   children: [
      //     {
      //       title: "Leave Dashboard",
      //       path: "",
      //       element: <LeaveDashboard />,
      //       sideNav: { icon: <IconDoorExit size={"1.5rem"} />, module: "Leave Management" },
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

function Routes() {
  return <>{useRoutes(routes)}</>;
}

export default Routes;
