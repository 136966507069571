import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";

import { ApiEndpoints } from "../../../configuration/apiEndpoints";

import { Grid, Typography } from "@mui/material";

import BreadCrumbs from "../../../component/breadCrumbs/breadCrumbs";
import GlobalTabs from "../../../component/golablTab/globalTab";
import ExistingClientListing from "./existingClientListing";
import PrimaryButton from "../../../component/buttons/primaryButton";
import { useAuthentication } from "../../../redux/authentication/hook";
import { useSnackbar } from "../../../contexts/snackbarContext";

import AssignSalesRepCard from "../../../component/crm/assignSalesRepCard";
import ConfirmDialog from "../../../component/confirmDialog/confirmDialog";
import { useNavigate } from "react-router-dom";
import ClientInfoForm from "../../../component/crm/clientInfoForm";
import useZodForm from "../../../hooks/useZodForm";

export default function CreateNewDeal() {
  const { user } = useAuthentication();
  const notification = useSnackbar();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    errors,
    setSchema,
    convertFieldsToSchema,
    renderField,
    allFieldValue,
  } = useZodForm();

  const [selectedSalesRep, setSelectedSalesRep] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [rawClientInfo, setRawClientInfo] = useState();
  const [loading, setLoading] = useState("");

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isNewClient, setIsNewClient] = useState(false);

  const handleCreateDeal = (data) => {
    const submitNewClient = Object.keys(data).length > 0;
    setIsNewClient(submitNewClient);
    if (!submitNewClient && selectedClient === null) {
      return notification("Please select a client", "warning");
    }
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };

  const createDeal = async () => {
    handleCloseConfirmationDialog();
    if (isNewClient) await createDealWithNewClient();
    else await createDealWithExistingClient();
  };

  const createDealWithNewClient = async () => {
    setIsSubmitLoading(true);
    const mappedFormData = Object.keys(allFieldValue)?.reduce((acc, key) => {
      const [model, property] = key?.split("_");
      if (!acc[model]) {
        acc[model] = {};
      }
      acc[model][property] = allFieldValue[key];
      return acc;
    }, {});
    const finalData = {
      ...mappedFormData,
      interactedById: user?.emp?.id,
      salesRepId: selectedSalesRep?.id,
      by: user?.username,
    };
    await axios
      .post(ApiEndpoints.createDealWithNewClient, finalData)
      .then((res) => {
        notification("Successfully created deal with existing client.", "success");
        navigate(`/dealManagement/dealInfo?id=${res.data?.id}`);
      })
      .catch((error) => {
        notification("Failed to create deal", "error");
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const createDealWithExistingClient = async () => {
    const data = {
      clientId: selectedClient?.clientId,
      salesRepId: selectedSalesRep?.id,
      interactedById: user?.emp?.id,
      by: user?.username,
    };
    setIsSubmitLoading(true);
    await axios
      .post(ApiEndpoints.createDealWithExistingClient, data)
      .then((res) => {
        notification("Successfully created deal with existing client.", "success");
        navigate(`/dealManagement/dealInfo?id=${res.data?.id}`);
      })
      .catch((error) => {
        notification("Failed to create deal", "error");
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const getAllClient = async () => {
    setLoading("clientList");
    await axios
      .get(ApiEndpoints.getAllClient)
      .then((resp) => {
        setRawClientInfo(resp.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(""));
  };

  useEffect(() => {
    getAllClient();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <BreadCrumbs />
        <PrimaryButton
          size={"small"}
          onClick={() => handleSubmit(handleCreateDeal)()}
          disabled={!selectedSalesRep}
          loading={isSubmitLoading}
        >
          {!selectedSalesRep ? "Select Sales Rep" : "Confirm"}
        </PrimaryButton>
      </div>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={3}>
          <AssignSalesRepCard
            selectedSalesRep={selectedSalesRep}
            setSelectedSalesRep={setSelectedSalesRep}
            stageName={'deal'}
          />
        </Grid>

        <Grid item xs={9}>
          <GlobalTabs
            tabs={[
              {
                label: "New Client",
                component: (
                  <div style={{ marginTop: "1rem" }}>
                    <ClientInfoForm
                      setSchema={setSchema}
                      convertFieldsToSchema={convertFieldsToSchema}
                      renderField={renderField}
                      control={control}
                      errors={errors}
                    />
                  </div>
                ),
              },
              {
                label: "Existing Client",
                component: (
                  <ExistingClientListing
                    rawClientInfo={rawClientInfo}
                    isLoading={loading === "clientList"}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                  />
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={openConfirmDialog}
        type={"info"}
        onSubmit={createDeal}
        onClose={handleCloseConfirmationDialog}
        dialogTitle={"Create New Deal"}
        dialogContent={() => (
          <Typography>{`Are you confirm to create new deal with ${
            isNewClient ? "new client" : "existing client"
          }?`}</Typography>
        )}
      />
    </div>
  );
}
