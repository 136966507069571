import { IconButton, Paper, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { Header1, Header3 } from "../../globalTypography/headers";
import { IconArrowsExchange } from "@tabler/icons-react";

import ReassigneSalesRepDialog from "./reassigneSalesRepDialog";
import { useAuthentication } from "../../../redux/authentication/hook";
import { ProfilePicture } from "../../profilePicture";

export default function AssignedSalesRepCard({
  loading,
  salesRep,
  actionable,
  salesStage,
  getSalesStage,
}) {
  const { isCurrentModuleAdmin } = useAuthentication();
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <Paper
      className="assigned-sales-rep"
      sx={{
        p: "1.2rem 1.5rem",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Header1 sx={{ fontSize: "0.9375rem" }}>Assigned Sales Representative</Header1>
        {isCurrentModuleAdmin && actionable && (
          <IconButton
            sx={{
              padding: "0.25rem",
            }}
            onClick={() => setOpenDialog(true)}
          >
            <IconArrowsExchange size="1.2rem" />
          </IconButton>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {loading ? (
          <Skeleton variant="circular" height={40} width={40} />
        ) : (
          <ProfilePicture id={salesRep?.id} />
        )}
        <span style={{ width: "70%" }}>
          <Header1 sx={{ fontSize: "0.9375rem" }}>
            {loading ? <Skeleton /> : salesRep?.displayName}
          </Header1>
          <Header3 sx={{ fontSize: "0.8125rem" }}>
            {loading ? <Skeleton /> : salesRep?.empEmployments?.[0]?.position}
          </Header3>
        </span>
      </div>
      <ReassigneSalesRepDialog
        open={openDialog}
        onClose={handleCloseDialog}
        salesStage={salesStage}
        getSalesStage={getSalesStage}
      />
    </Paper>
  );
}
