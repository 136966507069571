import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import SecondaryButton from "../../../component/buttons/secondaryButton";
import PrimaryButton from "../../../component/buttons/primaryButton";
import { Header2 } from "../../../component/globalTypography/headers";
import { z } from "zod";
import useZodForm from "../../../hooks/useZodForm";
import { Controller } from "react-hook-form";
import { Info, InfoOutlined } from "@mui/icons-material";
import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";
import { useSnackbar } from "../../../contexts/snackbarContext";
import ConfirmDialog from "../../../component/confirmDialog/confirmDialog";
import { useAuthentication } from "../../../redux/authentication/hook";
import { useApplication } from "../../../redux/application/hook";

const UserDialog = ({ open, action, handleClose, selectedUser, roleList, getAllRole }) => {
  const { user } = useAuthentication();
  const { updateEmployeeList } = useApplication();
  const { control, errors, handleSubmit, setSchema, setValue } = useZodForm();
  const openSnackbar = useSnackbar();
  const [requiredPassword, setRequiredPassword] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (action === "setup") setRequiredPassword(true);
  }, [action]);

  useEffect(() => {
    if (requiredPassword) {
      setSchema(
        z
          .object({
            username: z
              .string()
              .min(5, "Username must be at least 5 characters long")
              .max(20, "Username cannot exceed 20 characters"),
            password: z
              .string()
              .regex(new RegExp(".*[A-Z].*"), "Password must include at least one uppercase letter")
              .regex(new RegExp(".*[a-z].*"), "Password must include at least one lowercase letter")
              .regex(new RegExp(".*\\d.*"), "Password must contain at least one number")
              .min(8, "Password must be at least 8 characters long")
              .max(20, "Password cannot exceed 20 characters"),
            confirmPassword: z.string(),
            roleIds: z.array(z.string().uuid()).min(1, "Must select at least one role"),
          })
          .refine((data) => data.password === data.confirmPassword, {
            message: "Passwords do not match",
            path: ["confirmPassword"],
          })
      );
    } else {
      setSchema(
        z
          .object({
            username: z
              .string()
              .min(5, "Username must be at least 5 characters long")
              .max(20, "Username cannot exceed 20 characters"),
            roleIds: z.array(z.string().uuid()).min(1, "Must select at least one role"),
          })
          .refine((data) => data.password === data.confirmPassword, {
            message: "Passwords do not match",
            path: ["confirmPassword"],
          })
      );
    }
  }, [requiredPassword]);

  useEffect(() => {
    if (selectedUser) {
      setValue("username", selectedUser?.mapped?.username);
      setValue(
        "roleIds",
        selectedUser?.user?.userRoles?.map((userRole) => userRole?.roleId)
      );
    }
  }, [selectedUser]);

  const roleOptions = useMemo(() => {
    return roleList?.map((role) => {
      return {
        roleId: role?.id,
        roleName: role?.roleName,
      };
    });
  }, [roleList]);

  const passwordValidationMessages = [
    "Password must include at least one uppercase letter",
    "Password must include at least one lowercase letter",
    "Password must contain at least one number",
    "Password must be at least 8 characters long",
    "Password cannot exceed 20 characters",
  ];

  const onSubmit = (data) => {
    setConfirmDialog({
      open: true,
      title: action === "setup" ? "New Account Setup" : "Edit User Account",
      content:
        action === "setup"
          ? "Are you sure to proceed with the new account setup?"
          : "Are you sure to edit the user account?",
      submit: () => {
        setConfirmDialog({});
        if (action === "setup") newUserSetup(data);
        else updateUser(data);
      },
    });
  };

  const updateUser = async (data) => {
    setLoading(true);
    var mappedData = {
      ...data,
      id: selectedUser?.user?.id,
      by: user?.username,
      changePassword: requiredPassword,
    };
    await axios
      .post(`${ApiEndpoints.updateUserById}/${selectedUser?.user?.id}`, mappedData)
      .then((res) => {
        openSnackbar("Successfully updated user account", "success");
        handleClose();
        updateEmployeeList();
        getAllRole();
      })
      .catch((error) => {
        console.error(error);
        openSnackbar("Failed to update user account", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const newUserSetup = async (data) => {
    setLoading(true);
    var mappedData = {
      ...data,
      id: selectedUser?.user?.id,
      by: user?.username,
    };
    await axios
      .post(`${ApiEndpoints.newUserSetupById}/${selectedUser?.user?.id}`, mappedData)
      .then((res) => {
        openSnackbar("Successfully setup user account", "success");
        handleClose();
        updateEmployeeList();
        getAllRole();
      })
      .catch((error) => {
        console.error(error);
        openSnackbar("Failed to set user account", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{action === "setup" ? "New Account Setup" : "Edit User Account"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Header2 fontSize={"0.8rem"}>Name</Header2>
              <TextField value={selectedUser?.mapped?.name} size={"small"} fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Header2 fontSize={"0.8rem"}>Working Email</Header2>
              <TextField
                value={selectedUser?.mapped?.workEmail}
                size={"small"}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Header2 fontSize={"0.8rem"}>Position</Header2>
              <TextField value={selectedUser?.mapped?.position} size={"small"} fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Header2 fontSize={"0.8rem"}>Deprtment</Header2>
              <TextField
                value={selectedUser?.mapped?.department}
                size={"small"}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Header2 fontSize={"0.8rem"}>Username</Header2>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    value={field?.value ?? ""}
                    size="small"
                    error={!!errors?.username}
                    helperText={errors?.username?.message}
                    fullWidth
                    onBlur={field.onBlur}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Header2 fontSize={"0.8rem"}>
                Password{" "}
                {requiredPassword && (
                  <Tooltip
                    sx={{ fontSize: "inherit", textAlign: "justify" }}
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "0.5rem 0",
                          gap: "8px",
                          fontSize: "0.75rem",
                        }}
                      >
                        {passwordValidationMessages.map((message) => (
                          <li key={message}>{message}</li>
                        ))}
                      </div>
                    }
                    arrow
                  >
                    <InfoOutlined fontSize="inherit" />
                  </Tooltip>
                )}
              </Header2>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    type="password"
                    size="small"
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                    fullWidth
                    onBlur={field.onBlur}
                    disabled={!requiredPassword}
                    InputProps={{
                      endAdornment: action === "edit" && (
                        <InputAdornment position="end">
                          <Tooltip title="Change Password" arrow>
                            <Checkbox
                              checked={requiredPassword}
                              onChange={() => setRequiredPassword((curr) => !curr)}
                              size="small"
                            />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Header2 fontSize={"0.8rem"}>Confirm Password</Header2>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    type="password"
                    size="small"
                    error={!!errors?.confirmPassword}
                    helperText={errors?.confirmPassword?.message}
                    fullWidth
                    onBlur={field.onBlur}
                    disabled={!requiredPassword}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Header2 fontSize={"0.8rem"}>Roles</Header2>
              <Controller
                name="roleIds"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={roleOptions ?? []}
                    value={
                      roleOptions?.filter((role) => field?.value?.includes(role?.roleId)) ?? null
                    }
                    multiple
                    fullWidth
                    onChange={(e, v) => {
                      field.onChange(v?.map((x) => x?.roleId));
                    }}
                    getOptionLabel={(o) => o?.roleName}
                    onBlur={field.onBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"small"}
                        error={!!errors?.roleIds}
                        helperText={errors?.roleIds?.message}
                      />
                    )}
                    loading={true}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose} disabled={loading}>
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" loading={loading}>
            Confirm
          </PrimaryButton>
        </DialogActions>
      </form>
      {confirmDialog?.open && (
        <ConfirmDialog
          open={confirmDialog?.open}
          type={"info"}
          onSubmit={confirmDialog?.submit}
          onClose={() => setConfirmDialog({})}
          dialogTitle={confirmDialog?.title}
          dialogContent={() => confirmDialog?.content}
        />
      )}
    </Dialog>
  );
};

export default UserDialog;
