import React, { useState, useEffect, useMemo } from "react";

import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import { visuallyHidden } from "@mui/utils";

import { Link as RouterLink } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import {
  Paper,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Chip,
  Box,
  Button,
  Link,
} from "@mui/material";

import dayjs from "dayjs";

import GlobalSearchTextField from "../../component/gloabalTextfields/searchTextField";
import GlobalTextField from "../../component/gloabalTextfields/globalTextfield";
import GetAppIcon from "@mui/icons-material/GetApp";

import { TruncateString } from "../../component/truncateString/truncateString";

export default function DealListing(props) {
  const { MappedDealInfo, isLoading } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("clientName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchVal, setSearchVal] = useState("");

  const headCells = [
    // {
    //   id: "leadId",
    //   disablePadding: false,
    //   label: "Lead ID",
    //   center: false,
    // },
    {
      id: "client.clientName",
      disablePadding: false,
      label: "Client Name",
      center: false,
    },
    {
      id: "client.phoneNum",
      disablePadding: false,
      label: "Phone",
      center: false,
    },
    {
      id: "client.email",
      disablePadding: false,
      label: "Email",
      center: false,
    },
    {
      id: "createdOn",
      disablePadding: false,
      label: "Created On",
      center: false,
    },
    {
      id: "salesRep.displayName",
      disablePadding: false,
      label: "Assigned To",
      center: false,
    },
    {
      id: "status",
      disablePadding: false,
      label: "Status",
      center: false,
    },
    {
      id: "notes",
      disablePadding: false,
      label: "Notes",
      center: false,
    },
  ];

  function generateStatusColor(status) {
    // Convert status string to a unique number
    const hashCode = function (s) {
      return s.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    };

    let hash = 0;
    for (let i = 0; i < status?.length; i++) {
        hash = (hash << 5) - hash + status?.charCodeAt(i);
    }
    // Normalize the hash to a value between 0 and 1
    const normalized = (hash >>> 0) / 0xFFFFFFFF;

    const hashCodeNumber = Math.abs(hashCode(status));
    const hue = hashCodeNumber % 360; // Use modulo to ensure hue is within 0-359
    const saturation = 70; // Saturation set to 70%
    const lightness = 60 + normalized * 10; // Lightness ranges from 60 to 70

    // Convert HSL to RGB for CSS color representation
    const rgbColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

    return rgbColor;
  }

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(
      filterData.map((x) => {
        return {
          "Client Name": x.client.clientName,
          Phone: x.client.phoneNum,
          Email: x.client.email,
          "Created On": x.createdOn,
          "Assigned To": x.salesRep.displayName,
          Status: x.status,
          Notes: x.notes,
        };
      })
    );

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a binary string for the workbook
    const workbookBinary = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to an array buffer
    const buffer = new ArrayBuffer(workbookBinary.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < workbookBinary.length; i++) {
      view[i] = workbookBinary.charCodeAt(i) & 0xff;
    }

    // Create a blob from the buffer and trigger a download
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `Deal List Export - ${dayjs(new Date()).format("DD-MM-YYYY")}.xlsx`
    );
  };

  //==========================Search Function================================
  const handleFilterLeadBySearchVal = (data) => {
    var lowerCaseSearchVal = searchVal.toLowerCase() ?? "";
    return (
      data.client.clientName?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.client.phoneNum?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.client.email?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.createdOn?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.salesRep?.displayName?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.status?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.notes?.toLowerCase().includes(lowerCaseSearchVal)
    );
  };
  //============================================================================

  //==========================Table Sort Function=============================
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((value, key) => value?.[key], obj);
  };

  const getComparator = (order, orderBy) => {
    return (a, b) => {
      const aValue = getNestedValue(a, orderBy);
      const bValue = getNestedValue(b, orderBy);

      if (typeof aValue === "string" && typeof bValue === "string") {
        return order === "desc"
          ? bValue.localeCompare(aValue, undefined, {
              numeric: true,
              sensitivity: "base",
            })
          : aValue.localeCompare(bValue, undefined, {
              numeric: true,
              sensitivity: "base",
            });
      }

      // Handle other types if needed (e.g., numbers, dates)
      if (aValue < bValue) return order === "desc" ? 1 : -1;
      if (aValue > bValue) return order === "desc" ? -1 : 1;
      return 0;
    };
  };

  const handleRequestRoleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //==========================================================================

  //==========================Pagination Function================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //=============================================================================

  const statusFilter = useMemo(() => {
    return MappedDealInfo?.sort((a, b) => a.status.localeCompare(b.status))
      ?.map((x) => x.status)
      ?.filter((v, i, a) => a.indexOf(v) === i);
  }, [MappedDealInfo]);

  const filterData = useMemo(() => {
    return MappedDealInfo?.filter((a) =>
      selectedStatus ? selectedStatus === a.status : true
    )
      ?.filter((a) => handleFilterLeadBySearchVal(a))
      ?.sort(getComparator(order, orderBy));
  }, [MappedDealInfo, order, orderBy, selectedStatus, searchVal]);

  return (
    <div>
      <div style={{ marginTop: "30px" }}>
        <Paper sx={{ p: 2, border: "1px solid #E5E5E5", boxShadow: "none" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", width: "100%" }}>
              <GlobalSearchTextField
                sx={{ width: "40%" }}
                onChange={(e) => setSearchVal(e.target.value)}
                searchValue={searchVal}
                handleClearSearch={() => setSearchVal("")}
              />
              <Autocomplete
                size="small"
                sx={{ ml: 1, width: "20%" }}
                loading={isLoading}
                options={isLoading ? [] : statusFilter}
                onChange={(e, v) => setSelectedStatus(v)}
                renderInput={(params) => (
                  <GlobalTextField
                    label={"Status"}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>

            <div>
              <Button
                size="small"
                sx={{
                  backgroundColor: "#1D6F42",
                  color: "#ffffff",
                  width: 100,
                  "&:hover": {
                    backgroundColor: "#00cc00",
                  },
                }}
                onClick={() => exportToExcel()}
                startIcon={<GetAppIcon />}
              >
                Export
              </Button>
            </div>
          </div>

          <div style={{ marginTop: "31px" }}>
            <TableContainer sx={{ maxHeight: "55vh" }}>
              <Table stickyHeader aria-labelledby="tableTitle">
                <TableHead sx={{ zIndex: 1 }}>
                  <TableRow>
                    <TableCell></TableCell>
                    {headCells.map((item) => (
                      <TableCell align="left">
                        <TableSortLabel
                          active={orderBy === item?.id}
                          direction={order === "asc" ? "asc" : "desc"}
                          onClick={() => handleRequestRoleSort(item.id)}
                        >
                          {item.label}
                          {orderBy === item?.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <CircularProgress size={"2rem"} />
                      </TableCell>
                    </TableRow>
                  ) : filterData?.length > 0 ? (
                    filterData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((x, index) => {
                        return (
                          <TableRow
                            key={x.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            hover
                          >
                            <TableCell></TableCell>
                            <TableCell>
                                <Link
                                  underline="hover"
                                  color="#34C6F5"
                                  to={`/dealManagement/dealInfo?id=${x?.salesStageId}`}
                                  component={RouterLink}
                                >
                                  {x.client?.clientName}
                                </Link>
                              </TableCell>
                            <TableCell>{x.client.phoneNum}</TableCell>
                            <TableCell>{x.client.email}</TableCell>
                            <TableCell>{x.createdOn}</TableCell>
                            <TableCell>{x.salesRep.displayName}</TableCell>
                            <TableCell>
                              <Chip
                                label={x.status}
                                size="medium"
                                sx={{
                                  backgroundColor: generateStatusColor(x.status),
                                  color: "#ffffff",
                                }}
                              />
                            </TableCell>
                            <TableCell>{TruncateString(x.notes,20)}</TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No Record Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}
