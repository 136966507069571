import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthentication } from "../redux/authentication/hook";
import ForbiddenPage from "./errorPage/forbiddenPage";

export default function ProtectedModuleRoute({ module, isAdminOnly }) {
  const { isAuthenticated, moduleAccess, updateIsCurrentModuleAdmin } =
    useAuthentication();
  const location = useLocation();

  useEffect(() => {
    if (moduleAccess?.length > 0)
      updateIsCurrentModuleAdmin(
        moduleAccess?.some((m) => m?.name === module && m?.isAdmin)
      );
  }, [module, moduleAccess]);

  return moduleAccess?.some(
    (m) => m?.name === module && (isAdminOnly ? m?.isAdmin : true)
  ) ? (
    <Outlet />
  ) : isAuthenticated ? (
    <ForbiddenPage />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace={true} />
  );
}
