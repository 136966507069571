import React, { useState } from "react";
import BreadCrumbs from "../../../component/breadCrumbs/breadCrumbs";

import { Grid } from "@mui/material";
import ClientInfoForm from "../../../component/crm/clientInfoForm";
import useZodForm from "../../../hooks/useZodForm";
import PrimaryButton from "../../../component/buttons/primaryButton";
import { useSnackbar } from "../../../contexts/snackbarContext";
import { useAuthentication } from "../../../redux/authentication/hook";
import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";
import AssignSalesRepCard from "../../../component/crm/assignSalesRepCard";
import { useNavigate } from "react-router-dom";

export default function CreateLead() {
  const { handleSubmit, control, errors, setSchema, convertFieldsToSchema, renderField } =
    useZodForm();
  const { user } = useAuthentication();
  const navigate = useNavigate();
  const openSnackbar = useSnackbar();
  const [loading, setLoading] = useState("");

  const [selectedSalesRep, setSelectedSalesRep] = useState();

  const onFormSubmit = async (data) => {
    setLoading("submit");
    const mappedFormData = Object.keys(data)?.reduce((acc, key) => {
      const [model, property] = key?.split("_");
      if (!acc[model]) {
        acc[model] = {};
      }
      acc[model][property] = data[key];
      return acc;
    }, {});
    const finalData = {
      ...mappedFormData,
      interactedById: user?.emp?.id,
      salesRepId: selectedSalesRep?.id,
      by: user?.username,
    };
    await axios
      .post(ApiEndpoints.createLead, finalData)
      .then((res) => {
        console.log(res);
        navigate(`/leadManagement/leadInfo?id=${res.data?.id}`);
        openSnackbar("Successfully created new lead", "success");
      })
      .catch((error) => {
        console.log(error);
        openSnackbar("Failed to create new lead", "errro");
      })
      .finally(() => {
        setLoading("");
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
          <BreadCrumbs />
          <PrimaryButton
            type="submit"
            size="small"
            disabled={!selectedSalesRep}
            loading={loading === "submit"}
          >
            {!selectedSalesRep ? "Select Sales Rep" : "Confirm"}
          </PrimaryButton>
        </div>
        <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={3.2}>
            <AssignSalesRepCard
              selectedSalesRep={selectedSalesRep}
              setSelectedSalesRep={setSelectedSalesRep}
              stageName={'lead'}
            />
          </Grid>
          <Grid item xs={12} md={8.8}>
            <ClientInfoForm
              setSchema={setSchema}
              convertFieldsToSchema={convertFieldsToSchema}
              renderField={renderField}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
