import { useSelector, useDispatch } from "react-redux";
import { setUser, setIsAuthenticated, setModuleAccess, setIsCurrentModuleAdmin } from "./actions";

export const useAuthentication = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, moduleAccess, isCurrentModuleAdmin } = useSelector(
    (state) => state.authentication
  );

  const updateUser = (user) => {
    dispatch(setUser(user));
  };

  const updateIsAuthenticated = (isAuthenticated) => {
    dispatch(setIsAuthenticated(isAuthenticated));
  };

  const deauthenticateUser = () => {
    localStorage.removeItem("accessToken");
    dispatch(setUser(null));
    dispatch(setIsAuthenticated(false));
  };

  const updateModuleAccess = (moduleAccess) => {
    dispatch(setModuleAccess(moduleAccess));
  };

  const updateIsCurrentModuleAdmin = (moduleAccess) => {
    dispatch(setIsCurrentModuleAdmin(moduleAccess));
  };

  return {
    user,
    isAuthenticated,
    moduleAccess,
    isCurrentModuleAdmin,
    updateUser,
    updateIsAuthenticated,
    deauthenticateUser,
    updateModuleAccess,
    updateIsCurrentModuleAdmin,
  };
};
