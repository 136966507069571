import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";
import {ApiEndpoints} from "../../configuration/apiEndpoints"

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    Tooltip,
    Chip,
    IconButton,
    Box,
    styled,
    alpha,
    Typography,
    Menu,
    MenuItem
  } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircularProgress from "@mui/material/CircularProgress";
import { visuallyHidden } from "@mui/utils";

import ConfirmDialog from '../../component/confirmDialog/confirmDialog';

import { useSnackbar } from "../../contexts/snackbarContext";
import { useApplication } from "../../redux/application/hook";

import { Delete, Edit, Refresh as RefreshIcon, RemoveRedEye } from "@mui/icons-material";

export default function ParamSettingListing(props) {
  const {
    FilterApplicationParam,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRequestRoleSort,
    isLoading,
    setOpenParamDialog,
    selectedParamSetting,
    setSelectedParamSetting,
    action,
    setAction,
  } = props;

  const {updateApplicationParamList} = useApplication();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openButtonList, setOpenButtonList] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const notification = useSnackbar();

  const headCells = [
    {
      id: "no",
      disablePadding: false,
      label: "No",
      center: false,
    },
    {
      id: "paramValue",
      disablePadding: false,
      label: "Param Value",
      center: false,
    },
    {
      id: "paramType",
      disablePadding: false,
      label: "Param Type",
      center: false,
    },
    {
      id: "moduleName",
      disablePadding: false,
      label: "Module",
      center: false,
    },
    {
      id: "paramStatus",
      disablePadding: false,
      label: "Status",
      center: false,
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action",
      center: false,
    },
  ];

  //==========================Confirmation Dialog Function=====================
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedParamSetting(null);
    setAction("");
  };
  //============================================================================

  //==========================API Function=====================================
  const handleSubmit = async () => {
    if (action === "Delete") {
      setIsSubmitLoading(true);
      await axios
        .delete(
          `${ApiEndpoints.deleteApplicationParam}/${selectedParamSetting?.id}`
        )
        .then((resp) => {
          notification("Successfully deleted parameter.", "success");
          updateApplicationParamList();
        })
        .catch((err) => {
          notification("Fail to delete parameter", "error");
        })
        .finally(() => {
          setIsSubmitLoading(false);
          handleCloseConfirmationDialog();
          
        });
    }

    if (action === "Update Status") {
      setIsSubmitLoading(true);
      await axios
        .put(
          `${ApiEndpoints.updateApplicationParamStatus}/${selectedParamSetting?.id}`,
          {
            id: selectedParamSetting?.id,
            paramStatus: selectedParamSetting?.paramStatus === "Active" ? 0 : 1,
            by: "SYS",
          }
        )
        .then((resp) => {
          notification("Status successfully updated", "success");
          updateApplicationParamList();
        })
        .catch((err) => {
          notification("Fail to update status", "error");
        })
        .finally(() => {
          setIsSubmitLoading(false);
          handleCloseConfirmationDialog();
        });
    }
    
  };
  //===========================================================================

  //==========================Button List Function================================
  const handleClickButtonList = (event, row) => {
    setOpenButtonList((prev) => !prev);
    setAnchorEl(event.currentTarget);
    setSelectedParamSetting(row);
  };

  const handleCloseButtonList = () => {
    setOpenButtonList(false);
  };

  const handleActionButtonClick = (action) => {
    setAction(action);
    setOpenParamDialog(true);
    setOpenButtonList(false);
  }

  const renderPopper = () => {
    return (
      openButtonList && (
        <>
          <Menu
            elevation={0}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={openButtonList}
            onClose={handleCloseButtonList}
          >
            <MenuItem
              onClick={() => {
                handleActionButtonClick("View");
              }}
            >
              <RemoveRedEye />
              View
            </MenuItem>
            <MenuItem
            onClick={() => {
                handleActionButtonClick("Update");
              }}
            >
              <Edit />
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAction("Delete");
                setOpenConfirmDialog(true);
                setOpenButtonList(false);
              }}
            >
              <Delete />
              Delete
            </MenuItem>
          </Menu>
        </>
      )
    );
  };
  //=============================================================================
  
  //==========================Pagination Function================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //=============================================================================

  return (
    <div>
      {" "}
      <TableContainer sx={{ maxHeight: "55vh" }}>
        <Table stickyHeader aria-labelledby="tableTitle">
          <TableHead sx={{ zIndex: 1 }}>
            <TableRow>
              <TableCell></TableCell>
              {headCells.map((item) => (
                <TableCell align="left">
                  <TableSortLabel
                    active={orderBy === item?.id}
                    direction={order === "asc" ? "asc" : "desc"}
                    onClick={() => handleRequestRoleSort(item.id)}
                  >
                    {item.label}
                    {orderBy === item?.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress size={"2rem"} sx={{ pt: 20, pb: 20 }} />
                </TableCell>
              </TableRow>
            ) : FilterApplicationParam?.length > 0 ? (
              FilterApplicationParam.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((x, index) => {
                var actualIndex = (page + 1 - 1) * rowsPerPage + (index + 1);
                return (
                  <TableRow
                    key={x.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    hover
                  >
                    <TableCell></TableCell>
                    <TableCell>{actualIndex}</TableCell>
                    <TableCell>{x.paramValue}</TableCell>
                    <TableCell>{x.paramType}</TableCell>
                    <TableCell>{x.moduleName}</TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      <Chip
                        label={x?.paramStatus}
                        size="medium"
                        sx={{
                          color:
                            x.paramStatus === "Active" ? "#28A745" : "#DC3545",
                          boxShadow: "none",
                        }}
                        style={{
                          backgroundColor:
                            x.paramStatus === "Active" ? "#D4EDDA" : "#F8D7DA",
                        }}
                        onClick={() => {
                          setSelectedParamSetting(x);
                          setAction("Update Status");
                          setOpenConfirmDialog(true);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Action Buttons">
                        <IconButton
                          onClick={(event) => {
                            handleClickButtonList(event, x);
                          }}
                        >
                          <MoreVertIcon sx={{ color: "#999999" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Record Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={FilterApplicationParam?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {renderPopper()}
      <ConfirmDialog
        open={openConfirmDialog}
        loading={isSubmitLoading}
        type={action === "Delete" ? "error" : "info"}
        onSubmit={handleSubmit}
        onClose={handleCloseConfirmationDialog}
        dialogTitle={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {`${action} Parameter Confirmation`}
          </span>
        }
        dialogContent={() =>
          action === "Delete" ? (
            <Typography>{`Are you confirm to ${action?.toLowerCase()} this parameter?`}</Typography>
          ) : (
            <Typography>Are you confirm to update this parameter status?</Typography>
          )
        }
      />
    </div>
  );
}

