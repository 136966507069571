import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Header1, Header2 } from "../../globalTypography/headers";
import PrimaryButton from "../../buttons/primaryButton";
import { useApplication } from "../../../redux/application/hook";
import SalesInteractionTimelines from "./salesInteractionTimelines";
import { useAuthentication } from "../../../redux/authentication/hook";
import { useSnackbar } from "../../../contexts/snackbarContext";
import useZodForm from "../../../hooks/useZodForm";
import { Controller } from "react-hook-form";
import { z } from "zod";
import { IconFileUpload } from "@tabler/icons-react";
import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";

export default function SalesInteractions({
  getSalesStage,
  stage,
  salesStage,
  loadingSalesStage,
  actionable,
}) {
  const { user } = useAuthentication();
  const { applicationParamList } = useApplication();
  const openSnackbar = useSnackbar();
  const {
    control,
    errors,
    allFieldValue,
    setSchema,
    handleSubmit,
    setValue,
    formState: { isDirty },
    reset: resetForm,
  } = useZodForm();
  const [loading, setLoading] = useState("");

  const FileInput = ({ field, error, helperText }) => {
    const inputRef = useRef(null);

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDrop = (e) => {
      if (actionable) {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) field.onChange(file);
      }
    };

    const handleClick = () => {
      inputRef.current?.click();
    };

    return (
      <Paper
        variant="outlined"
        onClick={handleClick}
        sx={{
          cursor: actionable ? "pointer" : null,
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderColor: error ? "error.main" : "divider",
          borderRadius: 1,
          backgroundColor: "action.hover",
          "&:hover": {
            borderColor: actionable ? "#202020" : null,
          },
          textAlign: "center",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          ref={inputRef}
          type="file"
          hidden
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) field.onChange(file);
          }}
          accept=".doc,.docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png,.gif,.bmp,.webp"
          disabled={!actionable}
        />
        <IconFileUpload color="#D9D9D9" />
        <Header1 fontSize={"0.7rem"}>
          {field.value ? field.value.name : "Drag and drop a file here or click to select"}
        </Header1>
        {error && (
          <Typography fontSize={"0.7rem"} color="error" variant="caption" sx={{ mt: 1 }}>
            {helperText}
          </Typography>
        )}
      </Paper>
    );
  };

  useEffect(() => {
    setSchema(
      z.object({
        stageStatusId: z.string(),
        typeId: z.string(),
        remark: z.string(),
        attachment: z
          .instanceof(File)
          .refine(
            (file) => {
              const allowedTypes = [
                "application/msword", // .doc
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
                "application/vnd.ms-excel", // .xls
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
                "application/pdf", // .pdf
                "image/jpeg", // .jpg, .jpeg
                "image/png", // .png
              ];
              return allowedTypes.includes(file.type);
            },
            {
              message: "Attachment must be a document (Word, Excel, PDF) or an image (JPEG, PNG)",
            }
          )
          .optional(),
      })
    );
  }, []);

  useEffect(() => {
    if (salesStage?.statusId) {
      resetForm({
        stageStatusId: salesStage?.statusId,
        typeId: undefined,
        remark: undefined,
        attachment: undefined,
      });
    }
  }, [salesStage]);

  const statuses = useMemo(() => {
    const closedStatuses =
      stage === "lead"
        ? ["LeadStatus-Success", "LeadStatus-Fail"]
        : stage === "deal"
        ? ["DealStatus-Success", "DealStatus-Fail"]
        : [];
    const inProgressStatuses =
      stage === "lead"
        ? ["LeadStatus", "LeadStatus-New"]
        : stage === "deal"
        ? ["DealStatus", "DealStatus-New"]
        : [];
    const statuses = !actionable ? closedStatuses : inProgressStatuses;
    return applicationParamList
      ?.filter((param) => statuses.includes(param?.paramType))
      ?.map((param) => {
        return {
          value: param?.id,
          label: param?.paramValue,
        };
      });
  }, [stage, applicationParamList, actionable]);

  const interactionTypes = useMemo(() => {
    return applicationParamList
      ?.filter((param) => param?.paramType === "InteractionType")
      ?.map((param) => {
        return {
          value: param?.id,
          label: param?.paramValue,
        };
      });
  }, [applicationParamList]);

  const handleAddRemark = (data) => {
    setLoading("addRemark");
    const mappedData = {
      ...data,
      salesStageId: salesStage?.id,
      interactedById: user?.emp?.id,
      by: user?.username,
    };
    var formData = new FormData();
    Object.keys(mappedData)?.forEach((key) => {
      formData.append(key, mappedData[key]);
    });
    axios
      .post(ApiEndpoints.createSalesInteraction, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        getSalesStage();
        openSnackbar("Successfully added remark", "success");
      })
      .catch((error) => {
        openSnackbar("Failed to add remark", "error");
      })
      .finally(() => {
        setLoading("");
      });
  };

  function generateStatusColor(status) {
    // Convert status string to a unique number
    const hashCode = function (s) {
      return s?.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    };

    let hash = 0;
    for (let i = 0; i < status?.length; i++) {
      hash = (hash << 5) - hash + status?.charCodeAt(i);
    }
    // Normalize the hash to a value between 0 and 1
    const normalized = (hash >>> 0) / 0xffffffff;

    const hashCodeNumber = Math.abs(hashCode(status));
    const hue = hashCodeNumber % 360; // Use modulo to ensure hue is within 0-359
    const saturation = 70; // Saturation set to 70%
    const lightness = 60 + normalized * 10; // Lightness ranges from 60 to 70

    // Convert HSL to RGB for CSS color representation
    const rgbColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

    return rgbColor;
  }

  return (
    <Paper
      className="interaction-remarks"
      sx={{
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        display: "grid",
        gridTemplateAreas: `
              "header header"
              "content input"
            `,
        gridTemplateColumns: "2fr 1fr",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <div
        style={{
          gridArea: "header",
          padding: "1.2rem 1.5rem",
          borderBottom: "1px #E5E5E5 solid",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <Header1 sx={{ fontSize: "0.9375rem" }}>Interaction Remarks</Header1>
      </div>
      <div
        style={{
          gridArea: "content",
          padding: "1.2rem 0",
          backgroundColor: "#FAFAFA",
          borderBottomLeftRadius: "10px",
          overflow: "auto",
        }}
      >
        {loadingSalesStage ? (
          <span style={{ display: "grid", height: "100%", placeContent: "center" }}>
            <CircularProgress sx={{ placeItems: "center" }} />
          </span>
        ) : (
          <SalesInteractionTimelines salesStage={salesStage} />
        )}
      </div>
      <form
        onSubmit={handleSubmit(handleAddRemark)}
        style={{
          gridArea: "input",
          padding: "1.2rem 1.5rem",
          borderBottomRightRadius: "10px",
          borderLeft: "1px #E5E5E5 solid",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <span>
          <Header2>Status</Header2>
          <Controller
            name="stageStatusId"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={statuses || []}
                fullWidth
                onChange={(e, v) => {
                  field.onChange(v?.value || undefined);
                }}
                getOptionLabel={(o) => o?.label}
                renderOption={(params, o) => (
                  <li {...params}>
                    <span
                      style={{
                        borderRadius: "50%",
                        width: "8px",
                        height: "8px",
                        backgroundColor: generateStatusColor(o?.label),
                        marginRight: "10px",
                      }}
                    ></span>
                    {o?.label}
                  </li>
                )}
                onBlur={field.onBlur}
                value={statuses?.find((option) => option?.value === field?.value) || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={!!errors?.stageStatusId}
                    helperText={errors?.stageStatusId?.message}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: field?.value && (
                        <span
                          style={{
                            borderRadius: "50%",
                            width: "8px",
                            height: "8px",
                            backgroundColor: generateStatusColor(
                              statuses?.find((option) => option?.value === field?.value)?.label
                            ),
                            marginLeft: "10px",
                          }}
                        ></span>
                      ),
                    }}
                  />
                )}
                disabled={!actionable}
              />
            )}
          />
        </span>
        <span>
          <Header2>Type</Header2>
          <Controller
            name="typeId"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={interactionTypes || []}
                fullWidth
                onChange={(e, v) => {
                  field.onChange(v?.value || undefined);
                }}
                getOptionLabel={(o) => o?.label}
                onBlur={field.onBlur}
                value={interactionTypes?.find((option) => option?.value === field?.value) || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={!!errors?.typeId}
                    helperText={errors?.typeId?.message}
                  />
                )}
                disabled={!actionable}
              />
            )}
          />
        </span>
        <span>
          <Header2>Remarks</Header2>
          <Controller
            name="remark"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  fullWidth
                  size={"small"}
                  value={field?.value || ""}
                  error={!!errors?.remark}
                  helperText={errors?.remark?.message}
                  onChange={(e) => {
                    field.onChange(e.target.value || undefined);
                  }}
                  disabled={!actionable}
                />
              );
            }}
          />
        </span>
        <span>
          <Header2>Attachment</Header2>
          <Controller
            name="attachment"
            control={control}
            render={({ field: { onChange, value, ...restField } }) => (
              <Box display="flex" flexDirection="column" gap="0.5rem">
                <FileInput
                  field={{
                    ...restField,
                    onChange,
                    value,
                  }}
                  error={!!errors?.attachment}
                  helperText={errors?.attachment?.message}
                />
                {value && (
                  <Button
                    variant="text"
                    size="small"
                    color="error"
                    sx={{
                      width: "fit-content",
                      fontSize: "0.75rem",
                      textDecoration: "underline",
                      textTransform: "none",
                      alignSelf: "flex-end",
                    }}
                    onClick={() => onChange(undefined)}
                  >
                    Remove Attachment
                  </Button>
                )}
              </Box>
            )}
          />
        </span>
        <PrimaryButton
          type="submit"
          size="small"
          sx={{ marginTop: "auto", alignSelf: "flex-end" }}
          disabled={!isDirty || !actionable}
          loading={loading === "addRemark"}
        >
          Add Remark
        </PrimaryButton>
      </form>
    </Paper>
  );
}
