import React, { useState, useEffect, useMemo } from "react";

import GlobalTabs from "../../component/golablTab/globalTab";
import BreadCrumbs from "../../component/breadCrumbs/breadCrumbs";
import PrimaryButton from "../../component/buttons/primaryButton";

import { Link } from "react-router-dom";

import DealListing from "./dealListing";

import axios from "axios";
import { ApiEndpoints } from "../../configuration/apiEndpoints";

import dayjs from "dayjs";

import { useAuthentication } from "../../redux/authentication/hook";

export default function DealManagement() {
  const {user, isCurrentModuleAdmin, moduleAccess} = useAuthentication();
  const [rawDealInfo, setRawDealInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);

  //==========================API Function=====================================
  const getAllDealInfo = async () => {
    if (isCurrentModuleAdmin === true) {
      setIsLoading(true);
      await axios
        .get(ApiEndpoints.getAllSalesStage)
        .then((resp) => {
          setRawDealInfo(resp.data.filter((x) => x.stageName === "deal"));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    }
    if (isCurrentModuleAdmin === false) {
      setIsLoading(true);
      await axios
        .get(`${ApiEndpoints.getDealByEmpId}/${user?.empId}`)
        .then((resp) => {
          setRawDealInfo(resp.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    }
  };
  //============================================================================

  useEffect(() => {
    getAllDealInfo();
  },[isCurrentModuleAdmin])

  const MappedDealInfo = useMemo(() => {
    return rawDealInfo?.map((x) => {
      return{
        salesId: x.sales.id,
        salesStageId: x.id,
        client: x.sales.client,
        salesRep: x.sales.salesRep,
        status: x.status.paramValue,
        createdOn: dayjs(x.createdOn).format("DD MMM YYYY, HH:mm"),
        stageName: x.stageName,
        notes: x.sales.remark ?? "-",
      }
    })
  },[rawDealInfo])

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BreadCrumbs />
        {isCurrentModuleAdmin && (
          <Link to={`/dealManagement/create`}>
            <PrimaryButton size={"small"}>Create New Deal</PrimaryButton>
          </Link>
        )}
      </div>

      <div style={{ marginTop: "1.5rem" }}>
        <GlobalTabs
          tabs={[
            {
              label: "Main Table",
              component: (
                <DealListing
                  MappedDealInfo={MappedDealInfo}
                  isLoading={isLoading}
                />
              ),
            },
            { label: "Pipeline", component: <></> },
            { label: "Forecast", component: <></> },
          ]}
        />
      </div>
    </div>
  );
}