import {
  Avatar,
  CircularProgress,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Header1, Header3 } from "../globalTypography/headers";
import GlobalSearchTextField from "../gloabalTextfields/searchTextField";
import { useApplication } from "../../redux/application/hook";

import { ApiEndpoints } from "../../configuration/apiEndpoints";
import axios from "axios";
import { ProfilePicture } from "../profilePicture";

export default function AssignSalesRepCard({ selectedSalesRep, setSelectedSalesRep, stageName }) {
  const { employeeList, roleList, moduleList } = useApplication();
  const [loading, setLoading] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [salesStageInfo, setSalesStageInfo] = useState();

  const columns = [
    { id: "name", label: "Name", filter: false },
    { id: "ongoing", label: `Ongoing ${stageName.toUpperCase()}(s)`, filter: false },
  ];

  const sorting = (a, b, ascending) => {
    if (a === null) {
      return 1;
    }
    if (b === null) {
      return -1;
    }
    if (a === b) {
      return 0;
    }
    if (ascending) {
      return a < b ? -1 : 1;
    }
    return a < b ? 1 : -1;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => sorting(a?.mapped?.[orderBy] || null, b?.mapped?.[orderBy] || null, false)
      : (a, b) => sorting(a?.mapped?.[orderBy] || null, b?.mapped?.[orderBy] || null, true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const status = useMemo(() => {
    var status = {};
    return (status = {
      success: stageName === "lead" ? "LeadStatus-Success" : "DealStatus-Success",
      fail: stageName === "deal" ? "LeadStatus-Fail" : "DealStatus-Fail",
    });
  }, [stageName]);

  const getAllSalesStageInfo = async () => {
    setLoading(true);
    await axios
      .get(ApiEndpoints.getAllSalesStage)
      .then((resp) => {
        setSalesStageInfo(
          resp.data.filter(
            (x) =>
              x.stageName === stageName &&
              (x.status.paramType !== status.success || x.status.paramValue !== status.fail)
          )
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllSalesStageInfo();
  }, []);

  const MappedSalesRep = useMemo(() => {
    var crmModule = moduleList?.find((x) => x.name === "CRM");
    var mappedRole = roleList?.filter((a) =>
      a.roleModules?.some((b) => b.moduleId === crmModule?.id)
    );
    var userIdwithCRMAccess = mappedRole?.map((x) =>
      x.userRoles?.map((y) => {
        return {
          userId: y.userId,
        };
      })
    );
    var userIdwithCRMAccessFlat = userIdwithCRMAccess?.flat();

    var mappedEmployeeWithCRMAccess = userIdwithCRMAccessFlat?.map((user) => {
      var match = employeeList?.find((emp) => emp.user.id === user.userId);
      var ongoingLead = salesStageInfo
        ?.filter(
          (x) =>
            x.stageName === stageName &&
            (x.status.paramType !== status.success || x.status.paramValue !== status.fail)
        )
        ?.filter((x) => x.sales.salesRep.id === match.id)?.length;
      return {
        ...match,
        mapped: {
          empId: match?.id,
          name: match?.displayName,
          ongoing: ongoingLead,
        },
      };
    });

    return mappedEmployeeWithCRMAccess;
  }, [roleList, moduleList, salesStageInfo, employeeList]);

  const compareSearch = (obj, val) => {
    val = val.toString().toLowerCase();
    const keys = Object.keys(obj?.mapped);
    return keys.some((key) => obj?.mapped?.[key]?.toString()?.toLowerCase()?.includes(val));
  };

  const filteredSalesReps = useMemo(() => {
    return MappedSalesRep?.filter((salesRep) => compareSearch(salesRep, searchVal))?.sort(
      getComparator(order, orderBy)
    );
  }, [MappedSalesRep, order, orderBy, searchVal]);

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (name) => {
    const nameParts = name?.split(" ");
    const initials = nameParts
      ?.map((part) => part[0])
      ?.slice(0, 2)
      ?.join("");

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  };

  return (
    <Paper
      sx={{
        flex: 2.5,
        p: "1.5rem",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Header1 sx={{ fontSize: "0.9375rem" }}>Assign Sales Representative</Header1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <ProfilePicture id={selectedSalesRep?.id} />

        <span>
          <Header1 sx={{ fontSize: "1rem" }}>
            {selectedSalesRep?.mapped?.name || "Sales Rep"}
          </Header1>
          <Header3 sx={{ fontSize: "0.8125rem" }}>
            {selectedSalesRep?.empEmployments?.[0]?.position || "Sales Rep"}
          </Header3>
        </span>
      </div>
      <GlobalSearchTextField
        onChange={(e) => setSearchVal(e.target.value)}
        searchValue={searchVal}
        handleClearSearch={() => setSearchVal("")}
        sx={{ width: "100%" }}
      />
      <TableContainer style={{ width: "100%", maxHeight: "40vh" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} sx={{ minWidth: "fit-content" }}>
                  {column.sortable !== false && (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}
                  {column.sortable === false && column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading === "salesReps" || filteredSalesReps?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: "center", padding: "18px" }}>
                  {loading === "salesReps" ? (
                    <CircularProgress size={"0.875rem"} />
                  ) : (
                    "No results found"
                  )}
                </TableCell>
              </TableRow>
            ) : (
              filteredSalesReps?.map((salesRep, index) => {
                return (
                  <TableRow
                    key={salesRep?.id}
                    hover
                    selected={selectedSalesRep?.id === salesRep?.id}
                    onClick={() => setSelectedSalesRep(salesRep)}
                    sx={{ cursor: "pointer" }}
                  >
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {column.id === "name" ? (
                          <div sx={{ display: "flex", alignItems: "center" }}>
                            <Radio
                              checked={selectedSalesRep?.id === salesRep?.id}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: "0.8rem",
                                },
                              }}
                            />
                            <span
                              style={{
                                flexShrink: 1,
                                flexGrow: 1,
                              }}
                            >
                              {salesRep?.mapped?.[column.id]}
                            </span>
                          </div>
                        ) : (
                          salesRep?.mapped?.[column.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
