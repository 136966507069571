import React, { useState, useEffect, useMemo } from "react";

import { ApiEndpoints } from "../../../configuration/apiEndpoints";
import axios from "axios";

import { Link } from "react-router-dom";

import { useSnackbar } from "../../../contexts/snackbarContext";
import { Header1 } from "../../../component/globalTypography/headers";
import PrimaryButton from "../../../component/buttons/primaryButton";
import GlobalTextField from "../../../component/gloabalTextfields/globalTextfield";
import GlobalSearchTextField from "../../../component/gloabalTextfields/searchTextField";
import ResetButton from "../../../component/buttons/tableButtons/resetButton";
import ConfirmDialog from "../../../component/confirmDialog/confirmDialog";

import CircularProgress from "@mui/material/CircularProgress";

import AddRoleDialog from "./addRoleDialog";

import { IconEdit,IconX  } from "@tabler/icons-react";

import { Paper, Autocomplete, Button, Fab, Typography, Pagination} from "@mui/material";


export default function RoleManagement(props) {
  const notification = useSnackbar();
  const {rawRole, isLoading, getAllRole} = props;

  const [openAddRoleDialog, setOpenAddRoleDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [searchVal, setSearchVal] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState("");

  const [page , setPage] = useState(1);

  //==========================Pagination Function============================
  const itemPerPage = 4;

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  //=========================================================================

  //==========================Dialog Function================================
  const handleCloseAddRoleDialog = () => {
    setOpenAddRoleDialog(false);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  }
  //=========================================================================

  //==========================Search Function================================
  const handleSearch = (value) => {
    setSearchVal(value);
  };

  const handleClearSearch = (value) => {
    setSearchVal("");
  };

  const handleFilterAppParamBySearchVal = (appParam) => {
    var lowerCaseSearchVal = searchVal.toLowerCase() ?? "";
    return (
      appParam.roleName?.toLowerCase().includes(lowerCaseSearchVal)
    );
  };
  //============================================================================

  //==========================API Function=====================================
  const deleteRole = async () => {
    setIsSubmitLoading(true);
    await axios
      .delete(`${ApiEndpoints.deleteRole}/${selectedRole?.roleId}`)
      .then((resp) => {
        notification("Successfully deleted role.", "success");
        getAllRole();
      })
      .catch((error) => {
        notification("Failed to delete role.", "error");
      })
      .finally(() => {
        setOpenConfirmDialog(false);
        setIsSubmitLoading(false);
      });
  };
  //============================================================================

  //==========================useMemo Function==================================
  const MappedRoleInfo = useMemo(() => {
    var mappedRoleInfo = [];
    if (rawRole) {
      mappedRoleInfo = rawRole.map((x) => {
        return {
          roleId: x.id,
          roleName: x.roleName,
          roleStatus: x.status,
          userRole: x.userRoles,
          numOfUser: x.userRoles.length,
        };
      });
    }
    return mappedRoleInfo;
  }, [rawRole]);

  const FilterApplicationParam = useMemo(() => {
    return MappedRoleInfo
      .filter((a) => selectedRoleName ? selectedRoleName === a.roleName : true)
      .filter((a) => handleFilterAppParamBySearchVal(a))
  }, [searchVal,MappedRoleInfo, selectedRoleName]);

  const roleNameFilter = useMemo(() => {
    return MappedRoleInfo
      ?.sort((a, b) => a.roleName.localeCompare(b.roleName))
      ?.map((z) => z.roleName)
      ?.filter((v, i, a) => a.indexOf(v) === i);
  }, [MappedRoleInfo])
  //============================================================================
  return (
    <div>
      <div style={{ display: "flex", marginTop: "30px" }}>
        <GlobalSearchTextField
          width={"30%"}
          onChange={(e) => setSearchVal(e.target.value)}
          handleSearch={handleSearch}
          searchValue={searchVal}
          handleClearSearch={handleClearSearch}
        />

        <Autocomplete
          size={"small"}
          sx={{ width: 200, ml: 1 }}
          loading={isLoading}
          options={isLoading ? [] : roleNameFilter}
          value={selectedRoleName}
          onChange={(e, v) => setSelectedRoleName(v)}
          renderInput={(params) => (
            <GlobalTextField
              label={"Role Name"}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <div style={{ marginLeft: "auto" }}>
          <PrimaryButton
            onClick={() => {
              setOpenAddRoleDialog(true);
            }}
          >
            Add Role
          </PrimaryButton>
        </div>
      </div>

      {FilterApplicationParam?.length > 0 ? (
        isLoading ? (
          <div
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {FilterApplicationParam?.slice(
              (page - 1) * itemPerPage,
              page * itemPerPage
            )?.map((x) => (
              <div style={{ marginTop: "25px" }}>
                <Paper
                  sx={{
                    display: "flex",
                    p: "25px",
                    border: "2px solid #E5E5E5",
                    boxShadow: "none",
                  }}
                >
                  <div>
                    <Typography fontWeight={"bold"}>{x.roleName}</Typography>
                    <Typography>
                      <span style={{ color: "#34C6F5" }}>
                        {x.numOfUser} personnel
                      </span>{" "}
                      have this role
                    </Typography>
                  </div>

                  <div style={{ marginLeft: "auto" }}>
                    <Link to={`/accessMangement/edit?roleId=${x.roleId}`}>
                      <Fab
                        color="primary"
                        aria-label="add"
                        sx={{
                          mr: 1,
                          width: 50,
                          height: 50,
                          borderRadius: 1,
                          color: "#FFFFFF",
                          backgroundColor: "#34C6F5",
                          "&:hover": {
                            backgroundColor: "#2795B9",
                          },
                          boxShadow: "none",
                        }}
                      >
                        <IconEdit />
                      </Fab>
                    </Link>
                    <Fab
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: 1,
                        color: "#ffffff",
                        backgroundColor: "#D13F58",
                        borderColor: "#FFC107",
                        "&:hover": {
                          backgroundColor: "#AC3448",
                          borderColor: "#FFC107",
                        },
                        "& .MuiButton-startIcon": {
                          color: "#ffffff",
                        },
                        boxShadow: "none",
                      }}
                      onClick={() => {
                        setSelectedRole(x);
                        setOpenConfirmDialog(true);
                      }}
                    >
                      <IconX />
                    </Fab>
                  </div>
                </Paper>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Pagination
                count={Math.ceil(FilterApplicationParam.length / itemPerPage)}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </>
        )
      ) : (
        <div style={{ marginTop: "25px" }}>
          <Paper
            sx={{
              width: "97%",
              display: "flex",
              justifyContent: "center",
              p: "25px",
              border: "2px solid #E5E5E5",
              boxShadow: "none",
            }}
          >
            <Header1>Currently do not have any role</Header1>
          </Paper>
        </div>
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        loading={isSubmitLoading}
        type={"error"}
        onSubmit={deleteRole}
        onClose={handleCloseConfirmationDialog}
        dialogTitle={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Delete Role Confirmation
          </span>
        }
        dialogContent={() => (
          <Typography>{`Are you confirm to delete role ${selectedRole?.roleName}`}</Typography>
        )}
      />

      <AddRoleDialog
        open={openAddRoleDialog}
        onClose={handleCloseAddRoleDialog}
        getAllRole={getAllRole}
        roleNameFilter={roleNameFilter}
      />
    </div>
  );
}
