import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  timelineContentClasses,
  TimelineDot,
  timelineDotClasses,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from "@mui/lab";
import React, { useMemo, useState } from "react";
import StringAvatar from "../../stringAvatar";
import htmlParse from "html-react-parser";
import { Chip, CircularProgress, Link } from "@mui/material";
import { useApplication } from "../../../redux/application/hook";
import dayjs from "dayjs";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";
import axios from "axios";
import { ProfilePicture } from "../../profilePicture";

const InteractionTimelineItem = ({ salesInteractions, salesInteraction, index }) => {
  const [loadingAtachment, setLoadingAttachment] = useState(false);

  function generateStatusColor(status) {
    // Convert status string to a unique number
    const hashCode = function (s) {
      return s?.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    };

    let hash = 0;
    for (let i = 0; i < status?.length; i++) {
      hash = (hash << 5) - hash + status?.charCodeAt(i);
    }
    // Normalize the hash to a value between 0 and 1
    const normalized = (hash >>> 0) / 0xffffffff;

    const hashCodeNumber = Math.abs(hashCode(status));
    const hue = hashCodeNumber % 360; // Use modulo to ensure hue is within 0-359
    const saturation = 70; // Saturation set to 70%
    const lightness = 60 + normalized * 10; // Lightness ranges from 60 to 70

    // Convert HSL to RGB for CSS color representation
    const rgbColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

    return rgbColor;
  }

  const handleViewAttachment = async (salesInteraction) => {
    setLoadingAttachment(true);
    await axios
      .get(`${ApiEndpoints.getAttachmentById}/${salesInteraction?.id}`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const fileName = salesInteraction?.attachment?.split("/").pop();
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoadingAttachment(false));
  };
  return (
    <TimelineItem key={salesInteraction?.id}>
      <TimelineOppositeContent color="textSecondary">
        {salesInteraction?.createdOnStr}
      </TimelineOppositeContent>
      <TimelineSeparator>
        {index !== 0 ? <TimelineConnector /> : <span style={{ flexGrow: 1 }}></span>}
        <TimelineDot />
        {index !== salesInteractions?.length - 1 ? (
          <TimelineConnector />
        ) : (
          <span style={{ flexGrow: 1 }}></span>
        )}
      </TimelineSeparator>
      <TimelineContent>
        <div
          style={{
            padding: "1.2rem 1.5rem",
            background: "white",
            borderRadius: 10,
            border: "1px #E5E5E5 solid",
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "0.5rem",
          }}
        >
          <span style={{ gridRows: "span 2" }}>
            <ProfilePicture id={salesInteraction?.interactedBy?.id} width="2rem" height="2rem" />
          </span>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              placeContent: "center",
            }}
          >
            <span style={{ alignSelf: "center" }}>
              {htmlParse(salesInteraction?.interactionTitle)}
            </span>
            <Chip
              size="small"
              label={salesInteraction?.stageStatus?.paramValue}
              sx={{
                marginLeft: "auto",
                alignSelf: "flex-start",
                borderRadius: "5px",
                backgroundColor: generateStatusColor(salesInteraction?.stageStatus?.paramValue),
                color: "#ffffff",
              }}
            />
          </div>
          <span style={{ gridColumnStart: "2", whiteSpace: "pre-line" }}>
            {salesInteraction?.remark}
          </span>
          {salesInteraction?.attachment && (
            <span
              style={{
                gridColumnStart: "2",
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Link
                underline="hover"
                color="#34C6F5"
                component={"button"}
                onClick={() => handleViewAttachment(salesInteraction)}
                disabled={loadingAtachment}
              >
                {salesInteraction?.attachment?.split("/").pop()}
              </Link>
              {loadingAtachment && <CircularProgress size={"0.8125rem"} />}
            </span>
          )}
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};

export default function SalesInteractionTimelines({ salesStage }) {
  const { employeeList, applicationParamList } = useApplication();

  const salesInteractions = useMemo(() => {
    return salesStage?.sales?.salesInteractions
      ?.map((salesInteraction) => {
        return {
          ...salesInteraction,
          interactedBy: employeeList?.find(
            (employee) => employee?.id === salesInteraction?.interactedById
          ),
          stageStatus: applicationParamList?.find(
            (param) => param?.id === salesInteraction?.stageStatusId
          ),
          createdOnStr: dayjs(salesInteraction?.createdOn).format("MMM DD, YYYY hh:mm A"),
        };
      })
      ?.sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn));
  }, [salesStage, employeeList, applicationParamList]);

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.15,
          margin: "auto",
          fontSize: "0.8125rem",
        },
        [`& .${timelineContentClasses.root}`]: {
          fontSize: "0.8125rem",
          padding: "0.8rem 1rem",
        },
        [`& .${timelineDotClasses.root}`]: {
          margin: 0,
          padding: "2px",
        },
        padding: 0,
        margin: 0,
      }}
      position="right"
    >
      {salesInteractions?.map((salesInteraction, index) => (
        <InteractionTimelineItem
          key={index}
          salesInteractions={salesInteractions}
          salesInteraction={salesInteraction}
          index={index}
        />
      ))}
    </Timeline>
  );
}
