import React from "react";

import { AppBar, Typography, IconButton, Tooltip, Badge } from "@mui/material";

import NotificationsIcon from "@mui/icons-material/Notifications";

import { useAuthentication } from "../../redux/authentication/hook";
import { ProfilePicture } from "../profilePicture";

export default function TopNav() {
  const { user } = useAuthentication();
  return (
    <AppBar position="static" sx={{ backgroundColor: "#ffffff", padding: "0.5rem 1rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <ProfilePicture id={user?.emp?.id} width="2.5rem" height="2.5rem" />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography fontWeight={"bold"} fontSize={"0.875rem"} color={"#202020"}>
              {user?.emp?.displayName}
            </Typography>

            <Typography fontSize={"0.75rem"} color={"#202020"}>
              {user?.emp?.empEmployments?.[0]?.position}
            </Typography>
          </div>
        </div>

        <div
          style={{
            marginLeft: "auto",
          }}
        >
          <Tooltip title="Notification">
            <IconButton>
              <Badge badgeContent={4} color="primary">
                <NotificationsIcon sx={{ color: "#999999" }} />
              </Badge>
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </AppBar>
  );
}
