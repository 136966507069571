import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { routes } from "../../configuration/routes";

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

function BreadCrumbs({ pageTitle, pathTitle }) {
  const buildBreadcrumbNameMap = (routes, parentPath = "") => {
    return routes.reduce((breadcrumbNameMap, route) => {
      const path = `${parentPath}${route.path ? "/" + route.path : ""}`.toLocaleLowerCase();

      if (route.title) {
        breadcrumbNameMap[path] = route.title;
      }

      if (route.children) {
        const childBreadcrumbNameMap = buildBreadcrumbNameMap(route.children, path);
        Object.assign(breadcrumbNameMap, childBreadcrumbNameMap);
      }

      return breadcrumbNameMap;
    }, {});
  };

  const breadcrumbNameMap = buildBreadcrumbNameMap(routes);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  return (
    <div>
      <Typography fontSize={"1.2rem"} fontWeight={"bold"}>
        {pageTitle ?? breadcrumbNameMap[location.pathname.toLocaleLowerCase()]}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: "0.875rem", fontWeight: "medium" }}>
        <LinkRouter underline="hover" color="#34C6F5" to="/">
          Home
        </LinkRouter>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`.toLocaleLowerCase();

          return last ? (
            <div key={to} style={{ color: "black" }}>
              {pathTitle ?? breadcrumbNameMap[to]}
            </div>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {breadcrumbNameMap[to]}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}

export default BreadCrumbs;
