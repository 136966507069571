import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";
import { ApiEndpoints } from "../../configuration/apiEndpoints";

import BreadCrumbs from "../../component/breadCrumbs/breadCrumbs";
import PrimaryButton from "../../component/buttons/primaryButton";

import GlobalSearchTextField from "../../component/gloabalTextfields/searchTextField";
import GlobalTextField from "../../component/gloabalTextfields/globalTextfield";

import { visuallyHidden } from "@mui/utils";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import {
  Paper,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Chip,
  Box,
  Link,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import { TruncateString } from "../../component/truncateString/truncateString";
import { useAuthentication } from "../../redux/authentication/hook";

export default function LeadManagement() {
  const navigate = useNavigate();
  const { isCurrentModuleAdmin, user } = useAuthentication();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("client.clientName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [rawLeadInfo, setRawLeadInfo] = useState();

  const headCells = [
    // {
    //   id: "leadId",
    //   disablePadding: false,
    //   label: "Lead ID",
    //   center: false,
    // },
    {
      id: "client.clientName",
      disablePadding: false,
      label: "Client Name",
      center: false,
    },
    {
      id: "client.phoneNum",
      disablePadding: false,
      label: "Phone",
      center: false,
    },
    {
      id: "client.email",
      disablePadding: false,
      label: "Email",
      center: false,
    },
    {
      id: "createdOn",
      disablePadding: false,
      label: "Created On",
      center: false,
    },
    {
      id: "salesRep.displayName",
      disablePadding: false,
      label: "Assigned To",
      center: false,
    },
    {
      id: "status",
      disablePadding: false,
      label: "Status",
      center: false,
    },
    {
      id: "notes",
      disablePadding: false,
      label: "Notes",
      center: false,
    },
  ];

  function generateStatusColor(status) {
    // Convert status string to a unique number
    const hashCode = function (s) {
      return s.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    };

    let hash = 0;
    for (let i = 0; i < status?.length; i++) {
      hash = (hash << 5) - hash + status?.charCodeAt(i);
    }
    // Normalize the hash to a value between 0 and 1
    const normalized = (hash >>> 0) / 0xffffffff;

    const hashCodeNumber = Math.abs(hashCode(status));
    const hue = hashCodeNumber % 360; // Use modulo to ensure hue is within 0-359
    const saturation = 70; // Saturation set to 70%
    const lightness = 60 + normalized * 10; // Lightness ranges from 60 to 70

    // Convert HSL to RGB for CSS color representation
    const rgbColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

    return rgbColor;
  }

  //==========================API Function=====================================
  const getAllLead = async () => {
    if (isCurrentModuleAdmin === true) {
      setIsLoading(true);
      await axios
        .get(ApiEndpoints.getAllSalesStage)
        .then((resp) => {
          setRawLeadInfo(resp.data.filter((x) => x.stageName === "lead"));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    }
    if (isCurrentModuleAdmin === false) {
      setIsLoading(true);
      await axios
        .get(`${ApiEndpoints.getLeadByEmpId}/${user?.empId}`)
        .then((resp) => {
          setRawLeadInfo(resp.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    }
  };
  //============================================================================

  //==========================Search Function================================
  const handleFilterLeadBySearchVal = (data) => {
    var lowerCaseSearchVal = searchVal.toLowerCase() ?? "";
    return (
      data.client.clientName?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.client.phoneNum?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.client.email?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.createdOn?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.salesRep?.displayName?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.status?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.notes?.toLowerCase().includes(lowerCaseSearchVal)
    );
  };
  //============================================================================

  //==========================Table Sort Function=============================
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((value, key) => value?.[key], obj);
  };

  const getComparator = (order, orderBy) => {
    return (a, b) => {
      const aValue = getNestedValue(a, orderBy);
      const bValue = getNestedValue(b, orderBy);

      if (typeof aValue === "string" && typeof bValue === "string") {
        return order === "desc"
          ? bValue.localeCompare(aValue, undefined, {
              numeric: true,
              sensitivity: "base",
            })
          : aValue.localeCompare(bValue, undefined, {
              numeric: true,
              sensitivity: "base",
            });
      }

      // Handle other types if needed (e.g., numbers, dates)
      if (aValue < bValue) return order === "desc" ? 1 : -1;
      if (aValue > bValue) return order === "desc" ? -1 : 1;
      return 0;
    };
  };

  const handleRequestRoleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //==========================================================================

  //==========================Pagination Function================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //=============================================================================

  useEffect(() => {
    getAllLead();
  }, [isCurrentModuleAdmin]);

  const MappedLeadInfo = useMemo(() => {
    return rawLeadInfo?.map((x) => {
      return {
        salesId: x.sales.id,
        salesStageId: x.id,
        client: x.sales.client,
        salesRep: x.sales.salesRep,
        status: x.status.paramValue,
        createdOn: dayjs(x.createdOn).format("DD MMM YYYY, HH:mm"),
        stageName: x.stageName,
        notes: x.sales.remark ?? "-",
      };
    });
  }, [rawLeadInfo]);

  const statusFilter = useMemo(() => {
    return MappedLeadInfo?.sort((a, b) => a.status.localeCompare(b.status))
      ?.map((x) => x.status)
      ?.filter((v, i, a) => a.indexOf(v) === i);
  }, [MappedLeadInfo]);

  const filterData = useMemo(() => {
    return MappedLeadInfo?.filter((a) =>
      selectedStatus ? selectedStatus === a.status : true
    )
      ?.filter((a) => handleFilterLeadBySearchVal(a))
      ?.sort(getComparator(order, orderBy));
  }, [MappedLeadInfo, order, orderBy, selectedStatus, searchVal]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BreadCrumbs />

        {isCurrentModuleAdmin && (
          <PrimaryButton
            size={"small"}
            onClick={() => navigate("/leadManagement/create")}
          >
            Create New Lead
          </PrimaryButton>
        )}
      </div>

      <div style={{ marginTop: "30px" }}>
        <Paper sx={{ p: 2, border: "1px solid #E5E5E5", boxShadow: "none" }}>
          <div style={{ display: "flex" }}>
            <GlobalSearchTextField
              sx={{ width: "500px" }}
              onChange={(e) => setSearchVal(e.target.value)}
              searchValue={searchVal}
              handleClearSearch={() => setSearchVal("")}
            />
            <Autocomplete
              size="small"
              sx={{ ml: 1, width: 200 }}
              loading={isLoading}
              options={isLoading ? [] : statusFilter}
              onChange={(e, v) => setSelectedStatus(v)}
              renderInput={(params) => (
                <GlobalTextField
                  label={"Status"}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>

          <div style={{ marginTop: "31px" }}>
            <TableContainer sx={{ maxHeight: "55vh" }}>
              <Table stickyHeader aria-labelledby="tableTitle">
                <TableHead sx={{ zIndex: 1 }}>
                  <TableRow>
                    <TableCell></TableCell>
                    {headCells.map((item) => (
                      <TableCell align="left">
                        <TableSortLabel
                          active={orderBy === item?.id}
                          direction={order === "asc" ? "asc" : "desc"}
                          onClick={() => handleRequestRoleSort(item.id)}
                        >
                          {item.label}
                          {orderBy === item?.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <CircularProgress size={"2rem"} />
                      </TableCell>
                    </TableRow>
                  ) : filterData?.length > 0 ? (
                    filterData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((x, index) => {
                        return (
                          <TableRow
                            key={x.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            hover
                          >
                            <TableCell></TableCell>
                            <TableCell>
                              <Link
                                underline="hover"
                                color="#34C6F5"
                                to={`/leadManagement/leadInfo?id=${x?.salesStageId}`}
                                component={RouterLink}
                              >
                                {x.client?.clientName}
                              </Link>
                            </TableCell>
                            <TableCell>{x.client.phoneNum}</TableCell>
                            <TableCell>{x.client.email}</TableCell>
                            <TableCell>{x.createdOn}</TableCell>
                            <TableCell>{x.salesRep.displayName}</TableCell>
                            <TableCell>
                              <Chip
                                label={x.status}
                                size="medium"
                                sx={{
                                  backgroundColor: generateStatusColor(
                                    x.status
                                  ),
                                  color: "#ffffff",
                                }}
                              />
                            </TableCell>
                            <TableCell>{TruncateString(x.notes, 20)}</TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No Record Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}
