import { useSelector, useDispatch } from "react-redux";
import {
  setApplicationParamList,
  setCompanyList,
  setDeparmentList,
  setEmployeeList,
  setLoading,
  setModuleList,
  SetRoleList
} from "./actions";
import axios from "axios";
import { ApiEndpoints } from "../../configuration/apiEndpoints";
import { useSnackbar } from "../../contexts/snackbarContext";

export const useApplication = () => {
  const dispatch = useDispatch();
  const {
    employeeList,
    loading,
    locationData,
    nationalities,
    applicationParamList,
    companyList,
    departmentList,
    moduleList,
    roleList,
  } = useSelector((state) => state.application);
  const openSnackbar = useSnackbar();

  const updateEmployeeList = async () => {
    dispatch(setLoading({ employeeList: true }));
    await axios
      .get(ApiEndpoints.getEmployee)
      .then((res) => {
        dispatch(setEmployeeList(res.data));
      })
      .catch((error) => {
        openSnackbar(error, "error");
      })
      .finally(() => {
        dispatch(setLoading({ employeeList: false }));
      });
  };

  const updateApplicationParamList = async () => {
    // dispatch(setLoading("applicationParamList"));
    await axios
      .get(ApiEndpoints.getApplicationParam)
      .then((res) => {
        dispatch(setApplicationParamList(res.data));
      })
      .catch((error) => {
        openSnackbar(error, "error");
      })
      .finally(() => {
        // dispatch(setLoading(null));
      });
  };

  const updateCompanyList = async () => {
    // dispatch(setLoading("companyList"));
    await axios
      .get(ApiEndpoints.getCompany)
      .then((res) => {
        dispatch(setCompanyList(res.data));
      })
      .catch((error) => {
        openSnackbar(error, "error");
      })
      .finally(() => {
        // dispatch(setLoading(null));
      });
  };

  const updateDepartmentList = async () => {
    // dispatch(setLoading("departmentList"));
    await axios
      .get(ApiEndpoints.getDepartment)
      .then((res) => {
        dispatch(setDeparmentList(res.data));
      })
      .catch((error) => {
        openSnackbar(error, "error");
      })
      .finally(() => {
        // dispatch(setLoading(null));
      });
  };

  const updateModuleList = async () => {
    // dispatch(setLoading("departmentList"));
    await axios
      .get(ApiEndpoints.getModule)
      .then((res) => {
        dispatch(setModuleList(res.data));
      })
      .catch((error) => {
        openSnackbar(error, "error");
      })
      .finally(() => {
        // dispatch(setLoading(null));
      });
  };

  const updateRoleList = async () => {
    // dispatch(setLoading("departmentList"));
    await axios
      .get(ApiEndpoints.getRole)
      .then((res) => {
        dispatch(SetRoleList(res.data));
      })
      .catch((error) => {
        openSnackbar(error, "error");
      })
      .finally(() => {
        // dispatch(setLoading(null));
      });
  };

  return {
    employeeList,
    loading,
    locationData,
    nationalities,
    applicationParamList,
    companyList,
    departmentList,
    moduleList,
    roleList,
    updateEmployeeList,
    updateApplicationParamList,
    updateCompanyList,
    updateDepartmentList,
    updateModuleList,
    updateRoleList
  };
};
