import React, { useMemo } from "react";

import { Paper, Grid, Button, Alert } from "@mui/material";
import { Header1 } from "../../../../component/globalTypography/headers";
import FieldRender from "../fieldRender";

export default function HierarchyDetails(props) {
  const { fields, fieldOptions, errors, control, defaultFields, setFields } = props;

  const hierarchyGroups = useMemo(() => {
    const hierarchyGroups = {};
    fields.forEach((field) => {
      const hierarchyNumber = field.id?.split("-")[1];
      if (!hierarchyGroups[hierarchyNumber]) {
        hierarchyGroups[hierarchyNumber] = [];
      }
      hierarchyGroups[hierarchyNumber].push(field);
    });
    return hierarchyGroups;
  }, [fields]);

  const handleAddHierarchy = (key) => {
    setFields((curr) => {
      return [
        ...curr,
        ...defaultFields?.map((x) => {
          const splitId = x.id?.split("-");
          const keyNo = parseInt(key) + 1;
          const keyName = splitId[0];
          const obj = {
            ...x,
            id: `${keyName}-${keyNo}`,
          };
          return obj;
        }),
      ];
    });
  };

  const handleRemoveHierarchy = (key) => {
    setFields((curr) => {
      return [
        ...curr?.filter((x) => (x.section === "hierarchy" ? x.id.split("-")[1] !== key : true)),
      ];
    });
  };

  return (
    <Paper
      sx={{ p: "1.5rem", border: "1px solid", borderColor: "#eeeeee", borderRadius: "10px" }}
      elevation={0}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header1 fontSize={"0.9375rem"}>Hierarchy Details</Header1>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          {Object.keys(hierarchyGroups)?.length === 0 ? (
            <Grid item xs={12} container justifyContent={"space-between"} flexGrow={1}>
              <Alert
                severity="info"
                sx={{ width: "100%" }}
                action={
                  <Button
                    variant="text"
                    sx={{
                      fontSize: "0.75rem",
                      color: "#34C6F5",
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                    onClick={() => handleAddHierarchy(1)}
                  >
                    Add Hierarchy
                  </Button>
                }
              >
                No hierarchy found.
              </Alert>
            </Grid>
          ) : (
            Object.keys(hierarchyGroups)?.map((key, index) => {
              return (
                <React.Fragment key={index}>
                  {hierarchyGroups[key]?.map((fieldItem) => {
                    return (
                      <Grid item xs={fieldItem?.gridSize} key={fieldItem.id}>
                        <FieldRender
                          fieldItem={fieldItem}
                          fieldOptions={fieldOptions}
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                    );
                  })}

                  <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.75rem",
                        color: "#FF7043",
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                      onClick={() => handleRemoveHierarchy(key)}
                    >
                      Remove Hierarchy
                    </Button>
                    {Object.keys(hierarchyGroups)?.length - 1 === index && (
                      <Button
                        variant="text"
                        sx={{
                          fontSize: "0.75rem",
                          color: "#34C6F5",
                          textDecoration: "underline",
                          textTransform: "none",
                        }}
                        onClick={() => handleAddHierarchy(key)}
                      >
                        Add Hierarchy
                      </Button>
                    )}
                  </Grid>
                </React.Fragment>
              );
            })
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
