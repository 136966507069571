import axios from "axios";
import React, { useEffect, useState } from "react";
import { ApiEndpoints } from "../configuration/apiEndpoints";
import { Avatar } from "@mui/material";
import defaultUserImage from "../assets/images/defaultUser.jpg";

export const ProfilePicture = ({ id, width, height }) => {
  const [profilePicture, setProfilePicture] = useState(null);
  useEffect(() => {
    getProfilePicture(id);
  }, [id]);

  const getProfilePicture = async (id) => {
    if (id) {
      await axios
        .get(`${ApiEndpoints.getProfilePicture}/${id}`, {
          responseType: "blob",
        })
        .then((res) => {
          const blobUrl = window.URL.createObjectURL(res.data);
          setProfilePicture(blobUrl);
        })
        .catch((error) => {
          setProfilePicture(null);
        });
    }
  };

  return (
    <Avatar
      alt={id}
      sx={{ width: width, height: height }}
      src={profilePicture || defaultUserImage}
    />
  );
};
