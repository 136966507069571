import React from "react";
import forbidden403 from "../../assets/images/forbidden403.png";
import { Typography } from "@mui/material";
import PrimaryButton from "../buttons/primaryButton";
import { useNavigate } from "react-router-dom";

export default function ForbiddenPage() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        textAlign: "center",
        backgroundColor: "white",
        borderRadius: "8px",
      }}
    >
      <img src={forbidden403} alt="Forbidden 403" style={{ maxWidth: "40rem", width: "100%" }} />
      <Typography fontSize={"2.5rem"} color={"#1F246B"} fontWeight={"bold"}>
        Forbidden
      </Typography>
      <Typography fontSize={"1rem"} color={"#1F246B"} maxWidth={"30rem"}>
        The page you're trying to access has restricted access. Please refer to your system
        administrator.
      </Typography>
      <PrimaryButton style={{ marginTop: "2rem" }} onClick={() => navigate("/")}>
        Go To Home Page
      </PrimaryButton>
    </div>
  );
}
