import React, { useState, useEffect, useMemo } from "react";

import PrimaryButton from "../../../component/buttons/primaryButton";
import SecondaryButton from "../../../component/buttons/secondaryButton";
import { Header1, Header2 } from "../../../component/globalTypography/headers";
import GlobalTextField from "../../../component/gloabalTextfields/globalTextfield";

import CircularProgress from "@mui/material/CircularProgress";

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Box,
  Tooltip
} from "@mui/material";

export default function RoleModuleComponent(props) {
  const {
    roleName,
    setRoleName,
    module,
    setModule,
    errorCheck,
    isLoading,
    handleOnChangeCheckBox,
    validateRoleName,
  } = props;

  const headCells = [
    {
      id: "moduleName",
      disablePadding: false,
      label: "HR Modules",
      width: "500px",
      center: false,
    },
    {
      id: "grantAccess",
      disablePadding: false,
      label: "Grant Access",
      width: "200px",
      center: false,
    },
    {
      id: "isAdmin",
      disablePadding: false,
      label: "Is Admin",
      width: "200px",
      center: false,
    },
  ];

  const groupByModuleType = useMemo(() => {
    if (module) {
      const moduleTypes = module.map((item) => item.moduleType);
      return [...new Set(moduleTypes)];
    }
  }, [module]);

  return (
    <div>
      <Header1 sx={{ fontSize: "1rem", mb: 1 }}>Authorized User</Header1>
      <Header2>Role Name</Header2>
      <GlobalTextField
        sx={{ width: "100%" }}
        value={roleName}
        size={"small"}
        error={!!validateRoleName(roleName)}
        helperText={validateRoleName(roleName)}
        onChange={(e, v) => setRoleName(e.target.value)}
      />

      <Header2 sx={{ mt: 1 }}>Permission</Header2>
      {groupByModuleType
        ?.sort((a, b) => a.localeCompare(b))
        .map((x) => (
          <TableContainer
            sx={{
              maxHeight: "55vh",
              border: "1px solid #ddd", // Add a border here
              borderRadius: "4px",
              mb: 2,
            }}
            component={Box}
          >
            <Table stickyHeader aria-labelledby="tableTitle">
              <TableHead sx={{ zIndex: 1 }}>
                <TableRow>
                  <TableCell></TableCell>
                  {headCells.map((item) => (
                    <TableCell align="left" width={item.width}>
                      {item.id === "moduleName" ? `${x} Modules` : item.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress size={"2rem"} sx={{ pt: 20, pb: 20 }} />
                    </TableCell>
                  </TableRow>
                ) : module?.length > 0 ? (
                  module
                    .filter((a) => a.moduleType === x)
                    .map((m) => {
                      return (
                        <TableRow
                          key={x.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          hover
                        >
                          <TableCell></TableCell>
                          {/* <TableCell align="center">
                            {m.isAdminOnly ? (
                              <VerifiedUserIcon sx={{ color: "#14A44D" }} />
                            ) : (
                              ""
                            )}
                          </TableCell> */}
                          <TableCell>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {m.isAdminOnly === 1 && (
                                <Tooltip title="Admin Module">
                                  <VerifiedUserIcon sx={{ color: "#14A44D"}} />
                                </Tooltip>
                              )}
                              {m.name}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <Checkbox
                              checked={m.isGrantAccess}
                              onChange={(e, v) => {
                                handleOnChangeCheckBox(
                                  "isGrantAccess",
                                  e.target.checked,
                                  m
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Tooltip
                              title={
                                m.isAdminOnly
                                  ? "User cannot disable admin rights for the admin modules."
                                  : ""
                              }
                            >
                              <span>
                                <Checkbox
                                  checked={m.isAdmin}
                                  onChange={(e, v) => {
                                    handleOnChangeCheckBox(
                                      "isAdmin",
                                      e.target.checked,
                                      m
                                    );
                                  }}
                                  disabled={m.isAdminOnly}
                                />
                              </span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ))}{" "}
    </div>
  );
}
