import React, { useEffect, useMemo, useState } from "react";

import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";

import { visuallyHidden } from "@mui/utils";

import CircularProgress from "@mui/material/CircularProgress";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Radio,
  Checkbox,
  Typography,
} from "@mui/material";

import SecondaryButton from "../../buttons/secondaryButton";
import PrimaryButton from "../../buttons/primaryButton";
import GlobalSearchTextField from "../../gloabalTextfields/searchTextField";
import { useApplication } from "../../../redux/application/hook";
import { useAuthentication } from "../../../redux/authentication/hook";
import { useSnackbar } from "../../../contexts/snackbarContext";
import { Header3, Header5 } from "../../globalTypography/headers";
import ConfirmDialog from "../../confirmDialog/confirmDialog";
import { map } from "zod";

export default function ReassigneSalesRepDialog(props) {
  const { open, onClose, salesStage, getSalesStage } = props;
  const { employeeList, roleList, moduleList } = useApplication();
  const { user } = useAuthentication();
  const openSnackbar = useSnackbar();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [salesStageInfo, setSalesStageInfo] = useState();
  const [selectedSalesRep, setSelectedSalesRep] = useState(null);
  const [confirmCheckBox, setConfirmCheckBox] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [searchVal, setSearchVal] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const headCells = [
    {
      id: "action",
      disablePadding: false,
      label: "Action",
      center: false,
      sortLabel: false,
    },
    {
      id: "name",
      disablePadding: false,
      label: "Name",
      center: false,
      sortLabel: true,
    },
    {
      id: "ongoing",
      disablePadding: false,
      label: `Ongoing ${salesStage?.stageName?.toUpperCase()}(s)`,
      center: false,
      sortLabel: true,
    },
  ];

  const handleOpenConfirmDialog = () => {
    if (selectedSalesRep === null) {
      openSnackbar("Please select a sales representative", "error");
    } else {
      setOpenConfirmDialog(true);
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  //==========================Table Sort Function=============================
  const getComparator = (order, orderBy) => {
    if (orderBy === "ongoing") {
      return order === "desc"
        ? (a, b) => b[orderBy] - a[orderBy]
        : (a, b) => a[orderBy] - b[orderBy];
    } else {
      return order === "desc"
        ? (a, b) =>
            b[orderBy]?.localeCompare(a[orderBy], undefined, {
              numeric: true,
              sensitivity: "base",
            })
        : (a, b) =>
            a[orderBy]?.localeCompare(b[orderBy], undefined, {
              numeric: true,
              sensitivity: "base",
            });
    }
  };

  const handleRequestRoleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //==========================================================================

  //====================API====================
  const getAllSalesStage = async () => {
    setIsLoading(true);
    await axios
      .get(ApiEndpoints.getAllSalesStage)
      .then((resp) => {
        setSalesStageInfo(resp.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const reassignSalesRep = async () => {
    setIsSubmitLoading(true);
    await axios
      .post(`${ApiEndpoints.reassignSalesRep}/${salesStage?.sales?.id}`, {
        salesRepId: selectedSalesRep?.empId,
        by: user?.username,
      })
      .then((res) => {
        openSnackbar("Successfully re-assigned sales representative", "success");
        getAllSalesStage();
        getSalesStage();
        onClose();
        setSelectedSalesRep(null);
      })
      .catch((error) => {
        openSnackbar("Failed re-assign sales representative", "error");
      })
      .finally(() => {
        setIsSubmitLoading(false);
        handleCloseConfirmDialog();
      });
  };
  //===========================================

  const handleFilterAppParamBySearchVal = (emp) => {
    var lowerCaseSearchVal = searchVal.toLowerCase() ?? "";
    return (
      emp.name?.toLowerCase().includes(lowerCaseSearchVal) ||
      emp.ongoing?.toString().includes(lowerCaseSearchVal)
    );
  };

  //====================useMemo====================
  const status = useMemo(() => {
    var status = {
      success: salesStage?.stageName === "lead" ? "LeadStatus-Success" : "DealStatus-Success",
      fail: salesStage?.stageName === "lead" ? "LeadStatus-Fail" : "DealStatus-Fail",
    };

    return status;
  }, [salesStage]);

  const MappedSalesRep = useMemo(() => {
    var crmModule = moduleList?.find((x) => x.name === "CRM");
    var mappedRole = roleList?.filter((a) =>
      a.roleModules?.some((b) => b.moduleId === crmModule?.id)
    );
    var userIdwithCRMAccess = mappedRole?.map((x) =>
      x.userRoles?.map((y) => {
        return {
          userId: y.userId,
        };
      })
    );
    var userIdwithCRMAccessFlat = userIdwithCRMAccess?.flat();

    var mappedEmployeeWithCRMAccess = userIdwithCRMAccessFlat?.map((user) => {
      var match = employeeList?.find((emp) => emp?.user?.id === user?.userId);
      var ongoingLead = salesStageInfo
        ?.filter(
          (x) =>
            x.stageName === salesStage?.stageName &&
            (x.status.paramType !== status.success || x.status.paramValue !== status.fail)
        )
        ?.filter((x) => x.sales?.salesRep?.id === match?.id)?.length;
      return {
        empId: match?.id,
        name: match?.displayName,
        ongoing: ongoingLead,
      };
    });

    return mappedEmployeeWithCRMAccess;
  }, [roleList, moduleList, salesStageInfo, employeeList, status]);

  const FilterSalesPersonInfo = useMemo(() => {
    return MappedSalesRep?.filter((a) => handleFilterAppParamBySearchVal(a))?.sort(
      getComparator(order, orderBy)
    );
  }, [MappedSalesRep, order, orderBy, searchVal]);
  //===============================================

  useEffect(() => {
    getAllSalesStage();
  }, []);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Re-assign Sales Representative</DialogTitle>
        <DialogContent>
          <GlobalSearchTextField
            sx={{ width: "100%" }}
            onChange={(e) => setSearchVal(e.target.value)}
            searchValue={searchVal}
            handleClearSearch={() => setSearchVal("")}
          />
          <TableContainer sx={{ maxHeight: "35vh", mt: 2 }}>
            <Table stickyHeader aria-labelledby="tableTitle">
              <TableHead sx={{ zIndex: 1 }}>
                <TableRow>
                  <TableCell></TableCell>
                  {headCells.map((item, index) => (
                    <TableCell
                      key={index}
                      align="left"
                      width={item.id === "ongoingLead" && "100px"}
                    >
                      {item.sortLabel !== false && (
                        <TableSortLabel
                          active={orderBy === item?.id}
                          direction={order === "asc" ? "asc" : "desc"}
                          onClick={() => handleRequestRoleSort(item.id)}
                        >
                          {item.label}
                          {orderBy === item?.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc" ? "sorted descending" : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      )}
                      {item.sortLabel === false && item.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress size={"2rem"} sx={{ pt: 20, pb: 20 }} />
                    </TableCell>
                  </TableRow>
                ) : FilterSalesPersonInfo?.length > 0 ? (
                  FilterSalesPersonInfo?.map((x, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        hover
                      >
                        <TableCell></TableCell>
                        <TableCell>
                          <Radio
                            checked={selectedSalesRep?.empId === x.empId}
                            onChange={(e) => setSelectedSalesRep(x)}
                            value={x}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 15,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>{x.name}</TableCell>
                        <TableCell>{x.ongoing}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
            <Checkbox onChange={(e, v) => setConfirmCheckBox(v)} />{" "}
            <Header3 sx={{ fontSize: "0.8rem", pl: 1 }}>
              I confirm the reassignment of this individual.
            </Header3>
          </div>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={() => onClose()}>Cancel</SecondaryButton>
          <PrimaryButton disabled={!confirmCheckBox} onClick={() => handleOpenConfirmDialog()}>
            Confirm
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openConfirmDialog}
        loading={isSubmitLoading}
        type={"info"}
        onSubmit={reassignSalesRep}
        onClose={handleCloseConfirmDialog}
        dialogTitle={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Re-assign Sales Reprentative Confirmation
          </span>
        }
        dialogContent={() => (
          <Typography>Are you confirm to re-assign sales reprensentative?</Typography>
        )}
      />
    </>
  );
}
