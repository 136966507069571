import React, { useEffect, useState, useMemo } from "react";

import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";

import dayjs from "dayjs";

import PrimaryButton from "../../../component/buttons/primaryButton";
import SecondaryButton from "../../../component/buttons/secondaryButton";
import GlobalSearchTextField from "../../../component/gloabalTextfields/searchTextField";

import { useApplication } from "../../../redux/application/hook";
import { useSnackbar } from "../../../contexts/snackbarContext";

import CircularProgress from "@mui/material/CircularProgress";
import { visuallyHidden } from "@mui/utils";

import ConfirmDialog from "../../../component/confirmDialog/confirmDialog";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Box,
  Checkbox,
  Typography
} from "@mui/material";

export default function AddUserRoleDialog(props) {
  var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
  dayjs.extend(isSameOrAfter);

  const notification = useSnackbar();

  const { open, onClose, mappedRoleInfo, getRoleById } = props;
  const { employeeList, updateRoleList } = useApplication();

  const [searchVal, setSearchVal] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("displayName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  
  const headCells = [
    {
      id: "action",
      disablePadding: false,
      label: "Action",
      center: false,
    },
    {
      id: "displayName",
      disablePadding: false,
      label: "Name",
      center: false,
    },
    {
      id: "department",
      disablePadding: false,
      label: "Department",
      center: false,
    },
  ];

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  }

  //==========================Api Function===================================
  const handleAddUserToRoleByList = async () => {
    setIsSubmitLoading(true);
    await axios
      .post(ApiEndpoints.AddUserToRoleByList, mappedUserRoleSubmission)
      .then((resp) => {
        notification("Successfully added users.", "success");
        onClose();
        getRoleById();
        updateRoleList();
      })
      .catch((err) => {
        notification("Failed to add users", "error");
      })
      .finally(() => {
        handleCloseConfirmDialog();
        setIsSubmitLoading(false);
      });
  }
  //=========================================================================

  //==========================Search Function================================
  const handleFilterUserListBySearchVal = (userList) => {
    var lowerCaseSearchVal = searchVal.toLowerCase() ?? "";
    return (
      userList.displayName?.toLowerCase().includes(lowerCaseSearchVal) ||
      userList.department?.toLowerCase().includes(lowerCaseSearchVal)
    );
  };
  //==========================================================================

  //==========================Table Sort Function=============================
  const getComparator = (order, orderBy) => {
    if (orderBy === "no") {
      return order === "desc"
        ? (a, b) => b[orderBy] - a[orderBy]
        : (a, b) => a[orderBy] - b[orderBy];
    } else {
      return order === "desc"
        ? (a, b) =>
            b[orderBy]?.localeCompare(a[orderBy], undefined, {
              numeric: true,
              sensitivity: "base",
            })
        : (a, b) =>
            a[orderBy]?.localeCompare(b[orderBy], undefined, {
              numeric: true,
              sensitivity: "base",
            });
    }
  };

  const handleRequestRoleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //==========================================================================

  //==========================Pagination Function================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //=============================================================================

  const handleCheckBoxChange = (value, user) => {
    if (value === true) {
      setSelectedUsers((prev) => [...prev, user]);
    } else {
      setSelectedUsers((prev) => prev.filter((u) => u.empId !== user.empId));
    }
  };

  const mappedUserListing = useMemo(() => {
    return employeeList?.map((x) => {
      return {
        empId: x.id,
        displayName: x.displayName,
        department: x.empEmployments?.find(
          (x) =>
            (dayjs(x.startDate).isSameOrAfter(new Date()) &&
              dayjs(x.endDate).isSameOrAfter(new Date())) ||
            x.endDate === null
        )?.dept?.deptName,
        userId: x.user.id,
      };
    });
  }, [employeeList]);

  const filterUserListing = useMemo(() => {
    var userWithRole = mappedRoleInfo?.userRoles?.map((x) => x.empId);
    var filtered = mappedUserListing?.filter(
      (x) => !userWithRole?.includes(x.empId)
    )?.filter((x)=> handleFilterUserListBySearchVal(x))
    .sort(getComparator(order, orderBy));

    return filtered;
  }, [mappedRoleInfo, mappedUserListing, searchVal, order, orderBy]);

  const mappedUserRoleSubmission = useMemo(() => {
    return {
      userRole: selectedUsers.map((x) => {
        return {
          userId: x.userId,
          roleId: mappedRoleInfo.roleId,
          by: "SYS"
        };
      })
    };
  }, [selectedUsers,mappedRoleInfo]);
  
  useEffect(() => {
    if (!open) {
      setSelectedUsers([]);
    }
  },[open])

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent sx={{ p: 3, overflowX: "hidden" }}>
          <div>
            <GlobalSearchTextField
              sx={{ width: "100%" }}
              onChange={(e) => setSearchVal(e.target.value)}
              searchValue={searchVal}
              handleClearSearch={() => setSearchVal("")}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <TableContainer sx={{ maxHeight: "55vh" }}>
              <Table stickyHeader aria-labelledby="tableTitle">
                <TableHead sx={{ zIndex: 1 }}>
                  <TableRow>
                    <TableCell></TableCell>
                    {headCells.map((item) => (
                      <TableCell align="left">
                        {item.id !== "action" ? (
                          <TableSortLabel
                            active={orderBy === item?.id}
                            direction={order === "asc" ? "asc" : "desc"}
                            onClick={() => handleRequestRoleSort(item.id)}
                          >
                            {item.label}
                            {orderBy === item?.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          item.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <CircularProgress
                          size={"2rem"}
                          sx={{ pt: 20, pb: 20 }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : filterUserListing?.length > 0 ? (
                    filterUserListing
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((x, index) => {
                        var actualIndex =
                          (page + 1 - 1) * rowsPerPage + (index + 1);
                        return (
                          <TableRow
                            key={x.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            hover
                          >
                            <TableCell></TableCell>
                            <TableCell>
                              <Tooltip title="Add User">
                                <Checkbox
                                  onChange={(e, v) => {
                                    handleCheckBoxChange(v, x);
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell>{x.displayName}</TableCell>
                            <TableCell>{x.department}</TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No Record Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filterUserListing?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <SecondaryButton size={"small"} onClick={() => onClose()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size={"small"} onClick={() => setOpenConfirmDialog(true)}>Submit</PrimaryButton>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={openConfirmDialog}
        loading={isSubmitLoading}
        type={"info"}
        onSubmit={handleAddUserToRoleByList}
        onClose={handleCloseConfirmDialog}
        dialogTitle={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Add User Confirmation
          </span>
        }
        dialogContent={() => (
          <>
            <Typography>Are you confirm to Add user:</Typography>
            {selectedUsers.map((x, index) => (
              <Typography>{`${index+1}: ${x.displayName}`}</Typography>
            ))}
          </>
        )}
      />
    </div>
  );
}
