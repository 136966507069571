import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import { z } from "zod";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { IconId } from "@tabler/icons-react";

import PersonalDetails from "./personalDetails/personalDetails";
import ContactDetails from "./personalDetails/contactDetails";

import EmployeeInitialDetails from "./employeeDetails/employmentDetails";
import HierarchyDetails from "./employeeDetails/hierarchyDetails";
import { Paper, Grid, Typography, CardActionArea, CardContent } from "@mui/material";
import BreadCrumbs from "../../../component/breadCrumbs/breadCrumbs";
import { useApplication } from "../../../redux/application/hook";
import useZodForm from "../../../hooks/useZodForm";
import { useWatch } from "react-hook-form";
import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";
import { useSnackbar } from "../../../contexts/snackbarContext";
import PrimaryButton from "../../../component/buttons/primaryButton";
import { useAuthentication } from "../../../redux/authentication/hook";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ConfirmDialog from "../../../component/confirmDialog/confirmDialog";

export default function EmployeeForm() {
  const menuCards = [
    {
      id: "personal",
      label: "Personal Detail",
      description: "Individual's Personal Information",
      icon: <AccountCircleOutlinedIcon />,
    },
    {
      id: "employment",
      label: "Employment Detail",
      description: "Individual's Employment Information",
      icon: <IconId />,
    },
  ];

  const defaultFields = [
    {
      id: "profilePicture",
      label: "Profile Picture",
      type: "image",
      optional: true,
      section: "personal",
      model: "employee",
    },
    {
      id: "fullName",
      label: "Full Name",
      type: "string",
      optional: false,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "displayName",
      label: "Display Name",
      type: "string",
      optional: false,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "dateOfBirth",
      label: "Date Of Birth",
      type: "date",
      optional: false,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "gender",
      label: "Gender",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "nationality",
      label: "Nationality",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "nric",
      label: "NRIC",
      type: "string",
      optional: true,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "race",
      label: "Race",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "religion",
      label: "Religion",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "maritalStatus",
      label: "Marital Status",
      type: "selection",
      optional: false,
      gridSize: 12,
      section: "personal",
      model: "employee",
    },
    {
      id: "passportNum",
      label: "Passport No.",
      type: "string",
      optional: true,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "passportExpDate",
      label: "Passport Expiry Date",
      type: "date",
      optional: true,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "immigrationNum",
      label: "Immigration No.",
      type: "string",
      optional: true,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "immigrationExpDate",
      label: "Immigration Expiry Date",
      type: "date",
      optional: true,
      gridSize: 6,
      section: "personal",
      model: "employee",
    },
    {
      id: "addressLine1",
      label: "Address Line 1",
      type: "string",
      optional: false,
      gridSize: 12,
      section: "contact",
      model: "employee",
    },
    {
      id: "addressLine2",
      label: "Address Line 2",
      type: "string",
      optional: true,
      gridSize: 12,
      section: "contact",
      model: "employee",
    },
    {
      id: "country",
      label: "Country",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "contact",
      model: "employee",
    },
    {
      id: "state",
      label: "State",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "contact",
      model: "employee",
    },
    {
      id: "city",
      label: "City",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "contact",
      model: "employee",
    },
    {
      id: "postcode",
      label: "Postcode",
      type: "string",
      optional: false,
      gridSize: 6,
      section: "contact",
      model: "employee",
    },
    {
      id: "personalEmail",
      label: "Personal Email",
      type: "email",
      optional: false,
      gridSize: 6,
      section: "contact",
      model: "employee",
    },
    {
      id: "workEmail",
      label: "Working Email",
      type: "email",
      optional: false,
      gridSize: 6,
      section: "contact",
      model: "employee",
    },
    {
      id: "personalPhoneNum",
      label: "Personal Phone",
      type: "phone",
      optional: false,
      gridSize: 6,
      section: "contact",
      model: "employee",
    },
    {
      id: "homePhoneNum",
      label: "Home Phone",
      type: "phone",
      optional: true,
      gridSize: 6,
      section: "contact",
      model: "employee",
    },
    {
      id: "empEmployType-1",
      label: "Employment Type",
      type: "selection",
      optional: false,
      gridSize: 12,
      section: "employment",
      model: "empEmployments",
    },
    {
      id: "startDate-1",
      label: "Start Date",
      type: "date",
      optional: false,
      gridSize: 6,
      section: "employment",
      model: "empEmployments",
    },
    {
      id: "endDate-1",
      label: "End Date",
      type: "date",
      optional: true,
      gridSize: 6,
      section: "employment",
      model: "empEmployments",
    },
    {
      id: "position-1",
      label: "Position Title",
      type: "string",
      optional: false,
      gridSize: 6,
      section: "employment",
      model: "empEmployments",
    },
    {
      id: "salary-1",
      label: "Salary",
      type: "number",
      optional: true,
      gridSize: 6,
      section: "employment",
      model: "empEmployments",
    },
    {
      id: "compId-1",
      label: "Company",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "employment",
      model: "empEmployments",
    },
    {
      id: "deptId-1",
      label: "Department",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "employment",
      model: "empEmployments",
    },
    {
      id: "superiorId-1",
      label: "Superior",
      type: "selection",
      optional: false,
      gridSize: 4,
      section: "hierarchy",
      model: "empHierarchyEmps",
    },
    {
      id: "sequence-1",
      label: "Sequence",
      type: "number",
      optional: false,
      gridSize: 4,
      section: "hierarchy",
      model: "empHierarchyEmps",
    },
    {
      id: "effectiveOn-1",
      label: "Effective On",
      type: "date",
      optional: false,
      gridSize: 4,
      section: "hierarchy",
      model: "empHierarchyEmps",
    },
  ];

  const {
    employeeList,
    locationData,
    nationalities,
    applicationParamList,
    companyList,
    departmentList,
    updateEmployeeList,
  } = useApplication();
  const { user } = useAuthentication();
  const openSnackbar = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [selectedMenuCard, setSelectedMenuCard] = useState("personal");
  const [fields, setFields] = useState(defaultFields);
  const [employee, setEmployee] = useState({});
  const [loading, setLoading] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const createZodSchema = (field) => {
    let schema;
    switch (field.type) {
      case "string" || "phone":
        schema = z.string();
        break;
      case "email":
        schema = z.string().email();
        break;
      case "number":
        schema = z.number().refine((num) => {
          const rounded = Math.round(num * 100) / 100;
          return num === rounded;
        }, "Please enter a number with up to two decimal places.");
        break;
      case "date":
        schema = z.custom((value) => value instanceof dayjs, "Invalid Date");
        break;
      case "selection":
        schema = z.string();
        break;
      case "image":
        schema = z.instanceof(File).refine(
          (file) => {
            const allowedTypes = ["image/jpg", "image/jpeg", "image/png"];
            return allowedTypes.includes(file.type);
          },
          {
            message: "Must be an image (JPG, JPEG, PNG)",
          }
        );
        break;
      default:
        schema = z.string();
    }

    if (field.optional) {
      schema = schema.optional().or(z.literal("")).or(z.null());
    }

    return schema;
  };

  const { handleSubmit, control, errors, setSchema, setValue, formState } = useZodForm();

  const allFieldValue = useWatch({ control });

  useEffect(
    () =>
      setSchema(
        z
          .object(
            fields.reduce((acc, field) => {
              acc[field.id] = createZodSchema(field);
              return acc;
            }, {})
          )
          .refine(
            (data) => {
              if (data.nationality === "Malaysian" && !data.nric) {
                return false;
              }
              return true;
            },
            {
              message: "Required",
              path: ["nric"],
            }
          )
          .refine(
            (data) => {
              if (data.passportNum && !data.passportExpDate) {
                return false;
              }
              return true;
            },
            {
              message: "Required",
              path: ["passportExpDate"],
            }
          )
          .refine(
            (data) => {
              if (data.passportExpDate && !data.passportNum) {
                return false;
              }
              return true;
            },
            {
              message: "Required",
              path: ["passportNum"],
            }
          )
          .refine(
            (data) => {
              if (data.immigrationNum && !data.immigrationExpDate) {
                return false;
              }
              return true;
            },
            {
              message: "Required",
              path: ["immigrationExpDate"],
            }
          )
          .refine(
            (data) => {
              if (data.immigrationExpDate && !data.immigrationNum) {
                return false;
              }
              return true;
            },
            {
              message: "Required",
              path: ["immigrationNum"],
            }
          )
          .superRefine((data, ctx) => {
            const superiorIdKeys = Object.keys(data).filter((key) => key.startsWith("superiorId"));
            const effectiveOnKeys = Object.keys(data).filter((key) =>
              key.startsWith("effectiveOn")
            );

            let combinedEntries = {};
            superiorIdKeys.forEach((key, index) => {
              if (data[key] && data[effectiveOnKeys[index]]) {
                const superiorIdValue = data[key] ?? "";
                const effectiveOnValue = data[effectiveOnKeys[index]]
                  ? dayjs(data[effectiveOnKeys[index]]).format("DD/MM/YYYY")
                  : "";

                if (superiorIdValue && effectiveOnValue) {
                  const combinedKey = `${superiorIdValue}-${effectiveOnValue}`;
                  if (combinedEntries[combinedKey]) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: `Duplicate superior found on ${effectiveOnValue}`,
                      path: [key, effectiveOnKeys[index]],
                    });
                  } else {
                    combinedEntries[combinedKey] = true;
                  }
                }
              }
            });
          })
          .superRefine((data, ctx) => {
            const sequenceKeys = Object.keys(data).filter((key) => key.startsWith("sequence"));
            const effectiveOnKeys = Object.keys(data).filter((key) =>
              key.startsWith("effectiveOn")
            );

            let combinedEntries = {};
            sequenceKeys.forEach((key, index) => {
              if (data[key] && data[effectiveOnKeys[index]]) {
                const sequenceValue = data[key] ?? "";
                const effectiveOnValue = data[effectiveOnKeys[index]]
                  ? dayjs(data[effectiveOnKeys[index]]).format("DD/MM/YYYY")
                  : "";

                if (sequenceValue && effectiveOnValue) {
                  const combinedKey = `${sequenceValue}-${effectiveOnValue}`;
                  if (combinedEntries[combinedKey]) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: `Duplicated sequence ${sequenceValue} on ${effectiveOnValue}`,
                      path: [key, effectiveOnKeys[index]],
                    });
                  } else {
                    combinedEntries[combinedKey] = true;
                  }
                }
              }
            });
          })
      ),
    [fields, allFieldValue]
  );

  const country = useWatch({ control, name: "country" });
  const state = useWatch({ control, name: "state" });
  const city = useWatch({ control, name: "city" });

  const countries = useMemo(() => {
    return locationData
      ?.filter(
        (c) =>
          (state ? c.states.some((s) => s.name === state) : true) &&
          (city ? c.states.some((s) => s.cities.some((ct) => ct.name === city)) : true)
      )
      .map((country) => {
        return { label: country.name, value: country.name };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
  }, [locationData, state, city]);

  const states = useMemo(() => {
    return locationData
      .filter((c) => (country ? c.name === country : true))
      .flatMap((country) => country.states)
      .filter((s) => (city ? s.cities.some((ct) => ct.name === city) : true))
      .map((state) => {
        return { label: state.name, value: state.name };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
  }, [locationData, country, city]);

  const cities = useMemo(() => {
    return locationData
      .filter((c) => (country ? c.name === country : true))
      .flatMap((country) => country.states)
      .filter((s) => (state ? s.name === state : true))
      .flatMap((state) => state.cities)
      .map((city) => {
        return { label: city.name, value: city.name };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
  }, [locationData, country, state]);

  const compIdValues = useMemo(() => {
    return Object.keys(allFieldValue)
      .filter((key) => key.startsWith("compId"))
      .reduce((obj, key) => {
        obj[key] = allFieldValue[key];
        return obj;
      }, {});
  }, [allFieldValue]);

  const deptIdValues = useMemo(() => {
    return Object.keys(allFieldValue)
      .filter((key) => key.startsWith("deptId"))
      .reduce((obj, key) => {
        obj[key] = allFieldValue[key];
        return obj;
      }, {});
  }, [allFieldValue]);

  const companyOptions = useMemo(() => {
    const companyOptions = fields
      ?.filter((field) => field?.id?.includes("compId"))
      ?.reduce((acc, field) => {
        var key = field?.id?.split("-")?.[1];
        var deptIdValue = deptIdValues?.[`deptId-${key}`];
        acc[field?.id] = companyList
          ?.filter((c) => (deptIdValue ? c?.departments?.some((d) => d?.id === deptIdValue) : true))
          ?.map((c) => {
            return {
              label: c?.compName,
              value: c?.id,
            };
          })
          ?.sort((a, b) => a.label.localeCompare(b.label));
        return acc;
      }, {});
    return companyOptions;
  }, [fields, companyList, deptIdValues]);

  const departmentOptions = useMemo(() => {
    const departmentOptions = fields
      ?.filter((field) => field?.id?.includes("deptId"))
      ?.reduce((acc, field) => {
        var key = field?.id?.split("-")?.[1];
        var compIdValue = compIdValues?.[`compId-${key}`];
        acc[field?.id] = departmentList
          ?.filter((d) => (compIdValue ? d?.compId === compIdValue : true))
          ?.map((d) => {
            return {
              label: d?.deptName,
              value: d?.id,
            };
          })
          ?.sort((a, b) => a.label.localeCompare(b.label));
        return acc;
      }, {});

    return departmentOptions;
  }, [fields, departmentList, compIdValues]);

  const employmentTypeOptions = useMemo(
    () =>
      fields
        ?.filter((field) => field?.id?.includes("empEmployType"))
        ?.reduce((acc, field) => {
          acc[field?.id] = applicationParamList
            ?.filter((ap) => ap?.paramType === "EmploymentType")
            ?.map((ap) => {
              return { label: ap?.paramValue, value: ap?.paramValue };
            })
            ?.sort((a, b) => a.label.localeCompare(b.label));
          return acc;
        }, {}),
    [fields, applicationParamList]
  );

  const employees = useMemo(() => {
    return employeeList
      ?.filter((e) => e?.status === 1 && (e?.id ? e?.id !== id : true))
      ?.map((e) => {
        return { label: e?.displayName, value: e?.id };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
  }, [employeeList]);

  const superiorOptions = useMemo(
    () =>
      fields
        ?.filter((field) => field?.id?.includes("superiorId"))
        ?.reduce((acc, field) => {
          acc[field?.id] = employees;
          return acc;
        }, {}),
    [fields, employees]
  );

  const fieldOptions = useMemo(() => {
    return {
      nationality: [...nationalities]
        ?.map((n) => {
          return { label: n, value: n };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      gender: applicationParamList
        ?.filter((ap) => ap?.paramType === "Gender")
        ?.map((ap) => {
          return { label: ap?.paramValue, value: ap?.paramValue };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      race: applicationParamList
        ?.filter((ap) => ap?.paramType === "Race")
        ?.map((ap) => {
          return { label: ap?.paramValue, value: ap?.paramValue };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      religion: applicationParamList
        ?.filter((ap) => ap?.paramType === "Religion")
        ?.map((ap) => {
          return { label: ap?.paramValue, value: ap?.paramValue };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label)),
      maritalStatus: applicationParamList
        ?.filter((ap) => ap?.paramType === "MaritalStatus")
        ?.map((ap) => {
          return { label: ap?.paramValue, value: ap?.paramValue };
        }),
      country: countries,
      state: states,
      city: cities,
      ...employmentTypeOptions,
      ...companyOptions,
      ...departmentOptions,
      ...superiorOptions,
    };
  }, [
    nationalities,
    applicationParamList,
    countries,
    states,
    cities,
    employmentTypeOptions,
    companyOptions,
    departmentOptions,
    employees,
    fields,
  ]);

  const formValueMapping = (fieldData) => {
    let value;
    switch (fieldData.type) {
      case "date":
        value = fieldData?.value?.format("YYYY-MM-DD");
        break;
      default:
        value = fieldData?.value;
    }
    return value;
  };

  const formDataMapping = (data) => {
    const output = {};
    const listSectionItems = {};

    Object.entries(data).forEach(([key, item]) => {
      const model = item.model;
      const isListSection = ["empEmployments", "empHierarchyEmps"].includes(model);

      if (isListSection) {
        listSectionItems[model] = listSectionItems[model] || [];
        const index = parseInt(key.split("-")[1], 10);
        listSectionItems[model].push({
          index,
          key: key.split("-")[0],
          value: formValueMapping(item),
        });
      } else {
        output[model] = output[model] || {};
        output[model][key] = formValueMapping(item);
      }
    });

    Object.entries(listSectionItems).forEach(([model, items]) => {
      items.sort((a, b) => a.index - b.index);
      output[model] = items
        .reduce((acc, { index, key, value }) => {
          const existingItem = acc.find((item) => item.index === index);
          if (existingItem) {
            existingItem[key] = value;
          } else {
            acc.push({ index, [key]: value });
          }
          return acc;
        }, [])
        .map((item) => {
          const { index, ...rest } = item;
          return rest;
        });
    });

    return output;
  };

  const convertToFormData = (data) => {
    const formData = new FormData();

    const appendFormData = (obj, parentKey) => {
      if (Array.isArray(obj)) {
        obj.forEach((value, index) => {
          const key = `${parentKey}[${index}]`;
          appendFormData(value, key);
        });
      } else if (
        obj &&
        typeof obj === "object" &&
        !(obj instanceof Date) &&
        !(obj instanceof File)
      ) {
        Object.keys(obj).forEach((key) => {
          appendFormData(obj[key], parentKey ? `${parentKey}.${key}` : key);
        });
      } else if (obj !== null && obj !== undefined) {
        const value = obj instanceof Date ? obj.toISOString() : obj;
        formData.append(parentKey, value);
      }
    };

    appendFormData(data);
    return formData;
  };

  const onFormSubmit = async (data) => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmSubmit = async () => {
    const mapFormData = formDataMapping(
      fields?.reduce((acc, field) => {
        const fieldData = {
          id: field.id,
          type: field.type,
          model: field.model,
          value: allFieldValue[field?.id],
        };
        acc[field.id] = fieldData;
        return acc;
      }, {})
    );
    if (employee?.id) await updateEmployee(mapFormData);
    else await createEmployee(mapFormData);
  };

  const createEmployee = async (mapFormData) => {
    const formData = convertToFormData({
      ...mapFormData,
      by: user.username,
    });
    setLoading("submit");
    await axios
      .post(ApiEndpoints.createEmployee, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        updateEmployeeList();
        navigate(`/employeeManagement`);
        openSnackbar("Successfully created employee.", "success");
      })
      .catch((error) => {
        console.error(error);
        openSnackbar("Failed to create employee", "error");
      })
      .finally(() => {
        setLoading("");
        setOpenConfirmDialog(false);
      });
  };

  const updateEmployee = async (mapFormData) => {
    const { profilePicture, ...rest } = mapFormData;
    const formData = convertToFormData({
      ...rest,
      id: employee?.id,
      by: user.username,
    });
    setLoading("submit");
    await axios
      .post(`${ApiEndpoints.updateEmployee}/${employee?.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        updateEmployeeList();
        navigate(`/employeeManagement`);
        openSnackbar("Successfully updated employee.", "success");
      })
      .catch((error) => {
        console.error(error);
        openSnackbar("Failed to update employee", "error");
      })
      .finally(() => {
        setLoading("");
        setOpenConfirmDialog(false);
      });
  };

  const getEmployeeById = async (id) => {
    setLoading("getEmployee");
    await axios
      .get(`${ApiEndpoints.getEmployee}/${id}`)
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((error) => {
        console.error(error);
        navigate(`/employeeManagement`);
        openSnackbar("Failed to get employee", "error");
      })
      .finally(() => {
        setLoading("");
      });
  };

  const handleMenuCardClick = (card) => {
    setSelectedMenuCard(card);
  };

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const currentPathName = pathnames?.[pathnames?.length - 1];
    if (currentPathName === "edit" && !id) {
      navigate("/employeeManagement");
    }
    if (id) {
      getEmployeeById(id);
    }
  }, [id, location.pathname]);

  const setFieldValue = (field, key, value) => {
    if (field.type === "date") {
      setValue(key, value ? dayjs(value) : undefined);
    } else setValue(key, value);
  };

  useEffect(() => {
    if (employee?.id) {
      const { empEmployments, empHierarchyEmps, ...empRest } = employee;
      defaultFields
        .filter((field) => field.model === "employee")
        .forEach((field) => {
          setFieldValue(field, field.id, empRest[field.id]);
        });

      var employmentFields = empEmployments
        ?.sort((a, b) => (dayjs(b?.startDate).isAfter(dayjs(a?.startDate)) ? 1 : -1))
        ?.reduce((acc, employment, index) => {
          var fields = defaultFields
            .filter((field) => field.model === "empEmployments")
            .map((field) => {
              var keyName = field.id.split("-")[0];
              var key = `${keyName}-${index + 1}`;
              var value = keyName === "compId" ? employment?.dept?.compId : employment[keyName];
              setFieldValue(field, key, value);
              return {
                ...field,
                id: key,
              };
            });
          return [...acc, ...fields];
        }, []);

      var hierarchyEmpFields = empHierarchyEmps
        ?.sort((a, b) => {
          if (dayjs(a?.effectiveOn).isSame(b?.effectiveOn)) {
            return a?.sequence - b?.sequence;
          }
          return dayjs(b?.effectiveOn).isAfter(dayjs(a?.effectiveOn)) ? 1 : -1;
        })
        ?.reduce((acc, hierarchyEmp, index) => {
          var fields = defaultFields
            .filter((field) => field.model === "empHierarchyEmps")
            .map((field) => {
              var keyName = field.id.split("-")[0];
              var key = `${keyName}-${index + 1}`;
              var value = hierarchyEmp[keyName];
              if (field.type === "date") setValue(key, dayjs(value));
              else setValue(key, value);
              return {
                ...field,
                id: key,
              };
            });
          return [...acc, ...fields];
        }, []);

      setFields((curr) => {
        return [
          ...curr.filter((field) => !["empEmployments", "empHierarchyEmps"].includes(field.model)),
          ...employmentFields,
          ...hierarchyEmpFields,
        ];
      });
      if (employee?.profilePic) getProfilePictureById();
    }
  }, [employee]);

  const getProfilePictureById = async () => {
    await axios
      .get(`${ApiEndpoints.getProfilePicture}/${id}`, {
        responseType: "blob",
      })
      .then((res) => {
        if (res.data.size > 0) {
          const profilePicture = new File([res.data], "profilePicture.png", { type: "image/png" });
          setValue("profilePicture", profilePicture);
        } else setValue("profilePicture", undefined);
      })
      .catch((error) => {
        setValue("profilePicture", undefined);
        console.error(error);
      });
  };

  const errorSection = useMemo(() => {
    const errorFields = Object.keys(errors)?.reduce((acc, key) => {
      var field = fields?.find((field) => field?.id === key);
      return [...acc, { ...field, error: errors[key] }];
    }, []);

    return {
      personal: errorFields?.filter((ef) => ["personal", "contact"].includes(ef?.section)),
      employment: errorFields?.filter((ef) => ["employment", "hierarchy"].includes(ef?.section)),
    };
  }, [errors, fields]);

  if ((id && loading === null) || loading === "getEmployee") return "Loading...";
  else
    return (
      <div>
        <BreadCrumbs />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={3}>
            <Paper
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.10)",
                padding: "1.5rem 1.125rem",
              }}
              elevation={0}
            >
              <div style={{ marginLeft: "1rem", marginBottom: "1rem" }}>
                <Typography fontSize={"0.9375rem"}>Employee Profile</Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                {menuCards.map((menuCard) => (
                  <CardActionArea
                    key={menuCard.id}
                    onClick={() => handleMenuCardClick(menuCard.id)}
                    sx={{
                      borderRadius: "10px",
                      backgroundColor: selectedMenuCard === menuCard.id ? "#E1F7FE" : "#FAFAFA",
                      color: selectedMenuCard === menuCard.id ? "#34C6F5" : "#5D5D5D",
                    }}
                  >
                    <CardContent>
                      <div style={{ display: "flex", gap: "0.5rem" }}>
                        {menuCard.icon}
                        <div>
                          <Typography fontSize={"0.875rem"}>{menuCard.label}</Typography>
                          <Typography color={"#999999"} fontSize={"0.625rem"} fontWeight={400}>
                            {menuCard.description}
                          </Typography>
                          {errorSection[menuCard.id]?.length > 0 && (
                            <Typography color={"red"} fontSize={"0.625rem"} fontWeight={400}>
                              Field error detected on this section
                            </Typography>
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </CardActionArea>
                ))}
              </div>
            </Paper>
          </Grid>

          <Grid item xs={9}>
            <form
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              onSubmit={handleSubmit(onFormSubmit)}
            >
              {selectedMenuCard === "personal" && (
                <React.Fragment>
                  <PersonalDetails
                    fields={fields.filter((field) => field.section === "personal")}
                    fieldOptions={fieldOptions}
                    errors={errors}
                    control={control}
                    employee={employee}
                    isEdit={Boolean(employee?.id)}
                    getProfilePictureById={getProfilePictureById}
                  />
                  <ContactDetails
                    fields={fields.filter((field) => field.section === "contact")}
                    fieldOptions={fieldOptions}
                    errors={errors}
                    control={control}
                  />
                  {/* <EmergencyContact /> */}
                </React.Fragment>
              )}

              {selectedMenuCard === "employment" && (
                <React.Fragment>
                  <EmployeeInitialDetails
                    fields={fields.filter((field) => field.section === "employment")}
                    fieldOptions={fieldOptions}
                    errors={errors}
                    control={control}
                    defaultFields={defaultFields.filter((field) => field.section === "employment")}
                    setFields={setFields}
                  />
                  <HierarchyDetails
                    fields={fields.filter((field) => field.section === "hierarchy")}
                    fieldOptions={fieldOptions}
                    errors={errors}
                    control={control}
                    defaultFields={defaultFields.filter((field) => field.section === "hierarchy")}
                    setFields={setFields}
                  />
                </React.Fragment>
              )}

              <ConfirmDialog
                open={openConfirmDialog}
                type={"info"}
                onSubmit={handleConfirmSubmit}
                onClose={() => setOpenConfirmDialog(false)}
                dialogTitle={employee?.id ? "Update Employee" : "Create Employee"}
                dialogContent={() =>
                  `Are u sure to ${employee?.id ? "update" : "create"} this employee profile?`
                }
                loading={loading === "submit"}
              />

              <PrimaryButton
                type="submit"
                variant="contained"
                color="primary"
                loading={loading === "submit"}
              >
                {employee?.id ? "Update" : "Create"}
              </PrimaryButton>
            </form>
          </Grid>
        </Grid>
      </div>
    );
}
