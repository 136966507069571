import {
  SET_USER,
  SET_IS_AUTHENTICATED,
  SET_MODULE_ACCESS,
  SET_IS_CURRENT_MODULE_ADMIN,
} from "./types";

const initialState = {
  user: null,
  isAuthenticated: null,
  moduleAccess: [],
  isCurrentModuleAdmin: null,
};

export default function AuthenticationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_MODULE_ACCESS:
      return {
        ...state,
        moduleAccess: action.payload,
      };
    case SET_IS_CURRENT_MODULE_ADMIN:
      return {
        ...state,
        isCurrentModuleAdmin: action.payload,
      };
    default:
      return state;
  }
}
