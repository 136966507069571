//local
// export const ApiUrl = "https://localhost:44334/api";
export const ApiUrl = "https://testing.sappot.my/biz-royalty-api/api";

export const ApiEndpoints = {
  login: "auth/login",
  validateToken: "auth/validateToken",
  refreshToken: "auth/refreshToken",

  getEmployee: "employee",
  getProfilePicture: "employee/profilePicture",
  createEmployee: "employee/create",
  updateEmployee: "employee/update",
  updateEmployeeStatus: "employee/updateStatus",
  updateProfilePicture: "employee/updateProfilePicture",
  deleteProfilePicture: "employee/deleteProfilePicture",
  deleteEmployee: "employee/delete",

  getApplicationParam: "applicationParam",
  createApplicationParam: "applicationParam/create",
  updateApplicationParam: "applicationParam/update",
  updateApplicationParamStatus: "applicationParam/updateStatus",
  deleteApplicationParam: "applicationParam/delete",

  getSystemParam: "systemParam",

  getCompany: "company",

  getDepartment: "department",

  getModule: "module",

  //Role
  getRole: "role",
  createRoleByList: "role/createByList",
  updateRole: "role/updateByList",
  deleteRole: "role/delete",

  //User Role
  getUserRoleByUserId: "userRole/getByUserId",
  AddUserToRoleByList: "userRole/createByList",
  removeUserRoleByList: "userRole/deleteByList",

  updateUserById: "user/update",
  newUserSetupById: "user/newUserSetup",
  updateUserStatusById: "user/updateStatus",

  createLead: "sale/createLead",
  updateSalesInfo: "sale/updateSalesInfo",
  createDealWithNewClient: "sale/createDealWithNewClient",
  createDealWithExistingClient: "sale/createDealWithExistingClient",

  reassignSalesRep: "sale/reassignSalesRep",

  getAllSalesStage: "salesStage",
  getLeadByEmpId: "SalesStage/getLeadByEmpId",
  getDealByEmpId: "SalesStage/getDealByEmpId",
  getLeadById: "salesStage/lead",
  getDealById: "salesStage/deal",
  closeLead: "salesStage/closeLead",
  closeDeal: "salesStage/closeDeal",
  reopenLead: "salesStage/reopenLead",
  reopenDeal: "salesStage/reopenDeal",

  createSalesInteraction: "salesInteraction/create",
  getAttachmentById: "salesInteraction/getAttachmentById",

  //Client
  getAllClient: "client",
};
