import React from "react";
import notFound404 from "../../assets/images/notFound404.png";
import { Typography } from "@mui/material";
import PrimaryButton from "../buttons/primaryButton";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage({ to }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        textAlign: "center",
        backgroundColor: "white",
        borderRadius: "8px",
      }}
    >
      <img
        src={notFound404}
        alt="Not Found 403"
        style={{ maxWidth: "40rem", width: "100%", mixBlendMode: "multiply" }}
      />
      <Typography fontSize={"2.5rem"} color={"#1F246B"} fontWeight={"bold"}>
        Page Not Found
      </Typography>
      <Typography fontSize={"1rem"} color={"#1F246B"} maxWidth={"30rem"}>
        The page you're looking for may have been moved, deleted, or possibly never existed.
      </Typography>
      {to ? (
        <PrimaryButton style={{ marginTop: "2rem" }} onClick={() => navigate(to)}>
          Go Back
        </PrimaryButton>
      ) : (
        <PrimaryButton style={{ marginTop: "2rem" }} onClick={() => navigate("/")}>
          Go To Home Page
        </PrimaryButton>
      )}
    </div>
  );
}
