import { combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import AuthenticationReducer from "../authentication/reducer";
import ApplicationReducer from "../application/reducer";

const reducer = combineReducers({
  authentication: AuthenticationReducer,
  application: ApplicationReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
