import { Divider, Grid, Paper, TextField } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Header1, Header2 } from "../globalTypography/headers";
import { useApplication } from "../../redux/application/hook";
import { useWatch } from "react-hook-form";

export default function ClientInfoForm({
  setSchema,
  convertFieldsToSchema,
  renderField,
  control,
  errors,
}) {
  const { locationData } = useApplication();
  const defaultFields = [
    {
      id: "client_clientName",
      label: "Name",
      type: "string",
      optional: false,
      gridSize: 12,
      section: "Individual Information",
    },
    {
      id: "client_phoneNum",
      label: "Phone",
      type: "phone",
      optional: false,
      gridSize: 6,
      section: "Individual Information",
    },
    {
      id: "client_email",
      label: "Email",
      type: "email",
      optional: false,
      gridSize: 6,
      section: "Individual Information",
    },
    {
      id: "clientComp_compName",
      label: "Company Name",
      type: "string",
      optional: false,
      gridSize: 12,
      section: "Company Information",
    },
    {
      id: "client_positionTitle",
      label: "Position Title",
      type: "string",
      optional: false,
      gridSize: 6,
      section: "Company Information",
    },
    {
      id: "clientComp_industry",
      label: "Industry",
      type: "string",
      optional: false,
      gridSize: 6,
      section: "Company Information",
    },
    {
      id: "clientComp_addressLine1",
      label: "Address Line 1",
      type: "string",
      optional: false,
      gridSize: 6,
      section: "Company Information",
    },
    {
      id: "clientComp_addressLine2",
      label: "Address Line 2",
      type: "string",
      optional: true,
      gridSize: 6,
      section: "Company Information",
    },
    {
      id: "clientComp_country",
      label: "Country",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "Company Information",
    },
    {
      id: "clientComp_state",
      label: "State",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "Company Information",
    },
    {
      id: "clientComp_city",
      label: "City",
      type: "selection",
      optional: false,
      gridSize: 6,
      section: "Company Information",
    },
    {
      id: "clientComp_postcode",
      label: "Postcode",
      type: "string",
      optional: false,
      gridSize: 6,
      section: "Company Information",
    },
  ];

  const fieldSections = [...new Set(defaultFields.map((item) => item.section))];

  useEffect(() => {
    setSchema(convertFieldsToSchema(defaultFields));

    return () => {
      setSchema(null);
    };
  }, []);

  const country = useWatch({ control, name: "clientComp_country" });
  const state = useWatch({ control, name: "clientComp_state" });
  const city = useWatch({ control, name: "clientComp_city" });

  const countries = useMemo(() => {
    return locationData
      ?.filter(
        (c) =>
          (state ? c.states.some((s) => s.name === state) : true) &&
          (city ? c.states.some((s) => s.cities.some((ct) => ct.name === city)) : true)
      )
      .map((country) => {
        return { label: country.name, value: country.name };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
  }, [locationData, state, city]);

  const states = useMemo(() => {
    return locationData
      .filter((c) => (country ? c.name === country : true))
      .flatMap((country) => country.states)
      .filter((s) => (city ? s.cities.some((ct) => ct.name === city) : true))
      .map((state) => {
        return { label: state.name, value: state.name };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
  }, [locationData, country, city]);

  const cities = useMemo(() => {
    return locationData
      .filter((c) => (country ? c.name === country : true))
      .flatMap((country) => country.states)
      .filter((s) => (state ? s.name === state : true))
      .flatMap((state) => state.cities)
      .map((city) => {
        return { label: city.name, value: city.name };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
  }, [locationData, country, state]);

  const fieldOptions = useMemo(() => {
    return {
      clientComp_country: countries,
      clientComp_state: states,
      clientComp_city: cities,
    };
  }, [countries, states, cities]);

  return (
    <Paper
      sx={{
        p: "1.5rem",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
      }}
    >
      <Grid container spacing={1}>
        {fieldSections.map((section, index) => (
          <React.Fragment key={index}>
            {index === fieldSections.length - 1 && <Grid item xs={12}></Grid>}
            <Grid item xs={12}>
              <Header1 fontSize={"0.9375rem"}>{section}</Header1>
            </Grid>
            {defaultFields
              .filter((field) => field.section === section)
              .map((fieldItem) => {
                return (
                  <Grid key={fieldItem.id} item xs={12} sm={fieldItem.gridSize}>
                    {renderField(fieldItem, fieldOptions, control, errors)}
                  </Grid>
                );
              })}
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
}
