import {
  SET_EMPLOYEE_LIST,
  SET_LOADING,
  SET_APPLICATION_PARAM_LIST,
  SET_COMPANY_LIST,
  SET_DEPARTMENT_LIST,
  SET_MODULE_LIST,
  SET_ROLE_LIST,
} from "./types";

export const setEmployeeList = (payload) => ({
  type: SET_EMPLOYEE_LIST,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setApplicationParamList = (payload) => ({
  type: SET_APPLICATION_PARAM_LIST,
  payload,
});

export const setCompanyList = (payload) => ({
  type: SET_COMPANY_LIST,
  payload,
});

export const setDeparmentList = (payload) => ({
  type: SET_DEPARTMENT_LIST,
  payload,
});

export const setModuleList = (payload) => ({
  type: SET_MODULE_LIST,
  payload,
});

export const SetRoleList = (payload) => ({
  type: SET_ROLE_LIST,
  payload,
});

