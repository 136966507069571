import React, { useEffect, useMemo, useState } from "react";
import { Header1 } from "../../globalTypography/headers";
import { Autocomplete, TextField } from "@mui/material";
import PrimaryButton from "../../buttons/primaryButton";
import useZodForm from "../../../hooks/useZodForm";
import { z } from "zod";
import { Controller } from "react-hook-form";
import { useApplication } from "../../../redux/application/hook";
import { MuiTelInput } from "mui-tel-input";
import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";
import { useAuthentication } from "../../../redux/authentication/hook";
import { useSnackbar } from "../../../contexts/snackbarContext";

export default function SalesDetails({ sales, actionable }) {
  const { user } = useAuthentication();
  const { applicationParamList } = useApplication();
  const openSnackbar = useSnackbar();
  const {
    control,
    errors,
    allFieldValue,
    setSchema,
    handleSubmit,
    setValue,
    formState: { isDirty },
    reset: resetForm,
  } = useZodForm();
  const [loading, setLoading] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const client = sales?.client;

  useEffect(() => {
    setSchema(
      z.object({
        interestItemId: z.string().optional().or(z.literal("")).or(z.null()),
        salesValue: z.number().optional().or(z.literal("")).or(z.null()),
        remark: z.string().optional().or(z.literal("")).or(z.null()),
      })
    );
  }, []);

  useEffect(() => {
    resetForm({
      interestItemId: sales?.interestItemId || undefined,
      salesValue: sales?.salesValue || undefined,
      remark: sales?.remark || undefined,
    });
  }, [sales]);

  const interestItems = useMemo(() => {
    return applicationParamList
      ?.filter((param) => param?.paramType === "ProductService")
      ?.map((param) => {
        return {
          value: param?.id,
          label: param?.paramValue,
        };
      });
  }, [applicationParamList]);

  const handleSave = (data) => {
    setLoading("save");
    const mappedData = {
      ...data,
      id: sales?.id,
      by: user?.username,
    };
    axios
      .post(`${ApiEndpoints.updateSalesInfo}/${sales?.id}`, mappedData)
      .then((res) => {
        resetForm(allFieldValue);
        openSnackbar("Successfully updated sales information", "success");
      })
      .catch((error) => {
        openSnackbar("Failed to update sales information", "error");
      })
      .finally(() => {
        setLoading("");
      });
  };

  return (
    <form onSubmit={handleSubmit(handleSave)} className="sales-details">
      <Header1 sx={{ fontSize: "0.9375rem" }}>Sales Information</Header1>
      <div className="sales-details-field-container">
        <TextField
          label="Client Name"
          variant="standard"
          size="small"
          value={client?.clientName || " "}
          disabled
        />
        <TextField
          label="Company Name"
          variant="standard"
          size="small"
          value={client?.clientComp?.compName || " "}
          disabled
        />
        <MuiTelInput
          label="Phone"
          variant="standard"
          size="small"
          value={client?.phoneNum || " "}
          disabled
        />
        <TextField
          label="Email"
          variant="standard"
          size="small"
          value={client?.email || " "}
          disabled
        />
        <Controller
          name="interestItemId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              options={interestItems || []}
              fullWidth
              onChange={(e, v) => {
                field.onChange(v?.value || undefined);
              }}
              getOptionLabel={(o) => o?.label}
              onBlur={field.onBlur}
              value={interestItems?.find((option) => option?.value === field?.value) || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product / Services Interest"
                  variant="standard"
                  size="small"
                  error={!!errors?.interestItemId}
                  helperText={errors?.interestItemId?.message}
                />
              )}
              disabled={!actionable}
            />
          )}
        />
        <Controller
          name="salesValue"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                label="Deal Value (RM)"
                type="number"
                variant="standard"
                size="small"
                value={field?.value || ""}
                error={!!errors?.salesValue}
                helperText={errors?.salesValue?.message}
                onChange={(e) => {
                  field.onChange(parseFloat(e.target.value) || undefined);
                }}
                disabled={!actionable}
              />
            );
          }}
        />
        <Controller
          name="remark"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                label="Notes"
                variant="standard"
                multiline
                rows={3}
                size={"small"}
                value={field?.value || ""}
                error={!!errors?.remark}
                helperText={errors?.remark?.message}
                onChange={(e) => {
                  field.onChange(e.target.value || undefined);
                }}
                disabled={!actionable}
              />
            );
          }}
        />
      </div>
      <PrimaryButton
        type="submit"
        size="small"
        sx={{ marginTop: "auto", alignSelf: "flex-end" }}
        loading={loading === "save"}
        disabled={!isDirty || !actionable}
      >
        Save
      </PrimaryButton>
    </form>
  );
}
