import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";

import GlobalSearchTextField from "../../../component/gloabalTextfields/searchTextField";
import GlobalTextField from "../../../component/gloabalTextfields/globalTextfield";

import CircularProgress from "@mui/material/CircularProgress";
import { visuallyHidden } from "@mui/utils";

import { Header1 } from "../../../component/globalTypography/headers";
import ResetButton from "../../../component/buttons/tableButtons/resetButton";

import ConfirmDialog from "../../../component/confirmDialog/confirmDialog";

import { useSnackbar } from "../../../contexts/snackbarContext";
import { useApplication } from "../../../redux/application/hook";

import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Box,
  Typography,
  Checkbox,
  Button
} from "@mui/material";


export default function UserRoleListing(props) {

  const notification = useSnackbar();
  const {updateEmployeeList, updateRoleList} = useApplication();

  const {mappedRoleInfo, isLoading, getRoleById} = props;
  const [selectedEmpName, setSelectedEmpName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [searchVal, setSearchVal] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("displayName")
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const headCells = [
    {
      id: "action",
      disablePadding: false,
      label: "Remove Actions",
      center: true,
    },
    {
      id: "name",
      disablePadding: false,
      label: "Name",
      center: false,
    },
    {
      id: "position",
      disablePadding: false,
      label: "Position",
      center: false,
    },
    {
      id: "department",
      disablePadding: false,
      label: "Department",
      center: false,
    },
  ];

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedUsers([]);
  }

  const handleCheckBoxChange = (value, user) => {
    if (value === true) {
      setSelectedUsers((prev) => [...prev, user]);
    } else {
      setSelectedUsers((prev) => prev.filter((u) => u.userId !== user.userId));
    }
  };

  //==========================Api Function===================================
  const handleRemoveUserFromRole = async () => {
    setIsSubmitLoading(true);
    await axios
      .post(ApiEndpoints.removeUserRoleByList, RemoveUsersByList)
      .then((resp) => {
        notification("Successfully removed users.", "success");
        getRoleById();
        updateRoleList();
      })
      .catch((err) => {
        notification("Failed to remove users", "error");
      })
      .finally(() => {
        handleCloseConfirmDialog();
        
        setIsSubmitLoading(false);
      });
  }
  //=========================================================================

  //==========================Search Function================================
  const handleFilterUserRoleBySearchVal = (userRole) => {
    var lowerCaseSearchVal = searchVal.toLowerCase() ?? "";
    return (
      userRole.displayName?.toLowerCase().includes(lowerCaseSearchVal) ||
      userRole.position?.toLowerCase().includes(lowerCaseSearchVal) ||
      userRole.department?.toLowerCase().includes(lowerCaseSearchVal)
    );
  };

  const handleClearFilter = () => {
    setSelectedEmpName("");
    setSelectedPosition("");
    setSelectedDepartment("");
    setSearchVal("");
  }
  //==========================================================================

  //==========================Table Sort Function=============================
  const getComparator = (order, orderBy) => {
    if (orderBy === "no") {
      return order === "desc"
        ? (a, b) => b[orderBy] - a[orderBy]
        : (a, b) => a[orderBy] - b[orderBy];
    } else {
      return order === "desc"
        ? (a, b) =>
            b[orderBy]?.localeCompare(a[orderBy], undefined, {
              numeric: true,
              sensitivity: "base",
            })
        : (a, b) =>
            a[orderBy]?.localeCompare(b[orderBy], undefined, {
              numeric: true,
              sensitivity: "base",
            });
    }
  };

  const handleRequestRoleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //==========================================================================

  //==========================Pagination Function================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //=============================================================================

  //==========================Usememo Function===================================
  const empNameFilter = useMemo(() => {
    return mappedRoleInfo?.userRoles?.filter((a) => selectedPosition ? a.position === selectedPosition : true)
    ?.filter((b) => selectedDepartment ? b.department === selectedDepartment : true)
    ?.sort((a,b) => a.displayName.localeCompare(b.displayName))
    ?.map((z) => z.displayName)
    ?.filter((v, i, a) => a.indexOf(v) === i);
  },[mappedRoleInfo, selectedDepartment,selectedPosition])

  const positionFilter = useMemo(() => {
    return mappedRoleInfo?.userRoles?.filter((a) => selectedEmpName ? a.displayName === selectedEmpName : true)
    ?.filter((b) => selectedDepartment ? b.department === selectedDepartment : true)
    ?.sort((a,b) => a.position.localeCompare(b.position))
    ?.map((z) => z.position)
    ?.filter((v, i, a) => a.indexOf(v) === i);
  },[mappedRoleInfo, selectedDepartment,selectedEmpName])

  const departmentFilter = useMemo(() => {
    return mappedRoleInfo?.userRoles?.filter((a) => selectedEmpName ? a.displayName === selectedEmpName : true)
    ?.filter((b) => selectedPosition ? b.position === selectedPosition : true)
    ?.sort((a,b) => a.department.localeCompare(b.position))
    ?.map((z) => z.department)
    ?.filter((v, i, a) => a.indexOf(v) === i);
  },[mappedRoleInfo, selectedDepartment,selectedPosition])

  const FilterUserRole = useMemo(() => {
    setPage(0)
    return mappedRoleInfo?.userRoles
      ?.filter((a) => (selectedEmpName ? a.displayName === selectedEmpName : true))
      ?.filter((a) => (selectedPosition ? a.position === selectedPosition : true))
      ?.filter((a) => (selectedDepartment ? a.department === selectedDepartment : true))
      .filter((a) => handleFilterUserRoleBySearchVal(a))
      .sort(getComparator(order, orderBy));
  }, [
    mappedRoleInfo,
    selectedEmpName,
    selectedPosition,
    selectedDepartment,
    searchVal,
    order,
    orderBy,
  ]);

  const RemoveUsersByList = useMemo(() => {
    return {
      id: selectedUsers.map((x) => x.userRoleId),
    };
  }, [selectedUsers]);
  //=============================================================================
 
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <Header1 sx={{ fontSize: "1.1rem" }}>Authorized User</Header1>
        <Button
          sx={{
            color: "#ffffff", // Initial text color
            backgroundColor: "#D13F58", // Initial background color
            borderColor: "#FFC107",
            "&:hover": {
              backgroundColor: "#AC3448", // Hover background color
              borderColor: "#FFC107",
            },
            "&.Mui-disabled": {
              color: "#B0B0B0", // Disabled text color
              backgroundColor: "#F5F5F5", // Disabled background color
              borderColor: "#E0E0E0", // Disabled border color
            },
          }}
          variant="contianed"
          size={"small"}
          onClick={() => setOpenConfirmDialog(true)}
          disabled={selectedUsers?.length <= 0}
        >
          Remove User
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <GlobalSearchTextField
          sx={{ width: "30%" }}
          onChange={(e) => setSearchVal(e.target.value)}
          searchValue={searchVal}
          handleClearSearch={() => setSearchVal("")}
        />
        <Autocomplete
          size={"small"}
          sx={{ width: 200, ml: 1 }}
          options={empNameFilter ?? ["no option"]}
          value={selectedEmpName}
          onChange={(e, v) => setSelectedEmpName(v)}
          renderInput={(params) => (
            <GlobalTextField label={"Name"} {...params} />
          )}
        />
        <Autocomplete
          size={"small"}
          sx={{ width: 200, ml: 1 }}
          options={positionFilter ?? ["no option"]}
          value={selectedPosition}
          onChange={(e, v) => setSelectedPosition(v)}
          renderInput={(params) => (
            <GlobalTextField label={"Position"} {...params} />
          )}
        />
        <Autocomplete
          size={"small"}
          sx={{ width: 300, ml: 1 }}
          options={departmentFilter ?? ["no option"]}
          value={selectedDepartment}
          onChange={(e, v) => setSelectedDepartment(v)}
          renderInput={(params) => (
            <GlobalTextField label={"Department"} {...params} />
          )}
        />
        <div style={{ display: "flex", marginLeft: "auto" , alignItems:"center"}}>
          <ResetButton onClick={() => handleClearFilter()}/>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <TableContainer sx={{ maxHeight: "55vh" }}>
          <Table stickyHeader aria-labelledby="tableTitle">
            <TableHead sx={{ zIndex: 1 }}>
              <TableRow>
                <TableCell></TableCell>
                {headCells.map((item) => (
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === item?.id}
                      direction={order === "asc" ? "asc" : "desc"}
                      onClick={() => handleRequestRoleSort(item.id)}
                    >
                      {item.label}
                      {orderBy === item?.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress size={"2rem"} sx={{ pt: 20, pb: 20 }} />
                  </TableCell>
                </TableRow>
              ) : FilterUserRole?.length > 0 ? (
                FilterUserRole?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((x, index) => {
                  var actualIndex = (page + 1 - 1) * rowsPerPage + (index + 1);
                  return (
                    <TableRow
                      key={x.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      hover
                    >
                      <TableCell></TableCell>
                      <TableCell>
                        <Checkbox
                          onChange={(e, v) => {
                            handleCheckBoxChange(v, x);
                          }}
                        />
                      </TableCell>
                      <TableCell>{x.displayName}</TableCell>
                      <TableCell>{x.position}</TableCell>
                      <TableCell>{x.department}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Record Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={FilterUserRole?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <ConfirmDialog
        open={openConfirmDialog}
        loading={isSubmitLoading}
        type={"info"}
        onSubmit={handleRemoveUserFromRole}
        onClose={handleCloseConfirmDialog}
        dialogTitle={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Remove User From Role Confirmation
          </span>
        }
        dialogContent={() => (
          <>
            <Header1>Are you confirm to remove the users:</Header1>
            {selectedUsers.map((x, index) => (
              <Typography>{`${index + 1}: ${x.displayName}`}</Typography>
            ))}
          </>
        )}
      />
    </div>
  );
}