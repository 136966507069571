import React from "react";
import { Paper, Grid } from "@mui/material";
import { Header1 } from "../../../../component/globalTypography/headers";
import FieldRender from "../fieldRender";

export default function ContactDetails(props) {
  const { fields, fieldOptions, errors, control } = props;
  return (
    <Paper
      sx={{ p: "1.5rem", border: "1px solid", borderColor: "#eeeeee", borderRadius: "10px" }}
      elevation={0}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header1 fontSize={"0.9375rem"}>Contact Details</Header1>
        </Grid>

        <Grid item xs={12} container spacing={1}>
          {fields.map((fieldItem) => {
            return (
              <Grid item xs={fieldItem?.gridSize} key={fieldItem?.id}>
                <FieldRender
                  fieldItem={fieldItem}
                  fieldOptions={fieldOptions}
                  control={control}
                  errors={errors}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
}
