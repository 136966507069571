import React from "react";
import GlobalTabs from "../../component/golablTab/globalTab";
import PrimaryButton from "../../component/buttons/primaryButton";

import EmployeeListing from "./employeeListing";
import BreadCrumbs from "../../component/breadCrumbs/breadCrumbs";
import { useNavigate } from "react-router-dom";

export default function EmployeeManagement() {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BreadCrumbs />
        <PrimaryButton size={"small"} onClick={() => navigate("/employeeManagement/create")}>
          Create Employee
        </PrimaryButton>
      </div>

      <div style={{ marginTop: "1.5rem" }}>
        <GlobalTabs
          tabs={[
            { label: "Employee List", component: <EmployeeListing /> },
            { label: "Statistic", component: <></> },
          ]}
        />
      </div>
    </div>
  );
}
