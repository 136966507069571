import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Header2 } from "../../../component/globalTypography/headers";
import { Controller } from "react-hook-form";
import GlobalTextField from "../../../component/gloabalTextfields/globalTextfield";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MuiTelInput } from "mui-tel-input";

export default function FieldRender(props) {
  const { fieldItem, fieldOptions, control, errors } = props;
  const fieldOption = fieldItem.type === "selection" ? fieldOptions?.[fieldItem.id] : undefined;
  return (
    <div>
      <Header2 fontSize={"0.8rem"}>{`${fieldItem.label} ${
        !fieldItem.optional ? "*" : ""
      }`}</Header2>
      {["string", "email"].includes(fieldItem.type) && (
        <Controller
          name={fieldItem.id}
          control={control}
          render={({ field }) => {
            return (
              <GlobalTextField
                {...field}
                value={field?.value ?? ""}
                size={"small"}
                error={!!errors[fieldItem.id]}
                helperText={errors[fieldItem.id]?.message}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
              />
            );
          }}
        />
      )}
      {fieldItem.type === "phone" && (
        <Controller
          name={fieldItem.id}
          control={control}
          render={({ field }) => {
            return (
              <MuiTelInput
                {...field}
                defaultCountry="MY"
                value={field?.value ?? ""}
                size={"small"}
                error={!!errors[fieldItem.id]}
                helperText={errors[fieldItem.id]?.message}
                sx={{ width: "100%" }}
                onChange={(value) => {
                  field.onChange(value.replace(/\s/g, ""));
                }}
              />
            );
          }}
        />
      )}
      {fieldItem.type === "number" && (
        <Controller
          name={fieldItem.id}
          control={control}
          render={({ field }) => {
            return (
              <GlobalTextField
                {...field}
                value={field?.value ?? ""}
                type={"number"}
                size={"small"}
                error={!!errors[fieldItem.id]}
                helperText={errors[fieldItem.id]?.message}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  field.onChange(e.target.value ? parseFloat(e.target.value) : null);
                }}
              />
            );
          }}
        />
      )}
      {fieldItem.type === "date" && (
        <Controller
          name={fieldItem.id}
          control={control}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                    error: !!errors[fieldItem.id],
                    helperText: errors[fieldItem.id]?.message,
                  },
                }}
                TextFieldComponent={(props) => <GlobalTextField {...props} />}
                onBlur={field.onBlur}
                value={field?.value ?? null}
                onChange={(event) => {
                  field.onChange(event ? dayjs(event) : null);
                }}
              />
            </LocalizationProvider>
          )}
        />
      )}
      {fieldItem.type === "selection" && (
        <Controller
          name={fieldItem.id}
          control={control}
          render={({ field }) => (
            <Autocomplete
              options={fieldOption ?? []}
              fullWidth
              onChange={(e, v) => {
                field.onChange(v?.value ?? null);
              }}
              getOptionLabel={(o) => o?.label}
              onBlur={field.onBlur}
              value={fieldOption?.find((option) => option?.value === field?.value) ?? null}
              renderInput={(params) => (
                <GlobalTextField
                  {...params}
                  size={"small"}
                  error={!!errors[fieldItem.id]}
                  helperText={errors[fieldItem.id]?.message}
                />
              )}
            />
          )}
        />
      )}
    </div>
  );
}
