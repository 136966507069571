import React from 'react'
import { Button } from "@mui/material";

const SecondaryButton = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: "#E5E5E5",
        "&:hover": {
          backgroundColor: "#D9D9D9",
          boxShadow: "none",
        },
        boxShadow: "none",
        color: "#999999",
      }}
      variant="contained"
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
