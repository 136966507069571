import React, { useState, useEffect, useMemo } from "react";

import dayjs from "dayjs";

import BreadCrumbs from "../../../component/breadCrumbs/breadCrumbs";
import PrimaryButton from "../../../component/buttons/primaryButton";
import RoleModuleComponent from "./roleModuleComponent";
import ConfirmDialog from "../../../component/confirmDialog/confirmDialog";
import UserRoleListing from "./userRoleListing";
import AddUserRoleDialog from "./addUserRoleDialog";

import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "../../../contexts/snackbarContext";
import { useApplication } from "../../../redux/application/hook";
import { useAuthentication } from "../../../redux/authentication/hook";

import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";

import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  Paper,
  Typography,
  Autocompletem,
  IconButton
} from "@mui/material";

export default function EditRole(props) {
  const [module, setModule] = useState([]);
  const [role, setRole] = useState();
  const [allRawRole, setAllRawRole] = useState();
  const [mappedRoleInfo, setMappedRoleInfo] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [searchParam, setSearchParam] = useSearchParams();

  const [roleName, setRoleName] = useState("");
  const [errorCheck, setErrorCheck] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const [openAddUserRoleDialog, setOpenAddUserRoleDialog] = useState(false);

  const {moduleList} = useApplication();
  const {updateIsCurrentModuleAdmin} = useAuthentication();
  const notification = useSnackbar();
  const roleId = searchParam.get("roleId");

  var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
  dayjs.extend(isSameOrAfter);

  //==========================Dialog Function=====================================
  const handleOpenConfirmDialog = () => {
    if (roleName === "" || allRawRole?.filter((x) => x.roleName !== role.roleName)?.some((x) => x.roleName === roleName)) {
      setErrorCheck(true);
    }  else {
      setOpenConfirmDialog(true);
    }
  }

  const handleCloseAddUserRoleDialog = () => {
    setOpenAddUserRoleDialog(false);
  }

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };
  //============================================================================


  const handleOnChangeCheckBox = (item, value, moduleObject) => {
    if (item === "isGrantAccess") {
      setModule((prevData) =>
        prevData?.map((m) => {
          if (m.id === moduleObject.id) {
            return {
              ...m,
              isGrantAccess: value,
              isAdmin:
                moduleObject.isAdminOnly && value === true
                  ? true
                  : moduleObject.isAdminOnly && value === false
                  ? false :
                  value === false ? false
                  : m.isAdmin,
            };
          } else {
            return m;
          }
        })
      );

      const updatedModules = mappedRoleInfo?.roleModules?.map((module) => {
        if (module.moduleId === moduleObject.id) {
          return {
            ...module,
              isGrantAccess: value,
              isAdmin:
                moduleObject.isAdminOnly && value === true
                  ? true
                  : moduleObject.isAdminOnly && value === false
                  ? false :
                  value === false ? false
                  : module.isAdmin,
          };
        } else {
          return module;
        }
      });

      setMappedRoleInfo((prevState) => ({
        ...prevState,
        roleModules: updatedModules,
      }));
    }

    if (item === "isAdmin") {
      setModule((prevData) =>
        prevData?.map((m) => {
          if (m.id === moduleObject.id) {
            return {
              ...m,
              isGrantAccess: m.isGrantAccess === false ? value : m.isGrantAccess,
              isAdmin: value,
            };
          } else {
            return m;
          }
        })
      );

      const updatedModules = mappedRoleInfo.roleModules?.map((module) => {
        if (module.moduleId === moduleObject.id) {
          return {
            ...module,
            isGrantAccess: module.isGrantAccess === false ? value : module.isGrantAccess,
            isAdmin: value,
          };
        } else {
          return module;
        }
      });

      setMappedRoleInfo((prevState) => ({
        ...prevState,
        roleModules: updatedModules,
      }));
    }
  };

  const validateRoleName = (name) => {
    if (errorCheck && roleName === "") {
      return "Role name is required";
    }

    if(errorCheck && allRawRole?.filter((x) => x.roleName !== role.roleName)?.some((x) => x.roleName?.toLowerCase() === roleName?.toLowerCase())){
      return "Role name already exist"
    }

    return "";
  };

  //==========================API Function=====================================

  const getRoleById = async () => {
    await axios
      .get(`${ApiEndpoints.getRole}/${roleId}`)
      .then((resp) => {
        setRole(resp.data);
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const getAllRole = async () => {
    await axios
      .get(ApiEndpoints.getRole)
      .then((resp) => {
        setAllRawRole(resp.data);
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const fetchData = () => {
    setIsLoading(true);
    Promise.all([
      getRoleById(),
      getAllRole(),
    ]).finally(() => {
      setIsLoading(false);
    });
  };

  const updateRole = async () => {
    setIsSubmitLoading(true);
    await axios
      .post(`${ApiEndpoints.updateRole}/${roleId}`, MappedRoleSubmission)
      .then((resp) => {
        notification("Successfully updated role.", "success");
        getRoleById();
      })
      .catch((error) => {
        notification("Failed to update role.", "error");
      })
      .finally(() => {
        setIsSubmitLoading(false);
        setOpenConfirmDialog(false);
      });
  };
  //============================================================================
  useEffect(() => {
    setModule(
      moduleList?.map((x) => {
        return {
          id: x.id,
          name: x.name,
          moduleType: x.moduleType,
          status: x.status,
          isGrantAccess: false,
          isAdmin: false,
          isAdminOnly: x.isAdminOnly
        };
      })
    );
  }, [moduleList])

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (role) {
      const newMappedRoleInfo = {
        roleId: role.id,
        roleName: role.roleName,
        status: role.status,
        createdBy: role.createdBy,
        createdOn: role.createdOn,
        roleModules: role.roleModules?.map((module) => ({
          id: module.id,
          isAdmin: module.isAdmin,
          isGrantAccess: true,
          moduleId: module.moduleId,
          createdBy: module.createdBy,
          createdOn: module.createdOn,
        })),
        userRoles: role.userRoles?.map((userRole) => ({
          userRoleId: userRole.id,
          createdBy: userRole.createdBy,
          createdOn: userRole.createdOn,
          userId: userRole.user.id,
          empId: userRole.user.emp.id,
          displayName: userRole.user.emp.displayName,
          position: userRole.user.emp.empEmployments?.sort((a,b) => dayjs(b.startDate) - dayjs(a.startDate))[0]?.position,
          department: userRole.user.emp.empEmployments?.sort((a,b) => dayjs(b.startDate) - dayjs(a.startDate))[0]?.dept?.deptName,
        })),
      };

      // Only update state if there are actual changes
      if (JSON.stringify(mappedRoleInfo) !== JSON.stringify(newMappedRoleInfo)) {
        setMappedRoleInfo(newMappedRoleInfo);
        setRoleName(newMappedRoleInfo.roleName);
      }
    }
  }, [role]);

  useEffect(() => {
    if (module && mappedRoleInfo) {
      const updatedDataA = module?.map(itemA => {
        const match = mappedRoleInfo.roleModules?.find(itemB => itemB.moduleId === itemA.id);
        return match ? { ...itemA, isGrantAccess: match.isGrantAccess , isAdmin: match.isAdmin } : itemA;
      });

      // Only update state if there are actual changes
      if (JSON.stringify(module) !== JSON.stringify(updatedDataA)) {
        setModule(updatedDataA);
      }
    }
  }, [mappedRoleInfo, module]);

  const MappedRoleSubmission = useMemo(() => {
    var roleSubmissionFormat = {
      role: {
        roleName: roleName,
        status: 1,
        by: "Admin",
      },
      newRoleModule: module
        ?.filter((x) => x.isGrantAccess)
        ?.map((x) => {
          return {
            moduleId: x.id,
            isAdmin: x.isAdmin ? 1 : 0,
            by: "Admin",
          };
        }),
    };

    return roleSubmissionFormat;
  }, [module, roleName]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BreadCrumbs />
        <PrimaryButton
          size={"small"}
          onClick={() => setOpenAddUserRoleDialog(true)}
        >
          Add User
        </PrimaryButton>
      </div>

      <Grid container sx={{ mt: 2, width: "100%" }} spacing={1}>
        <Grid item xs={3.5}>
          <Paper
            sx={{
              p: 2,
              border: "2px solid #E5E5E5",
              boxShadow: "none",
            }}
          >
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <RoleModuleComponent
                  roleName={roleName}
                  setRoleName={setRoleName}
                  module={module}
                  setModule={setModule}
                  errorCheck={errorCheck}
                  isLoadin={isLoading}
                  handleOnChangeCheckBox={handleOnChangeCheckBox}
                  validateRoleName={validateRoleName}
                />

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <PrimaryButton
                    onClick={() => {
                      handleOpenConfirmDialog();
                    }}
                  >
                    Update
                  </PrimaryButton>
                </div>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={8.5}>
          <Paper
            sx={{
              p: 2,
              border: "2px solid #E5E5E5",
              boxShadow: "none",
            }}
          >
            <UserRoleListing
              mappedRoleInfo={mappedRoleInfo}
              isLoading={isLoading}
              getRoleById={getRoleById}
            />
          </Paper>
        </Grid>
      </Grid>

      <AddUserRoleDialog
        open={openAddUserRoleDialog}
        onClose={handleCloseAddUserRoleDialog}
        mappedRoleInfo={mappedRoleInfo}
        getRoleById={getRoleById}
      />

      <ConfirmDialog
        open={openConfirmDialog}
        loading={isSubmitLoading}
        type={"info"}
        onSubmit={updateRole}
        onClose={handleCloseConfirmationDialog}
        dialogTitle={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Update Role Confirmation
          </span>
        }
        dialogContent={() => (
          <Typography>{`Are you confirm to update this role?`}</Typography>
        )}
      />
    </div>
  );
}
