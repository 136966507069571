import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import SecondaryButton from "../../../../component/buttons/secondaryButton";
import PrimaryButton from "../../../../component/buttons/primaryButton";

export const ProfilePictureDialog = ({ open, close, newProfilePic, handleConfirm, loading }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (newProfilePic) convertFileToImage(newProfilePic);
  }, [newProfilePic]);

  const convertFileToImage = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        setImage(img.src);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Upload New Profile Picture</DialogTitle>
      <DialogContent
        dividers
        style={{ display: "grid", placeItems: "center", backgroundColor: "#f9fdff" }}
      >
        <img
          src={image}
          alt="Profile"
          style={{
            borderRadius: "50%",
            objectFit: "cover",
            aspectRatio: "auto 300 / 300",
            border: "1px solid grey",
          }}
          width={300}
          height={300}
        />
      </DialogContent>
      <DialogActions>
        <SecondaryButton disabled={loading} onClick={close}>
          Cancel
        </SecondaryButton>
        <PrimaryButton loading={loading} onClick={handleConfirm}>
          Confirm
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
