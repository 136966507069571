import React, { useState, useMemo } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Paper,
  Button,
  Chip,
  IconButton,
  Autocomplete,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  TableBody,
  MenuItem,
  Menu,
  TableCell,
  Link,
  CircularProgress,
} from "@mui/material";

import GlobalSearchTextField from "../../component/gloabalTextfields/searchTextField";
import {
  Delete,
  Edit,
  Refresh as RefreshIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import GlobalTextField from "../../component/gloabalTextfields/globalTextfield";
import { useApplication } from "../../redux/application/hook";
import ConfirmDialog from "../../component/confirmDialog/confirmDialog";
import { useAuthentication } from "../../redux/authentication/hook";
import axios from "axios";
import { ApiEndpoints } from "../../configuration/apiEndpoints";
import { useSnackbar } from "../../contexts/snackbarContext";

export default function EmployeeListing({ ...props }) {
  const { user } = useAuthentication();
  const { employeeList, loading: globalLoading, updateEmployeeList } = useApplication();
  const openSnackbar = useSnackbar();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openButtonList = Boolean(anchorEl);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchVal, setSearchVal] = useState("");
  const [filterVal, setFilterVal] = useState({});
  const [confirmDialog, setConfirmDialog] = useState({});
  const [loading, setLoading] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState({});

  const columns = [
    { id: "name", label: "Name", filter: false },
    { id: "workEmail", label: "Working Email", filter: false },
    { id: "position", label: "Position", filter: true },
    { id: "department", label: "Department", filter: true },
    { id: "status", label: "Status", filter: true },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sorting = (a, b, ascending) => {
    if (a === null) {
      return 1;
    }
    if (b === null) {
      return -1;
    }
    if (a === b) {
      return 0;
    }
    if (ascending) {
      return a < b ? -1 : 1;
    }
    return a < b ? 1 : -1;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) =>
          sorting(
            a["mapped"][orderBy]?.toLowerCase() || null,
            b["mapped"][orderBy]?.toLowerCase() || null,
            false
          )
      : (a, b) =>
          sorting(
            a["mapped"][orderBy]?.toLowerCase() || null,
            b["mapped"][orderBy]?.toLowerCase() || null,
            true
          );
  };

  const handleClickButtonList = (employee, event) => {
    setSelectedEmployee(employee);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseButtonList = () => {
    setAnchorEl(null);
    setSelectedEmployee(null);
  };

  const buildFilterOptions = (key, array, filterBy) => {
    return Array.from(
      new Set(
        array
          ?.filter(
            (x) =>
              filterBy.every((filterKey) =>
                filterVal[filterKey] ? x?.mapped?.[filterKey] === filterVal[filterKey] : true
              ) && compareSearch(x, searchVal)
          )
          ?.map((x) => x?.mapped?.[key])
      )
    ).sort();
  };

  const compareSearch = (obj, val) => {
    val = val.toLowerCase();
    const keys = Object.keys(obj?.mapped);
    return keys.some((key) => obj?.mapped?.[key]?.toLowerCase()?.includes(val));
  };

  const clearSearchAndFilter = () => {
    setSearchVal("");
    setFilterVal({});
  };

  const employees = useMemo(() => {
    const statusMap = {
      1: "Active",
      0: "Inactive",
    };
    return employeeList
      ?.filter((employee) => employee?.status !== 9)
      ?.map((employee) => {
        return {
          ...employee,
          mapped: {
            name: employee?.displayName,
            workEmail: employee?.workEmail,
            position: employee?.empEmployments?.[0]?.position,
            department: employee?.empEmployments?.[0]?.dept?.deptName,
            status: statusMap[employee?.status] ?? "Unknown Status",
          },
        };
      });
  }, [employeeList]);

  const filters = useMemo(() => {
    return columns
      .filter((x) => x.filter)
      .map((column) => {
        var filterBy = Object.keys(filterVal)?.filter((key) => !key?.includes(column.id));
        return {
          ...column,
          options: buildFilterOptions(column.id, employees, filterBy),
        };
      });
  }, [employees, columns, filterVal, searchVal]);

  const filteredEmployees = useMemo(() => {
    return employees
      ?.filter((employee) => {
        var filterBy = Object.keys(filterVal);
        return (
          filterBy.every((filterKey) =>
            filterVal[filterKey] ? employee?.mapped?.[filterKey] === filterVal[filterKey] : true
          ) && compareSearch(employee, searchVal)
        );
      })
      ?.sort(getComparator(order, orderBy));
  }, [employees, filterVal, searchVal, order, orderBy]);

  const handleStatusChange = (employee) => {
    const action = {
      Active: "Deactivate",
      Inactive: "Activate",
    }[employee?.mapped?.status];
    if (action) {
      setConfirmDialog({
        type: action === "Deactivate" ? "error" : "info",
        open: true,
        title: `${action} Employee Status`,
        content: `Are you sure to ${action.toLowerCase()} ${
          employee?.mapped?.name
        }'s employee profile?`,
        submit: () => updateEmployeeStatus(employee, employee?.status === 0 ? 1 : 0),
      });
    }
  };

  const handleDelete = (employee) => {
    if (employee?.id) {
      setConfirmDialog({
        type: "error",
        open: true,
        title: `Delete Employee Profile`,
        content: `Are you sure to delete ${employee?.mapped?.name}'s employee profile?\nThis action will also remove the employee's user account.`,
        submit: () => deleteEmployee(employee),
      });
    }
  };

  const updateEmployeeStatus = (employee, status) => {
    setLoading("confirmDialog");
    const data = {
      id: employee.id,
      status,
      by: user.username,
    };

    axios
      .post(`${ApiEndpoints.updateEmployeeStatus}/${employee.id}`, data)
      .then((res) => {
        openSnackbar("Successfully updated employee status", "success");
        updateEmployeeList();
      })
      .catch((error) => {
        openSnackbar("Failed to update employee status", "error");
      })
      .finally(() => {
        setConfirmDialog({});
        setLoading("");
      });
  };

  const deleteEmployee = (employee) => {
    setLoading("confirmDialog");
    const data = {
      id: employee.id,
      by: user.username,
    };

    axios
      .post(`${ApiEndpoints.deleteEmployee}/${employee.id}`, data)
      .then((res) => {
        openSnackbar(
          "The employee and user account are marked as deleted in the system",
          "success"
        );
        updateEmployeeList();
      })
      .catch((error) => {
        openSnackbar("Failed to delete employee", "error");
      })
      .finally(() => {
        setConfirmDialog({});
        setLoading("");
      });
  };

  const statusChipStyle = {
    Active: {
      color: "#28A745",
      backgroundColor: "#D4EDDA",
      "&:hover": {
        backgroundColor: "#C5E6CD",
      },
    },
    Inactive: {
      color: "#D13F58",
      backgroundColor: "#FFEBEF",
      "&:hover": {
        backgroundColor: "#FCDEE5",
      },
    },
  };

  return (
    <Paper sx={{ mt: 2 }}>
      <div
        style={{
          display: "flex",
          padding: "16px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px", flex: 8 }}>
          <GlobalSearchTextField
            searchValue={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            handleClearSearch={() => setSearchVal("")}
            sx={{ flex: 1 }}
          />
          {filters.map((filter) => (
            <Autocomplete
              key={filter.id}
              size="small"
              renderInput={(params) => <GlobalTextField {...params} label={filter.label} />}
              options={filter?.options}
              sx={{ flex: 1 }}
              value={filterVal?.[filter.id] ?? null}
              onChange={(e, value) =>
                setFilterVal((curr) => {
                  return { ...curr, [filter?.id]: value };
                })
              }
            />
          ))}
        </div>
        <div style={{ flex: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button
            startIcon={<RefreshIcon />}
            sx={{
              borderColor: "#E5E5E5",
              color: "#999999",
              backgroundColor: "#ffffff",
              "&:hover": {
                borderColor: "#E5E5E5",
                backgroundColor: "#E5E5E5",
              },
              "& .MuiButton-startIcon": {
                color: "#999999",
              },
            }}
            variant="outlined"
            onClick={() => clearSearchAndFilter()}
          >
            Clear
          </Button>
        </div>
      </div>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={openButtonList}
        onClose={handleCloseButtonList}
      >
        <MenuItem
          onClick={() => {
            handleCloseButtonList();
            navigate(`/employeeManagement/edit?id=${selectedEmployee.id}`);
          }}
        >
          <Edit />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseButtonList();
            handleDelete(selectedEmployee);
          }}
        >
          <Delete />
          Delete
        </MenuItem>
      </Menu>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  {column.sortable !== false && (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}
                  {column.sortable === false && column.label}
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {globalLoading?.employeeList || filteredEmployees?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: "center", padding: "18px" }}>
                  {globalLoading?.employeeList ? (
                    <CircularProgress size={"0.875rem"} />
                  ) : (
                    "No results found"
                  )}
                </TableCell>
              </TableRow>
            ) : (
              filteredEmployees
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((employee, index) => (
                  <TableRow key={index}>
                    <TableCell></TableCell>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {column.id === "name" ? (
                          <Link
                            underline="hover"
                            color="#34C6F5"
                            to={`/employeeManagement/edit?id=${employee.id}`}
                            component={RouterLink}
                          >
                            {employee?.mapped?.[column.id]}
                          </Link>
                        ) : column.id === "status" ? (
                          <Chip
                            sx={statusChipStyle[employee?.mapped?.[column.id]]}
                            label={employee?.mapped?.[column.id]}
                            onClick={() => {
                              handleStatusChange(employee);
                            }}
                          />
                        ) : (
                          employee?.mapped?.[column.id]
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <IconButton
                        onClick={(event) => {
                          handleClickButtonList(employee, event);
                        }}
                      >
                        <MoreVertIcon sx={{ color: "#999999" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredEmployees?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {confirmDialog?.open && (
        <ConfirmDialog
          open={confirmDialog?.open}
          type={confirmDialog?.type}
          onSubmit={confirmDialog?.submit}
          onClose={() => setConfirmDialog({})}
          dialogTitle={confirmDialog?.title}
          dialogContent={() => confirmDialog?.content}
          loading={loading === "confirmDialog"}
        />
      )}
    </Paper>
  );
}
