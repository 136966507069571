import React from "react";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const PrimaryButton = ({ children, ...props }) => {
  return (
    <LoadingButton
      {...props}
      sx={{
        ...props.sx,
        color: "#FFFFFF",
        backgroundColor: "#34C6F5",
        "&:hover": {
          backgroundColor: "#2795B9",
          boxShadow:"none",
        },
        boxShadow: "none",
      }}
      variant="contained"
    >
      {children}
    </LoadingButton>
  );
};

export default PrimaryButton;
