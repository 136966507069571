import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import SideNav from "./sideNav";
import TopNav from "./topNav";
import "../layout/layout.css";
import axios from "axios";
import { ApiEndpoints } from "../../configuration/apiEndpoints";
import { useAuthentication } from "../../redux/authentication/hook";
import { useApplication } from "../../redux/application/hook";
import { jwtDecode } from "jwt-decode";
import { useSnackbar } from "../../contexts/snackbarContext";

export default function Layout() {
  const location = useLocation();
  const {
    user,
    isAuthenticated,
    updateUser,
    updateIsAuthenticated,
    deauthenticateUser,
    updateModuleAccess,
  } = useAuthentication();
  const {
    updateEmployeeList,
    updateApplicationParamList,
    updateCompanyList,
    updateDepartmentList,
    updateModuleList,
    updateRoleList,
  } = useApplication();
  const openSnackbar = useSnackbar();
  const [isHovered, setIsHovered] = useState(false);

  var accessToken = localStorage.getItem("accessToken");

  const validateToken = async () => {
    await axios
      .post(ApiEndpoints.validateToken, accessToken)
      .then((res) => {
        if (res.data?.accessToken) {
          localStorage.setItem("accessToken", res.data?.accessToken);
        }
        updateUser(res.data?.user);
        updateIsAuthenticated(true);
      })
      .catch(async (error) => {
        deauthenticateUser();
      });
  };

  const refreshToken = async () => {
    await axios
      .post(ApiEndpoints.refreshToken, accessToken)
      .then((res) => {
        localStorage.setItem("accessToken", res.data?.accessToken);
        updateIsAuthenticated(true);
      })
      .catch((error) => {
        deauthenticateUser();
        openSnackbar(
          "Oops! It seems you've been logged out due to security measures. For your safety, please log in again.",
          "error"
        );
      });
  };

  useEffect(() => {
    if (isAuthenticated === null) {
      if (accessToken) {
        validateToken();
      } else {
        updateIsAuthenticated(false);
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      updateEmployeeList();
      updateApplicationParamList();
      updateCompanyList();
      updateDepartmentList();
      updateModuleList();
      updateRoleList();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (accessToken) {
      const decoded = jwtDecode(accessToken, { complete: true });
      const expiryTimestamp = decoded?.exp;
      const interval = setInterval(async () => {
        const now = Math.floor(Date.now() / 1000);
        const timeUntilExpiry = expiryTimestamp - now;
        if (timeUntilExpiry <= 300) {
          await refreshToken();
        }
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [accessToken]);

  useEffect(() => {
    const moduleAccess = [
      ...new Set(
        user?.userRoles?.flatMap((userRole) =>
          userRole?.role?.roleModules
            ?.filter((rm) => (rm?.module?.isAdminOnly ? rm?.isAdmin : true))
            ?.map((rm) => {
              return {
                name: rm?.module?.name,
                isAdminOnly: rm?.module?.isAdminOnly,
                isAdmin: rm?.isAdmin,
              };
            })
        )
      ),
    ];
    updateModuleAccess(moduleAccess);
  }, [user]);

  return isAuthenticated === null ? (
    "Loading..."
  ) : isAuthenticated ? (
    <div className="main-grid-container">
      <div className="sidebar">
        <SideNav isHovered={isHovered} setIsHovered={setIsHovered} />
      </div>

      <div className="top-nav">
        <TopNav />
      </div>
      <div className="main-content">
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace={true} />
  );
}
