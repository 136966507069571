import React, { useState, useEffect, useMemo } from "react";

import PrimaryButton from "../../../component/buttons/primaryButton";
import SecondaryButton from "../../../component/buttons/secondaryButton";
import { Header2 } from "../../../component/globalTypography/headers";
import GlobalTextField from "../../../component/gloabalTextfields/globalTextfield";

import RoleModuleComponent from "./roleModuleComponent";

import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import { useApplication } from "../../../redux/application/hook";
import { useSnackbar } from "../../../contexts/snackbarContext";
import ConfirmDialog from "../../../component/confirmDialog/confirmDialog";

export default function AddRoleDialog(props) {
  const {moduleList} = useApplication();
  const notification = useSnackbar();

  const { open, onClose, getAllRole, roleNameFilter } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [openConfirmDialog, setConfirmDialog] = useState(false);

  const [module, setModule] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [errorCheck, setErrorCheck] = useState(false);

  const handleCloseConfirmationDialog = () => {
    setConfirmDialog(false);
  };

  const handleSubmit = () => {
    if (roleName === "" || roleNameFilter.some((x) => x === roleName)) {
      setErrorCheck(true);
    }  else {
      setConfirmDialog(true);
    }
  };

  const validateRoleName = (name) => {
    if (errorCheck && name === "") {
      return "Role name is required";
    }

    if (
      errorCheck &&
      roleNameFilter.some((x) => x?.toLowerCase() === name?.toLowerCase())
    ) {
      return "Role name already exists";
    }

    return "";
  };

  const handleOnChangeCheckBox = (item, value, moduleObject) => {
    if (item === "isGrantAccess") {
      setModule((prevData) =>
        prevData?.map((m) => {
          if (m.id === moduleObject.id) {
            return {
              ...m,
              isGrantAccess: value,
              isAdmin:
                moduleObject.isAdminOnly === 1 && value === true
                  ? true
                  : moduleObject.isAdminOnly === 1 && value === false
                  ? false :
                  value === false ? false
                  : m.isAdmin,
            };
          } else {
            return m;
          }
        })
      );
    }

    if (item === "isAdmin") {
      setModule((prevData) =>
        prevData?.map((m) => {
          if (m.id === moduleObject.id) {
            return {
              ...m,
              isGrantAccess: m.isGrantAccess === false ? value : m.isGrantAccess,
              isAdmin: value,
            };
          } else {
            return m;
          }
        })
      );
    }
  };

  //==========================API Function=====================================

  const createRole = async () => {
    setIsSubmitLoading(true);
    await axios
      .post(ApiEndpoints.createRoleByList, MappedRoleSubmission)
      .then((resp) => {
        notification("Successfully created a role.", "success");
        setRoleName("");
        onClose();
        getAllRole();
      })
      .catch((err) => {
        notification("Fail to create a role", "error");
      })
      .finally(() => {
        setIsSubmitLoading(false);
        handleCloseConfirmationDialog();
      });
  };
  //============================================================================

  useEffect(() => {
    setModule(
      moduleList?.map((x) => {
        return {
          id: x.id,
          name: x.name,
          moduleType: x.moduleType,
          status: x.status,
          isAdminOnly: x.isAdminOnly,
          isGrantAccess: false,
          isAdmin: false,
        };
      })
    );
  },[moduleList])

  useEffect(() => {
    if (!open) {
      setRoleName("");
      setModule(
        moduleList?.map((x) => {
          return {
            id: x.id,
            name: x.name,
            moduleType: x.moduleType,
            status: x.status,
            isAdminOnly: x.isAdminOnly,
            isGrantAccess: false,
            isAdmin: false,
          };
        })
      );
    }
  }, [open]);

  const MappedRoleSubmission = useMemo(() => {
    var roleSubmissionFormat = {
      role: {
        roleName: roleName,
        status: 1,
        by: "Admin",
      },
      newRoleModule: module
        ?.filter((x) => x.isGrantAccess)
        ?.map((x) => {
          return {
            moduleId: x.id,
            isAdmin: x.isAdmin ? 1 : 0,
            by: "Admin",
          };
        }),
    };

    return roleSubmissionFormat;
  }, [module, roleName]);

  return (
    <div>
      {" "}
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add Role</DialogTitle>
        <DialogContent sx={{ p: 3, overflowX: "hidden" }}>
          <RoleModuleComponent
            roleName={roleName}
            setRoleName={setRoleName}
            module={module}
            setModule={setModule}
            errorCheck={errorCheck}
            isLoadin={isLoading}
            handleOnChangeCheckBox={handleOnChangeCheckBox}
            validateRoleName={validateRoleName}
          />
        </DialogContent>

        <DialogActions>
          <SecondaryButton size={"small"} onClick={() => onClose()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size={"small"} onClick={() => handleSubmit()}>
            Submit
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openConfirmDialog}
        loading={isSubmitLoading}
        type={"info"}
        onSubmit={createRole}
        onClose={handleCloseConfirmationDialog}
        dialogTitle={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Create Role Confirmation
          </span>
        }
        dialogContent={() => (
          <Typography>{`Are you confirm to create role ${roleName}`}</Typography>
        )}
      />
    </div>
  );
}
