import React from "react";
import { Button } from "@mui/material";

import ResetIcon from "@mui/icons-material/Refresh";

const ResetButton = ({ onClick }) => {
  return (
    <Button
      startIcon={<ResetIcon />}
      sx={{
        borderColor: "#E5E5E5", // Initial border color
        color: "#999999", // Initial text color
        backgroundColor: "#ffffff", // Initial background color
        "&:hover": {
          borderColor:"#E5E5E5",
          backgroundColor: "#E5E5E5", // Hover background color
        },
        "& .MuiButton-startIcon": {
          color: "#999999", // Initial color for the icon
        },
      }}
      onClick={onClick}
      variant="outlined"
      size={"small"}
    >
      Clear
    </Button>
  );
};

export default ResetButton;
