import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";

export default (props) => {
  const { open, onClose, onSubmit, dialogContent, dialogTitle, type, loading } = props;
  const typeStyle = {
    info: {
      backgroundColor: "#FAFDFF",
      cancelBtn: {
        color: "#34C6F5",
        backgroundColor: "#E1F7FE",
        "&:hover": {
          backgroundColor: "#CFF0FB",
        },
      },
      confirmBtn: {
        color: "#FFFFFF",
        backgroundColor: "#34C6F5",
        "&:hover": {
          backgroundColor: "#20BBE9",
        },
      },
    },
    error: {
      backgroundColor: "#FFFAFB",
      cancelBtn: {
        color: "#D13F58",
        backgroundColor: "#FFEBEF",
        "&:hover": {
          backgroundColor: "#FCDEE5",
        },
      },
      confirmBtn: {
        color: "#FFFFFF",
        backgroundColor: "#D13F58",
        "&:hover": {
          backgroundColor: "#C22E4B",
        },
      },
    },
  };
  return (
    <Dialog
      open={open}
      onClick={(e) => e.stopPropagation()}
      // onClose={onClose}
      sx={{ padding: "0px" }}
      PaperProps={{ sx: { borderRadius: "8px" } }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          gap: "1.5rem",
          backgroundColor: typeStyle[type]?.backgroundColor ?? "#FFFFF",
        }}
      >
        <div
          style={{
            color: "#202020",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          {dialogTitle}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>{dialogContent()}</div>
        </div>
        <div style={{ display: "flex", gap: "0.8rem", flexGrow: 1 }}>
          <Button
            disabled={loading}
            sx={{
              width: "8rem",
              ...typeStyle[type]?.cancelBtn,
            }}
            onClick={(event) => {
              onClose();
              event.stopPropagation();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            sx={{
              width: "8rem",
              ...typeStyle[type]?.confirmBtn,
            }}
            onClick={(event) => {
              onSubmit();
              event.stopPropagation();
            }}
          >
            Confirm
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
