import React, { useMemo, useState } from "react";
import Divider from "@mui/material/Divider";

import {
  Typography,
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";

import BizRoyaltyLogo from "../../assets/images/bizRoyaltyLogo.png";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { useAuthentication } from "../../redux/authentication/hook";
import { useSnackbar } from "../../contexts/snackbarContext";
import { routes } from "../../configuration/routes";

const drawerWidth = "15rem";
const miniDrawerWidth = "3.5rem";

export default function SideNav(props) {
  const { isHovered, setIsHovered } = props;
  const { deauthenticateUser, moduleAccess } = useAuthentication();
  const navigate = useNavigate();
  const openSnackbar = useSnackbar();
  const [selectedMenu, setSelectedMenu] = useState("");

  const convertRoutesToMenuList = (routes, parentPath = "") => {
    const menuList = [];

    routes?.forEach((route) => {
      const path = `${parentPath}${route?.path ? "/" + route?.path : ""}`;
      if (route?.sideNav) {
        menuList.push({
          title: route?.title,
          icon: route?.sideNav?.icon,
          route: path,
          module: route?.sideNav?.module,
        });
      }

      if (route?.children) {
        const childMenuList = convertRoutesToMenuList(route?.children, path);
        menuList.push(...childMenuList);
      }
    });

    return menuList;
  };

  const menuList = useMemo(() => {
    const allMenuList = convertRoutesToMenuList(routes);
    const authenticatedMenuList = allMenuList?.filter((menu) =>
      moduleAccess?.some((m) => m?.name === menu?.module)
    );

    return authenticatedMenuList;
  }, [routes, moduleAccess]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSignOut = async () => {
    deauthenticateUser();
    navigate("/login", { replace: true });
    openSnackbar("Successfully signed out", "success");
  };

  const drawer = (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box component="img" src={BizRoyaltyLogo} sx={{ height: "1.5rem", width: "1.5rem", p: "1rem" }} />
      <List>
        {menuList.map((menuItem, index) => (
          <li key={menuItem.route}>
            <Link underline="none" component={RouterLink} to={menuItem.route}>
              <ListItemButton
                onClick={() => setSelectedMenu(menuItem.route)}
                sx={{
                  padding: "0.5rem 1rem",
                  ":hover": {
                    backgroundColor: "#3D4288",
                  },
                  backgroundColor: menuItem.route === selectedMenu ? "#3D4288" : null,
                }}
              >
                <ListItemIcon sx={{ color: "#ffffff", minWidth: 0, marginRight: "1.4rem" }}>
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography color={"white"} fontSize={"0.875rem"}>
                      {menuItem.title}
                    </Typography>
                  }
                />
              </ListItemButton>
            </Link>
          </li>
        ))}
      </List>

      <div style={{ marginTop: "auto" }}>
        <Divider sx={{ backgroundColor: "#ffffff" }} />
        <List>
          <li>
            <ListItemButton sx={{ padding: "0.5rem 1rem" }} onClick={() => handleSignOut()}>
              <ListItemIcon sx={{ color: "#ffffff", minWidth: 0, marginRight: "1.4rem" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color={"white"} fontSize={"0.875rem"}>
                    Sign Out
                  </Typography>
                }
              />
            </ListItemButton>
          </li>
        </List>
      </div>
    </div>
  );

  return (
    <Drawer
      variant="permanent"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        "& .MuiDrawer-paper": {
          width: isHovered ? drawerWidth : miniDrawerWidth,
          whiteSpace: "nowrap",
          transition: "width 0.3s ease",
          overflowX: "hidden",
          backgroundColor: "#1F246B",
          zIndex: 1000,
        },
      }}
    >
      {drawer}
    </Drawer>
  );
}
