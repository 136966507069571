import React, { useEffect } from "react";
import { Paper, Grid } from "@mui/material";
import GlobalTextField from "../../component/gloabalTextfields/globalTextfield";
import PrimaryButton from "../../component/buttons/primaryButton";
import { Header1, Header2 } from "../../component/globalTypography/headers";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ApiEndpoints } from "../../configuration/apiEndpoints";
import { useSnackbar } from "../../contexts/snackbarContext";
import { useAuthentication } from "../../redux/authentication/hook";

export default function Login(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const openSnackbar = useSnackbar();
  const { updateUser, updateIsAuthenticated, isAuthenticated } = useAuthentication();
  const { from } = location.state || { from: { pathname: "/" } };

  const schema = z.object({
    username: z.string().trim().min(1, "Required"),
    password: z.string().trim().min(1, "Required"),
  });

  var accessToken = localStorage.getItem("accessToken");

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const autoSignIn = async () => {
    await axios.post(ApiEndpoints.validateToken, accessToken).then((res) => {
      if (res.data?.accessToken) {
        localStorage.setItem("accessToken", res.data?.accessToken);
      }
      updateUser(res.data?.user);
      updateIsAuthenticated(true);
      navigate(from.pathname, { replace: true });
    });
  };

  useEffect(() => {
    if (accessToken) {
      // sign in with available token
      autoSignIn();
    }
  }, []);

  const onSubmit = async (data) => {
    await axios
      .post(ApiEndpoints.login, data)
      .then((res) => {
        localStorage.setItem("accessToken", res.data?.accessToken);
        updateIsAuthenticated(true);
        updateUser(res.data?.user);
        navigate(from.pathname, { replace: true });
        openSnackbar("Successfully signed in.", "success");
      })
      .catch((error) => {
        console.error(error);
        if (error?.response) openSnackbar(error?.response?.data, "error");
        else openSnackbar(error?.message, "error");
      });
  };

  const handleErrorMsg = (error, name) => {
    if (error === "Required") return `${name} is required`;
    return error;
  };

  return isAuthenticated ? (
    <Navigate to={from.pathname} replace={true} />
  ) : (
    <form
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Paper
        sx={{
          maxWidth: "32.5rem",
          wdith: "100%",
        }}
        elevation={4}
      >
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12}>
            <Header1 fontSize={"24px"}>Welcome Back</Header1>
          </Grid>
          <Grid item xs={12}>
            <Header2 fontSize={"13px"}>Please sign in to your account</Header2>
          </Grid>

          <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
            <Header2 fontSize={"13px"}>Username</Header2>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <GlobalTextField
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  size="small"
                  error={!!errors?.username}
                  helperText={handleErrorMsg(errors?.username?.message, "Username")}
                  fullWidth
                  onBlur={field.onBlur}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <Header2 fontSize={"13px"}>Password</Header2>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <GlobalTextField
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  type="password"
                  size="small"
                  error={!!errors?.password}
                  helperText={handleErrorMsg(errors?.password?.message, "Password")}
                  fullWidth
                  onBlur={field.onBlur}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <PrimaryButton type={"submit"} sx={{ width: "100%" }} loading={isSubmitting}>
              Sign In
            </PrimaryButton>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
}
