import React, { useMemo } from "react";
import { Paper, Grid, Button } from "@mui/material";
import { Header1 } from "../../../../component/globalTypography/headers";
import FieldRender from "../fieldRender";

export default function EmployeeInitialDetails(props) {
  const { fields, fieldOptions, errors, control, defaultFields, setFields } = props;

  const employmentGroups = useMemo(() => {
    const employmentGroups = {};
    fields.forEach((field) => {
      const employmentNum = field.id?.split("-")[1];
      if (!employmentGroups[employmentNum]) {
        employmentGroups[employmentNum] = [];
      }
      employmentGroups[employmentNum].push(field);
    });
    return employmentGroups;
  }, [fields]);

  const handleAddEmployment = (key) => {
    setFields((curr) => {
      return [
        ...curr,
        ...defaultFields?.map((x) => {
          const splitId = x.id?.split("-");
          const keyNo = parseInt(key) + 1;
          const keyName = splitId[0];
          const obj = {
            ...x,
            id: `${keyName}-${keyNo}`,
          };
          return obj;
        }),
      ];
    });
  };

  const handleRemoveEmployment = (key) => {
    setFields((curr) => {
      return [
        ...curr?.filter((x) => (x.section === "employment" ? x.id.split("-")[1] !== key : true)),
      ];
    });
  };

  return (
    <Paper
      sx={{ p: "1.5rem", border: "1px solid", borderColor: "#eeeeee", borderRadius: "10px" }}
      elevation={0}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header1 fontSize={"0.9375rem"}>Employment Details</Header1>
        </Grid>

        <Grid item xs={12} container spacing={1}>
          {Object.keys(employmentGroups)?.map((key, index) => {
            return (
              <React.Fragment key={index}>
                {employmentGroups[key]?.map((fieldItem) => {
                  return (
                    <Grid item xs={fieldItem?.gridSize} key={fieldItem.id}>
                      <FieldRender
                        fieldItem={fieldItem}
                        fieldOptions={fieldOptions}
                        control={control}
                        errors={errors}
                      />
                    </Grid>
                  );
                })}

                <Grid item xs={12} container justifyContent={"flex-end"}>
                  {Object.keys(employmentGroups)?.length - 1 !== 0 && (
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.75rem",
                        color: "#FF7043",
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                      onClick={() => handleRemoveEmployment(key)}
                    >
                      Remove Employment
                    </Button>
                  )}
                  {Object.keys(employmentGroups)?.length - 1 === index && (
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.75rem",
                        color: "#34C6F5",
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                      onClick={() => handleAddEmployment(key)}
                    >
                      Add Employment
                    </Button>
                  )}
                </Grid>
              </React.Fragment>
            );
          })}
          {/* {fields.map((fieldItem) => {
            return (
              <Grid item xs={fieldItem?.gridSize} key={fieldItem?.id}>
                <FieldRender
                  fieldItem={fieldItem}
                  fieldOptions={fieldOptions}
                  control={control}
                  errors={errors}
                />
              </Grid>
            );
          })} */}
        </Grid>
      </Grid>
    </Paper>
  );
}
