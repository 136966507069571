import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";
import { ApiEndpoints } from "../../../configuration/apiEndpoints";

import { visuallyHidden } from "@mui/utils";

import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import GlobalSearchTextField from "../../../component/gloabalTextfields/searchTextField";

import {
  Paper,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Radio,
  Box,
} from "@mui/material";

import dayjs from "dayjs";

const headCells = [
  {
    id: "action",
    disablePadding: false,
    label: "Action",
    center: false,
  },
  {
    id: "clientName",
    disablePadding: false,
    label: "Client Name",
    center: false,
  },
  {
    id: "phoneNum",
    disablePadding: false,
    label: "Phone",
    center: false,
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
    center: false,
  },
  {
    id: "createdOn",
    disablePadding: false,
    label: "Created On",
    center: false,
  },
];

export default function ExistingClientListing(props) {
  const { rawClientInfo, isLoading, selectedClient, setSelectedClient } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("clientName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchVal, setSearchVal] = useState("");

  //==========================Search Function================================
  const handleFilterLeadBySearchVal = (data) => {
    var lowerCaseSearchVal = searchVal.toLowerCase() ?? "";
    return (
      data.clientName?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.phone?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.email?.toLowerCase().includes(lowerCaseSearchVal) ||
      data.createdOn?.toLowerCase().includes(lowerCaseSearchVal)
    );
  };
  //============================================================================

  //==========================Table Sort Function=============================
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) =>
          b[orderBy]?.localeCompare(a[orderBy], undefined, {
            numeric: true,
            sensitivity: "base",
          })
      : (a, b) =>
          a[orderBy]?.localeCompare(b[orderBy], undefined, {
            numeric: true,
            sensitivity: "base",
          });
  };

  const handleRequestRoleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //==========================================================================

  //==========================Pagination Function================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const MappedClientInfo = useMemo(() => {
    return rawClientInfo?.map((x) => {
      return {
        clientId: x.id,
        clientName: x.clientName,
        phoneNum: x.phoneNum,
        email: x.email,
        createdOn: dayjs(x.createdOn).format("DD MMM YYYY, HH:MM"),
      };
    });
  }, [rawClientInfo]);

  const filterData = useMemo(() => {
    return MappedClientInfo?.filter((a) => handleFilterLeadBySearchVal(a))?.sort(
      getComparator(order, orderBy)
    );
  }, [MappedClientInfo, order, orderBy, searchVal]);

  return (
    <Paper
      sx={{
        p: 2,
        marginTop: "1rem",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
      }}
    >
      <div style={{ display: "flex" }}>
        <GlobalSearchTextField
          sx={{ width: "500px" }}
          onChange={(e) => setSearchVal(e.target.value)}
          searchValue={searchVal}
          handleClearSearch={() => setSearchVal("")}
        />
      </div>

      <div style={{ marginTop: "31px" }}>
        <TableContainer sx={{ maxHeight: "55vh" }}>
          <Table stickyHeader aria-labelledby="tableTitle">
            <TableHead sx={{ zIndex: 1 }}>
              <TableRow>
                <TableCell></TableCell>
                {headCells.map((item) => (
                  <TableCell key={item.id} align="left">
                    <TableSortLabel
                      active={orderBy === item?.id}
                      direction={order === "asc" ? "asc" : "desc"}
                      onClick={() => handleRequestRoleSort(item.id)}
                    >
                      {item.label}
                      {orderBy === item?.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress size={"2rem"} sx={{ pt: 20, pb: 20 }} />
                  </TableCell>
                </TableRow>
              ) : filterData?.length > 0 ? (
                filterData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((x, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        hover
                        selected={selectedClient?.clientId === x.clientId}
                        onClick={() => setSelectedClient(x)}
                      >
                        <TableCell></TableCell>
                        <TableCell>
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 15,
                              },
                            }}
                            checked={selectedClient?.clientId === x.clientId}
                          />
                        </TableCell>
                        <TableCell>{x.clientName}</TableCell>
                        <TableCell>{x.phoneNum}</TableCell>
                        <TableCell>{x.email}</TableCell>
                        <TableCell>{x.createdOn}</TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Record Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filterData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Paper>
  );
}
