import React, { useEffect, useMemo, useState } from 'react'

import axios from "axios";
import {ApiEndpoints} from "../../configuration/apiEndpoints"

import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Autocomplete } from '@mui/material'

import { Header2 } from '../../component/globalTypography/headers'
import GlobalAutoComplete from '../../component/gloabalTextfields/globalAutoComplete'
import GlobalTextField from '../../component/gloabalTextfields/globalTextfield'

import PrimaryButton from '../../component/buttons/primaryButton'
import SecondaryButton from '../../component/buttons/secondaryButton'

import ConfirmDialog from '../../component/confirmDialog/confirmDialog';
import { useSnackbar } from "../../contexts/snackbarContext";

import { useApplication } from '../../redux/application/hook';

export default function ParamSettingDialogbox(props) {
  const {
    open,
    onClose,
    MappedApplicationParam,
    selectedParamSetting,
    action,
    setSelectedModule,
    setSelectedParamType,
    setSelectedStatus,
    setSearchVal,
    setPage,
  } = props;

  const {moduleList, updateApplicationParamList} = useApplication()

  const [isLoading, setIsLoading] = useState(false);

  const [paramVal, setParamVal] = useState({
    module: null,
    paramType: "",
    paramValue: "",
    paramDesc: "",
    paramRemark: "",
    paramSeq: "",
  });
  const [errorCheck, setErrorCheck] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const notification = useSnackbar();

  //==========================Confirmation Dialog Function=====================
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleSubmitButton = () => {
    if (
      paramVal?.paramType === "" ||
      paramVal?.paramValue === "" ||
      paramVal?.module === null ||
      /\./.test(paramVal?.paramSeq)
    ) {
      setErrorCheck(true);
    } else {
      setOpenConfirmDialog(true);
    }
  };
  //============================================================================

  //==========================API Function=====================================
  const handleSubmit = async () => {

    if (action === "Create") {
      setIsSubmitLoading(true);
      await axios
        .post(ApiEndpoints.createApplicationParam, MappedSubmissionData)
        .then((resp) => {
          notification("Successfully created parameter.", "success");
          onClose();
          updateApplicationParamList();
          setSelectedModule("");
          setSelectedParamType("");
          setSelectedStatus("");
          setSearchVal("");
          setPage(0);
        })
        .catch((err) => {
          notification("Fail to create a parameter", "error");
        })
        .finally(() => {
          setIsSubmitLoading(false);
          handleCloseConfirmationDialog();
        });
    }

    if (action === "Update") {
      setIsSubmitLoading(true);
      await axios
        .put(
          `${ApiEndpoints.updateApplicationParam}/${selectedParamSetting?.id}`,
          MappedSubmissionData
        )
        .then((resp) => {
          notification("Successfully updated parameter.", "success");
          onClose();
          updateApplicationParamList();
          setSelectedModule("");
          setSelectedParamType("");
          setSelectedStatus("");
          setSearchVal("");
          setPage(0);
        })
        .catch((err) => {
          notification("Fail to update parameter", "error");
        })
        .finally(() => {
          setIsSubmitLoading(false);
          handleCloseConfirmationDialog();
        });
    }
    
  };
  //===========================================================================

  //==========================useEffect Function================================
  useEffect(() => {
    if (!open) {
      setErrorCheck(false);
      setParamVal({
        module: null,
        paramType: "",
        paramValue: "",
        paramDesc: "",
        paramRemark: "",
        paramSeq: "",
      });
    }
  }, [open]);

  useEffect(() => {
    if (selectedParamSetting && open && action !== "Create") {
      setParamVal({
        module: selectedParamSetting.moduleId
          ? {
              moduleId: selectedParamSetting.moduleId,
              moduleName: selectedParamSetting.moduleName,
            }
          : {
              moduleId: "",
              moduleName: "General",
            },
        paramType: selectedParamSetting.paramType,
        paramValue: selectedParamSetting.paramValue,
        paramDesc: selectedParamSetting.paramDesc,
        paramRemark: selectedParamSetting.paramRemark,
        paramSeq: selectedParamSetting.paramSeq,
      });
    }
  }, [selectedParamSetting, open, action]);
  //===========================================================================

  //==========================useMemo Function=================================
  const MappedModuleInfo = useMemo(() => {
    var mappedModule = moduleList?.map((x) => {
      return {
        moduleId: x.id,
        moduleName: x.name,
      };
    });
    var generalModule = {moduleId:"", moduleName: "General"}
    if (mappedModule) {
      return [...mappedModule, generalModule]
    }
    return [generalModule];
  }, [moduleList]);

  const MappedSubmissionData = useMemo(() => {
    if (paramVal) {
      var submissionVal = {
        id: selectedParamSetting?.id ?? null,
        paramType: paramVal.paramType,
        paramValue: paramVal.paramValue,
        paramDesc: paramVal.paramDesc === "" ? null : paramVal.paramDesc,
        paramRemark: paramVal.paramRemark,
        paramSeq: paramVal.paramSeq,
        moduleId: paramVal?.module?.moduleId === "" ? null : paramVal?.module?.moduleId,
        paramStatus: 1,
        by: "SYS",
      };
    }

    return submissionVal;
  }, [paramVal]);

  const ParamTypes = useMemo(() => {
    return MappedApplicationParam?.filter((x) =>
      MappedSubmissionData ? MappedSubmissionData.moduleId === x.moduleId : true
    )
      ?.map((z) => z.paramType)
      ?.filter((v, i, a) => a.indexOf(v) === i);
  }, [MappedSubmissionData]);
  //===========================================================================

  return (
    <div>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Add Application Parameter</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Header2>Module</Header2>
          <Autocomplete
            options={MappedModuleInfo}
            getOptionLabel={(option) => option.moduleName}
            sx={{ width: "100%", mb: 1 }}
            size={"small"}
            onChange={(e, v) => {
              setParamVal((prev) => ({ ...prev, module: v }));
            }}
            value={paramVal?.module === "undefined" ? "" : paramVal?.module}
            renderInput={(params) => (
              <GlobalTextField
                error={errorCheck && paramVal?.module === null}
                helperText={
                  errorCheck &&
                  paramVal?.module === null &&
                  "Module cannot be empty"
                }
                {...params}
              />
            )}
            disabled={action === "View"}
          />

          <Header2>Param Type</Header2>
          <Autocomplete
            // freeSolo
            options={ParamTypes}
            sx={{ width: "100%", mb: 1 }}
            size={"small"}
            onChange={(e, v) => {
              setParamVal((prev) => ({ ...prev, paramType: v }));
            }}
            onInputChange={(e, v) => {
              if (e && e.type === "change") {
                setParamVal((prev) => ({ ...prev, paramType: v }));
              }
            }}
            value={paramVal?.paramType}
            renderInput={(params) => (
              <GlobalTextField
                error={errorCheck && paramVal?.paramType === ""}
                helperText={
                  errorCheck &&
                  paramVal?.paramType === "" &&
                  "Param Type cannot be empty"
                }
                {...params}
              />
            )}
            disabled={action === "View"}
          />

          <Header2>Param Value</Header2>
          <GlobalTextField
            size={"small"}
            sx={{ width: "100%", mb: 1 }}
            onChange={(e, v) => {
              setParamVal((prev) => ({ ...prev, paramValue: e.target.value }));
            }}
            value={paramVal?.paramValue}
            error={errorCheck && paramVal?.paramValue === ""}
            helperText={
              errorCheck &&
              paramVal?.paramValue === "" &&
              "Param Value cannot be empty"
            }
            disabled={action === "View"}
          />

          <Header2>Param Description</Header2>
          <GlobalTextField
            size={"small"}
            sx={{ width: "100%", mb: 1 }}
            onChange={(e, v) => {
              setParamVal((prev) => ({ ...prev, paramDesc: e.target.value }));
            }}
            value={paramVal?.paramDesc}
            disabled={action === "View"}
            multiline
          />

          <Header2>Param Remark</Header2>
          <GlobalTextField
            size={"small"}
            sx={{ width: "100%", mb: 1 }}
            onChange={(e, v) => {
              setParamVal((prev) => ({ ...prev, paramRemark: e.target.value }));
            }}
            value={paramVal?.paramRemark}
            disabled={action === "View"}
            multiline
          />

          <Header2>Param Sequence</Header2>
          <GlobalTextField
            size={"small"}
            sx={{ width: "100%", mb: 1 }}
            type={"number"}
            onChange={(e, v) => {
              setParamVal((prev) => ({ ...prev, paramSeq: e.target.value }));
            }}
            error={errorCheck && /\./.test(paramVal.paramSeq)}
            helperText={
              errorCheck &&
              /\./.test(paramVal.paramSeq) &&
              "Param Sequence are not allow to contain decimal point"
            }
            value={paramVal?.paramSeq}
            disabled={action === "View"}
          />
        </DialogContent>

        <DialogActions>
          <SecondaryButton size={"small"} onClick={() => onClose()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size={"small"} onClick={() => handleSubmitButton()}>
            Submit
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={openConfirmDialog}
        loading={isSubmitLoading}
        type={"info"}
        onSubmit={handleSubmit}
        onClose={handleCloseConfirmationDialog}
        dialogTitle={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {`${action} Parameter Confirmation`}
          </span>
        }
        dialogContent={() => (
          <Typography>{`Are you confirm to ${action?.toLowerCase()} this parameter?`}</Typography>
        )}
      />
    </div>
  );
}
